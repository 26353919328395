import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IMapboxTilesetFieldsFragment = Pick<
  Types.ITileset,
  'id' | 'mapboxTilesetId'
> & {
  mapboxData?: Types.Maybe<
    Pick<
      Types.IMapboxTilesetData,
      'id' | 'name' | 'type' | 'center' | 'fileSize'
    >
  >
}

export type ITilesetFieldsFragment = Pick<
  Types.ITileset,
  'id' | 'name' | 'mapboxTilesetId' | 'isProcessed'
>

export type IGetMapboxTilesetsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetMapboxTilesetsQuery = {
  getMapboxTilesets?: Types.Maybe<
    Array<Types.Maybe<IMapboxTilesetFieldsFragment>>
  >
}

export type IListenTilesetsByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenTilesetsByProjectIdSubscription = {
  tileset?: Types.Maybe<
    Pick<Types.ITilesetChangeEvent, 'id'> & {
      item?: Types.Maybe<ITilesetFieldsFragment>
    }
  >
}

export type IUploadGeoTiffToMapboxMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  fileName: Types.Scalars['String']
  fullFilePath: Types.Scalars['String']
}>

export type IUploadGeoTiffToMapboxMutation = {
  uploadGeoTIFFToMapbox?: Types.Maybe<ITilesetFieldsFragment>
}

export type IDeleteTilesetFromMapboxMutationVariables = Types.Exact<{
  tilesetId: Types.Scalars['String']
}>

export type IDeleteTilesetFromMapboxMutation = Pick<
  Types.IMutation,
  'deleteTilesetFromMapbox'
>

export const MapboxTilesetFieldsFragmentDoc = gql`
  fragment MapboxTilesetFields on Tileset {
    id
    mapboxTilesetId
    mapboxData {
      id
      name
      type
      center
      fileSize
    }
  }
`
export const TilesetFieldsFragmentDoc = gql`
  fragment TilesetFields on Tileset {
    id
    name
    mapboxTilesetId
    isProcessed
  }
`
export const GetMapboxTilesetsDocument = gql`
  query GetMapboxTilesets($projectId: ObjectId!) {
    getMapboxTilesets(projectId: $projectId) {
      ...MapboxTilesetFields
    }
  }
  ${MapboxTilesetFieldsFragmentDoc}
`

/**
 * __useGetMapboxTilesetsQuery__
 *
 * To run a query within a React component, call `useGetMapboxTilesetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMapboxTilesetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMapboxTilesetsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetMapboxTilesetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetMapboxTilesetsQuery,
    IGetMapboxTilesetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetMapboxTilesetsQuery,
    IGetMapboxTilesetsQueryVariables
  >(GetMapboxTilesetsDocument, options)
}
export function useGetMapboxTilesetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetMapboxTilesetsQuery,
    IGetMapboxTilesetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetMapboxTilesetsQuery,
    IGetMapboxTilesetsQueryVariables
  >(GetMapboxTilesetsDocument, options)
}
export type GetMapboxTilesetsQueryHookResult = ReturnType<
  typeof useGetMapboxTilesetsQuery
>
export type GetMapboxTilesetsLazyQueryHookResult = ReturnType<
  typeof useGetMapboxTilesetsLazyQuery
>
export type GetMapboxTilesetsQueryResult = Apollo.QueryResult<
  IGetMapboxTilesetsQuery,
  IGetMapboxTilesetsQueryVariables
>
export const ListenTilesetsByProjectIdDocument = gql`
  subscription ListenTilesetsByProjectId($projectId: ObjectId!) {
    tileset(projectId: $projectId) {
      id
      item {
        ...TilesetFields
      }
    }
  }
  ${TilesetFieldsFragmentDoc}
`

/**
 * __useListenTilesetsByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenTilesetsByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenTilesetsByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenTilesetsByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenTilesetsByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenTilesetsByProjectIdSubscription,
    IListenTilesetsByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenTilesetsByProjectIdSubscription,
    IListenTilesetsByProjectIdSubscriptionVariables
  >(ListenTilesetsByProjectIdDocument, options)
}
export type ListenTilesetsByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenTilesetsByProjectIdSubscription
>
export type ListenTilesetsByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenTilesetsByProjectIdSubscription>
export const UploadGeoTiffToMapboxDocument = gql`
  mutation UploadGeoTIFFToMapbox(
    $projectId: ObjectId!
    $fileName: String!
    $fullFilePath: String!
  ) {
    uploadGeoTIFFToMapbox(
      projectId: $projectId
      fileName: $fileName
      fullFilePath: $fullFilePath
    ) {
      ...TilesetFields
    }
  }
  ${TilesetFieldsFragmentDoc}
`
export type IUploadGeoTiffToMapboxMutationFn = Apollo.MutationFunction<
  IUploadGeoTiffToMapboxMutation,
  IUploadGeoTiffToMapboxMutationVariables
>

/**
 * __useUploadGeoTiffToMapboxMutation__
 *
 * To run a mutation, you first call `useUploadGeoTiffToMapboxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadGeoTiffToMapboxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadGeoTiffToMapboxMutation, { data, loading, error }] = useUploadGeoTiffToMapboxMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      fileName: // value for 'fileName'
 *      fullFilePath: // value for 'fullFilePath'
 *   },
 * });
 */
export function useUploadGeoTiffToMapboxMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUploadGeoTiffToMapboxMutation,
    IUploadGeoTiffToMapboxMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUploadGeoTiffToMapboxMutation,
    IUploadGeoTiffToMapboxMutationVariables
  >(UploadGeoTiffToMapboxDocument, options)
}
export type UploadGeoTiffToMapboxMutationHookResult = ReturnType<
  typeof useUploadGeoTiffToMapboxMutation
>
export type UploadGeoTiffToMapboxMutationResult =
  Apollo.MutationResult<IUploadGeoTiffToMapboxMutation>
export type UploadGeoTiffToMapboxMutationOptions = Apollo.BaseMutationOptions<
  IUploadGeoTiffToMapboxMutation,
  IUploadGeoTiffToMapboxMutationVariables
>
export const DeleteTilesetFromMapboxDocument = gql`
  mutation DeleteTilesetFromMapbox($tilesetId: String!) {
    deleteTilesetFromMapbox(tilesetId: $tilesetId)
  }
`
export type IDeleteTilesetFromMapboxMutationFn = Apollo.MutationFunction<
  IDeleteTilesetFromMapboxMutation,
  IDeleteTilesetFromMapboxMutationVariables
>

/**
 * __useDeleteTilesetFromMapboxMutation__
 *
 * To run a mutation, you first call `useDeleteTilesetFromMapboxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTilesetFromMapboxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTilesetFromMapboxMutation, { data, loading, error }] = useDeleteTilesetFromMapboxMutation({
 *   variables: {
 *      tilesetId: // value for 'tilesetId'
 *   },
 * });
 */
export function useDeleteTilesetFromMapboxMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteTilesetFromMapboxMutation,
    IDeleteTilesetFromMapboxMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteTilesetFromMapboxMutation,
    IDeleteTilesetFromMapboxMutationVariables
  >(DeleteTilesetFromMapboxDocument, options)
}
export type DeleteTilesetFromMapboxMutationHookResult = ReturnType<
  typeof useDeleteTilesetFromMapboxMutation
>
export type DeleteTilesetFromMapboxMutationResult =
  Apollo.MutationResult<IDeleteTilesetFromMapboxMutation>
export type DeleteTilesetFromMapboxMutationOptions = Apollo.BaseMutationOptions<
  IDeleteTilesetFromMapboxMutation,
  IDeleteTilesetFromMapboxMutationVariables
>
