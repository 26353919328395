import * as React from 'react'

import { Icon, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'

import { MapFileType } from '~/client/graph'
import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import * as Icons from '~/client/src/shared/components/Icons'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import GlobeView from '~/client/src/shared/models/GlobeView'
import Sitemap from '~/client/src/shared/models/Sitemap'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import TilesetsStore from '~/client/src/shared/stores/domain/Tilesets.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import ProjectSetUpPageStore from '../../../../ProjectSetUpPage.store'
import MapViewSetUpStore, { SetUpSteps } from '../../MapViewSetUp.store'
import { ViewType } from '../../stores/MapBoxEditor.store'
import { ItemsCollapseState } from '../../stores/MapViewItemsSetup.store'
import HierarchyTreeNode from '../HierarchyTree/HierarchyTreeNode'
import { C_KEY_CODE, V_KEY_CODE } from '../SitemapEditor/MapEditor'
import GlobeLeftPanelStore, { IMapFile } from './GlobeLeftPanel.store'

import '../LeftPanel.scss'

interface IProps {
  globes?: GlobeView[]

  store: MapViewSetUpStore
  projectSetUpPageStore?: ProjectSetUpPageStore
  step: SetUpSteps
  state?: DesktopInitialState

  sitemapsStore?: SitemapsStore
  tilesetsStore?: TilesetsStore
  isDisabled?: boolean

  selectedMapViewItem?: MapViewItemBase
  selectedSitemap?: Sitemap
}

interface ISortableListItems {
  hiddenItems: JSX.Element[]
  shownItems: JSX.Element[]
}

interface ISortableListProps {
  isRubberMode?: boolean
  items: ISortableListItems
}

const SEARCH_DEFAULT = 'Search'
const OVERSCAN_ROW_COUNT = 6
const objectsVisible = 'objects visible'

const SortableItem = SortableElement(({ item }) => (
  <div
    className={classList({
      'sortable-item full': true,
      hidden: !item?.props?.children,
    })}
  >
    {item}
  </div>
))

const SortableList = SortableContainer<ISortableListProps>(
  ({ items, isRubberMode }) => {
    const { hiddenItems, shownItems } = items
    return (
      <div className="col">
        {/* Shown items */}
        {shownItems?.length > 0 && (
          <div>
            <div className="row pb10 px16">
              <div className="text grey-30 bold lp1 line-16">
                {Localization.translator.visibleLayers.toLocaleUpperCase()}
              </div>
            </div>
            {shownItems.map((item, index) => {
              return isRubberMode ? (
                <div key={`hidden-item-${index}`}>{item}</div>
              ) : (
                <SortableItem key={`item-${index}`} index={index} item={item} />
              )
            })}
          </div>
        )}

        {/* Hidden items */}
        {hiddenItems?.length > 0 && (
          <div>
            <div className="row py10 px16">
              <div className="text grey-30 bold lp1 line-16">
                {Localization.translator.hiddenLayers.toLocaleUpperCase()}
              </div>
            </div>
            {hiddenItems.map((item, index) => (
              <div key={`hidden-item-${index}`}>{item}</div>
            ))}
          </div>
        )}
      </div>
    )
  },
)

const png = 'PNG'
const shp = 'SHP'
const doneTypingInterval = 300

@inject('state', 'sitemapsStore', 'tilesetsStore')
@observer
export default class GlobeLeftPanel extends React.Component<IProps> {
  @observable private hoveredMapFile: IMapFile
  private readonly cellMeasurerCache: CellMeasurerCache = null
  @observable private isCollapsed: boolean = false
  private store: GlobeLeftPanelStore
  public constructor(props: IProps) {
    super(props)
    this.store = new GlobeLeftPanelStore(
      props.store,
      props.sitemapsStore,
      props.tilesetsStore,
      props.state,
    )
    this.cellMeasurerCache = new CellMeasurerCache({
      fixedWidth: true,
    })
  }

  public componentDidMount(): void {
    document.addEventListener('keydown', this.onKeyDown)
  }

  public componentWillUnmount(): void {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  public render(): JSX.Element {
    const {
      store: { mapBoxEditorStore },
      isDisabled,
    } = this.props
    const { selectedViewType } = mapBoxEditorStore

    if (this.isCollapsed) {
      return (
        <div
          className={classList({
            'sitemaps-left-bar br-light-grey full-height col no-outline-container collapsed-panel':
              true,
            'inactive-element': isDisabled,
          })}
        >
          <Icon
            className="no-grow"
            icon={IconNames.DOUBLE_CHEVRON_RIGHT}
            onClick={this.toggleCollapseState}
          />
        </div>
      )
    }

    return (
      <div
        className={classList({
          'sitemaps-left-bar br-light-grey full-height col no-outline-container':
            true,
          'inactive-element': isDisabled,
        })}
      >
        {this.renderViewSelect()}
        {selectedViewType === ViewType.Objects && (
          <>
            {this.renderSearchBar()}
            <div className="sitemaps-left-bar-header no-grow row y-center bb-light-input-border">
              <div
                className="row text light large ml10 pointer"
                onClick={this.toggleCollapsing}
              >
                {this.navigationIcon}
                {`${this.store.getVisibleObjectsCount(
                  selectedViewType,
                )} ${objectsVisible}`}
              </div>
              <div className="no-grow"></div>
            </div>
          </>
        )}
        {this.renderHierarchyPanel()}
      </div>
    )
  }

  private toggleCollapseState = (): void => {
    this.isCollapsed = !this.isCollapsed
  }

  private renderViewSelect(): JSX.Element {
    const { selectedViewType } = this.props.store.mapBoxEditorStore

    return (
      <div className="row left-panel-items bb-light-cool-grey">
        {Object.values(ViewType).map((viewType, index) => {
          const isSelected = selectedViewType === viewType
          if (!this.props.store.isGlobeMode && viewType === ViewType.MapFiles) {
            return null
          }

          return (
            <div
              key={viewType}
              className={classList({
                'row x-center full-height bg-white left-panel-item': true,
                'bl-white br-white': index === 1,
                selected: isSelected,
                inactive: !isSelected,
              })}
              onClick={this.store.onViewTypeSelect.bind(null, viewType)}
            >
              <div
                className={classList({
                  'text large row y-center x-center no-grow full-height no-white-space-wrap':
                    true,
                  'blue-brand selected-item': isSelected,
                  'primary pointer': !isSelected,
                })}
              >
                {viewType}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  private changeSearchText = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    this.props.store.mapViewItemsSetupStore.setSearchKey(event.target.value)
    this.recomputeGridSize()
  }

  private renderSearchBar(): JSX.Element {
    return (
      <div className="search-bar relative bg-white px10">
        <Icon
          icon={IconNames.SEARCH}
          className="search-bar-icon absolute"
          iconSize={Icon.SIZE_LARGE}
        />
        <StruxhubInput
          onChange={this.changeSearchText}
          value={this.props.store.mapViewItemsSetupStore.searchKey}
          placeholder={SEARCH_DEFAULT}
          isMinimalisticMode={true}
        />
      </div>
    )
  }

  private renderMapFilesSearchBar(): JSX.Element {
    return (
      <div className="search-bar relative bg-white px10">
        <Icon
          icon={IconNames.SEARCH}
          className="search-bar-icon absolute"
          iconSize={Icon.SIZE_LARGE}
        />
        <StruxhubInput
          onChange={this.changeSearchText}
          value={this.props.store.mapViewItemsSetupStore.searchKey}
          placeholder={SEARCH_DEFAULT}
          isMinimalisticMode={true}
          withDelay={true}
          inputDelay={doneTypingInterval}
        />
      </div>
    )
  }

  private renderItemsPanel(): JSX.Element {
    const { hierarchyList } = this.props.store.mapViewItemsSetupStore
    const { isRubberMode } = this.props.store.mapBoxEditorStore

    return (
      <div className="relative full-height">
        <AutoSizer>
          {({ width, height }) => (
            <List
              deferredMeasurementCache={this.cellMeasurerCache}
              data={hierarchyList}
              width={width}
              height={height}
              rowCount={hierarchyList.length}
              overscanRowCount={OVERSCAN_ROW_COUNT}
              scrollToAlignment="start"
              rowHeight={this.store.getRowHeight}
              rowRenderer={this.renderRow.bind(null, isRubberMode)}
            />
          )}
        </AutoSizer>
      </div>
    )
  }

  public renderRow = (
    isRubberMode: boolean,
    { key, style, index, parent }: any,
  ): JSX.Element => {
    const {
      mapViewItemsSetupStore,
      sitemapViewsSetupStore,
      mapBoxViewerStore,
      globeViewSetupStore,
      mapBoxEditorStore,
    } = this.props.store
    const node = mapViewItemsSetupStore.hierarchyList[index]

    if (!node) {
      return null
    }

    return (
      <CellMeasurer
        cache={this.cellMeasurerCache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div
            style={style}
            ref={registerChild}
            className="col bb-palette-brand-lighter"
          >
            <HierarchyTreeNode
              node={node}
              key={index}
              mapViewItemsSetupStore={mapViewItemsSetupStore}
              sitemapViewsSetupStore={sitemapViewsSetupStore}
              level={node.level}
              getUpdatedItem={this.getUpdatedItemCoords}
              globeViewSetupStore={globeViewSetupStore}
              setViewportFromItem={mapBoxViewerStore.setViewportFromItem}
              recomputeGridSize={this.recomputeGridSize}
              mapBoxEditorStore={isRubberMode && mapBoxEditorStore}
            />
          </div>
        )}
      </CellMeasurer>
    )
  }

  private getUpdatedItemCoords = (item: MapViewItemBase): MapViewItemBase => {
    const {
      isGlobeMode,
      mapBoxViewerStore: { displayedGlobeViewItems },
    } = this.props.store
    return isGlobeMode
      ? displayedGlobeViewItems.find(i => item.id == i.id)
      : item
  }

  private renderHierarchyPanel(): JSX.Element {
    const { selectedViewType } = this.props.store.mapBoxEditorStore

    switch (selectedViewType) {
      case ViewType.Objects:
        return this.renderItemsPanel()
      case ViewType.MapFiles:
        return this.renderMapFiles()
    }
  }

  private get navigationIcon(): JSX.Element {
    const { itemsCollapseState } = this.props.store.mapViewItemsSetupStore
    const className = 'no-grow navigation-arrows'

    switch (itemsCollapseState) {
      case ItemsCollapseState.collapsed:
        return <Icons.NavigationArrowsUp className={className} />
      case ItemsCollapseState.notCollapsed:
        return <Icons.NavigationArrowsDown className={className} />
      default:
        return <Icons.NavigationArrows className={className} />
    }
  }

  private onKeyDown = (event: KeyboardEvent): void => {
    if ((event.metaKey || event.ctrlKey) && event.code === C_KEY_CODE) {
      this.props.store.mapViewItemsSetupStore.copyLevel()
    }
    if ((event.metaKey || event.ctrlKey) && event.code === V_KEY_CODE) {
      this.props.store.mapViewItemsSetupStore.pasteLevel()
    }
  }

  @computed
  private get sortableMapFiles(): ISortableListItems {
    const { filteredMapFiles, filteredGlobeViewMapFiles, isMapFileDisplayed } =
      this.store
    return {
      hiddenItems: filteredMapFiles
        .filter(f => !isMapFileDisplayed(f))
        .map(f => this.renderMapFile(f, true)),
      shownItems: filteredGlobeViewMapFiles
        .filter(f => isMapFileDisplayed(f))
        .map(f =>
          this.renderMapFile(
            f,
            this.props.store.mapBoxEditorStore.isRubberMode,
          ),
        ),
    }
  }

  private onMouseEnterFile = (f: IMapFile): void => {
    this.hoveredMapFile = f
  }

  private onMouseLeaveFile = (): void => {
    this.hoveredMapFile = null
  }

  private renderMapFile(f: IMapFile, isHidden?: boolean): JSX.Element {
    const shouldHighlightAllBorders =
      f.type === MapFileType.Sitemap
        ? this.props.store.sitemapsSetupStore.selectedSitemap?.id === f.id
        : this.props.store.tilesetsSetupStore.selectedTilesetId === f.id
    const splittedName = f.name.split('.')
    const name = splittedName[0]
    const ext =
      (splittedName?.length > 1 && splittedName?.at(-1)) ||
      (f.type === MapFileType.Sitemap ? png : shp)
    return (
      <div
        key={f.id}
        className={classList({
          'globe-plan-node row pr20 relative pl12 pointer': true,
          h70: !!f.filledImage,
          h50: !f.filledImage,
          selected: shouldHighlightAllBorders,
        })}
        onMouseEnter={this.onMouseEnterFile.bind(this, f)}
        onMouseLeave={this.onMouseLeaveFile}
        onClick={
          this.props.store.mapBoxEditorStore.isRubberMode
            ? NOOP
            : this.selectMapFile.bind(this, f)
        }
      >
        <div className="row">
          {!isHidden && (
            <Icons.Draggable className="h12 mw20 no-grow icon-dark-grey" />
          )}
          <div className="col full-height sitemap-image-holder x-center no-grow unclickable-element">
            {f.type === MapFileType.Sitemap ? (
              <DesktopFileInput
                id={f.id}
                name=""
                value={f.filledImage}
                isReadonly={true}
                textClassName="hint"
                shouldHideIconAndOutline={true}
              />
            ) : (
              <Icons.File className="h36 w36 brada3 ba-light-grey bg-white pa8 icon-dark-grey" />
            )}
          </div>
          <div className="row full-height y-center sitemap-item-name">
            <div className="col px10">
              <div className="row full-width relative">
                <div
                  className={classList({
                    'text medium-bold brada4 text-ellipsis bg-unset': true,
                    'max-width160': !f.filledImage,
                    'max-width130': !!f.filledImage,
                  })}
                >
                  {name}
                </div>
              </div>
              <div className="text row grey">{ext.toLocaleUpperCase()}</div>
            </div>
          </div>
        </div>
        {this.selectedGlobeView && this.renderMapFileObjectVisibilityToggle(f)}
      </div>
    )
  }

  private renderMapFiles(): JSX.Element {
    return (
      <>
        {this.renderMapFilesSearchBar()}
        <div className="scrollable full-height">
          {this.renderMapFilesList()}
        </div>
      </>
    )
  }

  private renderMapFilesList(): JSX.Element {
    const { toggleCreateMapFileMenu } = this.props.store.sitemapControlStore
    return (
      <>
        <div className="row pa10">
          <div className="text large">
            {Localization.translator.xFiles(this.store.filteredMapFiles.length)}
          </div>
          <div className="sitemap-ribbon no-grow row">
            <div
              className="text white bg-white brada24 py5 large title-row pointer row x-center ba-light-cool-grey"
              onClick={() => toggleCreateMapFileMenu()}
            >
              <Icon icon={IconNames.PLUS} intent={Intent.PRIMARY} />
            </div>
          </div>
        </div>
        <div className="col pb12 columns-list no-select">
          <div className="col full-height sortable-list-holder">
            <SortableList
              items={this.sortableMapFiles}
              onSortEnd={this.store.onMapFilesSortEnd}
              axis="y"
              distance={2}
              isRubberMode={this.props.store.mapBoxEditorStore.isRubberMode}
            />
          </div>
        </div>
      </>
    )
  }

  private selectMapFile = async (f: IMapFile): Promise<void> => {
    const { tilesetsSetupStore, sitemapsSetupStore, mapBoxViewerStore } =
      this.props.store
    tilesetsSetupStore.deselectTileset()
    sitemapsSetupStore.deselectSitemap()
    mapBoxViewerStore.tilesetsControlStore.resetTilesetFeaturesSelection()
    if (f.type === MapFileType.Sitemap) {
      await sitemapsSetupStore.selectSitemapById(f.id, false)
      mapBoxViewerStore.setViewportFromPlan(sitemapsSetupStore.selectedSitemap)
    }
    if (f.type === MapFileType.Tileset) {
      await tilesetsSetupStore.selectTilesetById(f.id, false)
      mapBoxViewerStore.setViewportFromTileset(
        tilesetsSetupStore.selectedTileset,
      )
    }
  }

  private renderMapFileObjectVisibilityToggle = (f: IMapFile): JSX.Element => {
    const isDisplayed = this.store.isMapFileDisplayed(f)
    const onClick =
      f.type === MapFileType.Sitemap
        ? this.onPlanVisibilityClick
        : this.onTilesetVisibilityClick

    return (
      <div
        className="icon-wrapper no-grow pointer"
        onClick={onClick.bind(null, f.id)}
      >
        {isDisplayed ? (
          this.hoveredMapFile?.id === f.id && (
            <Icons.EyeView className="globe-icon" />
          )
        ) : (
          <Icons.EyeHide className="globe-icon" />
        )}
      </div>
    )
  }

  @action.bound
  private onPlanVisibilityClick(sitemapId: string) {
    const { globeViewSetupStore, mapBoxEditorStore } = this.props.store
    if (mapBoxEditorStore.isRubberMode) {
      mapBoxEditorStore.togglePlanVisibility(sitemapId)
    } else {
      globeViewSetupStore.setSitemapToGlobeView(sitemapId)
    }
  }

  @action.bound
  private onTilesetVisibilityClick(tilesetId: string) {
    const { globeViewSetupStore, mapBoxEditorStore } = this.props.store
    if (mapBoxEditorStore.isRubberMode) {
      mapBoxEditorStore.toggleTilesetVisibility(tilesetId)
    } else {
      globeViewSetupStore.setTilesetToGlobeView(tilesetId)
    }
  }

  private get selectedGlobeView(): GlobeView {
    return this.props.store.globeViewSetupStore.selectedGlobeView
  }

  @action.bound
  private toggleCollapsing(): void {
    this.props.store.mapViewItemsSetupStore.toggleItemsCollapsingState()
    this.recomputeGridSize()
  }

  @action.bound
  private recomputeGridSize(): void {
    this.cellMeasurerCache?.clearAll()
  }
}
