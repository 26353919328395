import { WorkflowsFilterType } from '~/client/graph'
import LogisticsGroupingOption from '~/client/src/shared/enums/LogisticsGroupingOption'
import FilterInfo from '~/client/src/shared/stores/substates/FilterInfo'

export default class LogisticsFiltersSettingState {
  public filterInfoMap: { [key: string]: FilterInfo } = {
    [WorkflowsFilterType.Company]: new FilterInfo(
      WorkflowsFilterType.Company,
      false,
    ),
    [WorkflowsFilterType.Location]: new FilterInfo(
      WorkflowsFilterType.Location,
      false,
    ),
    [WorkflowsFilterType.Equipment]: new FilterInfo(
      WorkflowsFilterType.Equipment,
      false,
    ),
    [WorkflowsFilterType.ResponsibleContact]: new FilterInfo(
      WorkflowsFilterType.ResponsibleContact,
      false,
    ),
    [WorkflowsFilterType.Status]: new FilterInfo(
      WorkflowsFilterType.Status,
      false,
    ),
    [WorkflowsFilterType.Category]: new FilterInfo(
      WorkflowsFilterType.Category,
      false,
    ),
    [WorkflowsFilterType.Type]: new FilterInfo(WorkflowsFilterType.Type, false),
  }

  public desktopFilterMap: { [key: string]: FilterInfo } = {
    [LogisticsGroupingOption.APP]: new FilterInfo(
      LogisticsGroupingOption.APP,
      false,
    ),
    [LogisticsGroupingOption.LBS]: new FilterInfo(
      LogisticsGroupingOption.LBS,
      false,
    ),
    [LogisticsGroupingOption.EQUIPMENT]: new FilterInfo(
      LogisticsGroupingOption.EQUIPMENT,
      false,
    ),
    [LogisticsGroupingOption.STATUS]: new FilterInfo(
      LogisticsGroupingOption.STATUS,
      false,
    ),
    [LogisticsGroupingOption.COMPANY]: new FilterInfo(
      LogisticsGroupingOption.COMPANY,
      false,
    ),
    [LogisticsGroupingOption.DATE]: new FilterInfo(
      LogisticsGroupingOption.DATE,
      false,
    ),
    [LogisticsGroupingOption.RESPONSIBLE]: new FilterInfo(
      LogisticsGroupingOption.RESPONSIBLE,
      false,
    ),
  }
}
