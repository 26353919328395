import { action, computed } from 'mobx'
import { SortEnd, arrayMove } from 'react-sortable-hoc'

import { IGlobeViewMapFile, MapFileType } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import TilesetsStore from '~/client/src/shared/stores/domain/Tilesets.store'

import MapViewSetUpStore from '../../MapViewSetUp.store'
import { ViewType } from '../../stores/MapBoxEditor.store'

export interface IMapFile {
  type: MapFileType
  name: string
  id: string
  filledImage?: string
}

export default class GlobeLeftPanelStore {
  public constructor(
    private readonly mapViewSetUpStore: MapViewSetUpStore,
    private readonly sitemapsStore: SitemapsStore,
    private readonly tilesetsStore: TilesetsStore,
    private readonly state: DesktopInitialState,
  ) {}

  public isMapFileDisplayed = (mapFile: IMapFile): boolean => {
    switch (mapFile.type) {
      case MapFileType.Sitemap:
        return this.isPlanDisplayed(mapFile.id)
      case MapFileType.Tileset:
        return this.isTilesetDisplayed(mapFile.id)
      default:
        return false
    }
  }

  public isPlanDisplayed = (planId: string): boolean => {
    const {
      mapBoxEditorStore: { isRubberMode, leftPanelVisibility },
      globeViewSetupStore: { selectedGlobeView },
    } = this.mapViewSetUpStore
    return isRubberMode
      ? leftPanelVisibility.plans[planId]
      : selectedGlobeView?.sitemapsMap?.[planId]
  }

  public isTilesetDisplayed = (tilesetId: string): boolean => {
    const {
      mapBoxEditorStore: { isRubberMode, leftPanelVisibility },
      globeViewSetupStore: { selectedGlobeView },
    } = this.mapViewSetUpStore
    return isRubberMode
      ? leftPanelVisibility.tilesets[tilesetId]
      : selectedGlobeView?.tilesetsMap?.[tilesetId]
  }

  @computed
  public get availableMapFiles(): IMapFile[] {
    return [...this.referencedPlans, ...this.tilesetsList]
  }

  @computed
  private get referencedPlans(): IMapFile[] {
    return this.sitemapsStore.list
      .filter(x => x.isReferenced)
      .map(s => ({
        type: MapFileType.Sitemap,
        name: s.name,
        id: s.id,
        filledImage: s.filledImage,
      }))
  }

  @computed
  private get tilesetsList(): IMapFile[] {
    return this.state.isTilesetsDisabled
      ? []
      : this.tilesetsStore.list.map(t => ({
          type: MapFileType.Tileset,
          id: t.id,
          name: t.mapboxData.name,
        }))
  }

  public get filteredMapFiles(): IMapFile[] {
    const { searchKey } = this.mapViewSetUpStore.mapViewItemsSetupStore
    if (!searchKey) {
      return this.availableMapFiles
    }
    return this.availableMapFiles.filter(x =>
      x.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()),
    )
  }

  @computed
  public get filteredGlobeViewMapFiles(): IMapFile[] {
    if (!this.mapViewSetUpStore.globeViewSetupStore.selectedGlobeView) {
      return []
    }
    return this.mapViewSetUpStore.globeViewSetupStore.selectedGlobeView.mapFiles
      .filter(f => !!this.filteredMapFileIdToMapFile[f.entityType + f.entityId])
      .map(f => this.filteredMapFileIdToMapFile[f.entityType + f.entityId])
      .reverse()
  }

  @computed
  public get filteredMapFileIdToMapFile(): {
    [planId: string]: IMapFile
  } {
    return this.filteredMapFiles.reduce((acc, f) => {
      acc[f.type + f.id] = f
      return acc
    }, {})
  }

  public getRowHeight = ({ index }): number => {
    const { searchKey } = this.mapViewSetUpStore.mapViewItemsSetupStore
    const node =
      this.mapViewSetUpStore.mapViewItemsSetupStore.hierarchyList[index]

    return node.isHidden ||
      (searchKey &&
        node.name &&
        !node.name?.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()))
      ? 0
      : 35
  }

  public getVisibleObjectsCount = (selectedViewType: ViewType): number => {
    const { mapViewItems } = this.mapViewSetUpStore.mapViewItemsSetupStore

    switch (selectedViewType) {
      case ViewType.Objects:
        return mapViewItems.length
      case ViewType.MapFiles:
        return (
          this.mapViewSetUpStore.globeViewSetupStore.selectedGlobeView
            ?.sitemapIds?.length || 0
        )
      default:
        return 0
    }
  }

  public onViewTypeSelect = async (viewType: ViewType) => {
    const {
      onViewTypeSelect,
      currentStep,
      setStep,
      mapBoxEditorStore: { isRubberMode },
    } = this.mapViewSetUpStore

    onViewTypeSelect(viewType)
    if (currentStep && !isRubberMode) {
      setStep(null)
    }
  }

  @action.bound
  public onMapFilesSortEnd(sort: SortEnd): void {
    if (sort.oldIndex === sort.newIndex) {
      return
    }
    const newMapFiles = arrayMove(
      this.filteredGlobeViewMapFiles,
      sort.oldIndex,
      sort.newIndex,
    )
      .map(f => ({ entityId: f.id, entityType: f.type } as IGlobeViewMapFile))
      .reverse()
    this.mapViewSetUpStore.globeViewSetupStore.updateGlobeView(
      null,
      null,
      null,
      newMapFiles,
    )
  }
}
