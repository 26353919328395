import * as React from 'react'

import { observable } from 'mobx'
import { classList } from 'react-classlist-helper'

import OpacityBackground from '~/client/src/desktop/views/SimpleGanttView/components/OpacityBackground/OpacityBackground'

import HeaderBarStore from './HeaderBar.store'
import ViewModeToggle from './components/ViewModeToggle/ViewModeToggle'

import './HeaderBar.scss'

// localization: no display text to translate

export default class BaseHeaderBar<T> extends React.Component<T> {
  @observable protected isModalOpen: boolean = false
  protected store: HeaderBarStore = null

  public render() {
    const { shouldShowOpacityBackground } = this.store

    const actionBar = this.renderActionBar()
    const secondRowBar = this.renderSecondRow()

    return (
      <div className="col relative no-grow">
        <div
          className={classList({
            'header-bar row main x-start y-center pb10 pt5 relative': true,
            'bb-light-grey px24': !actionBar && !secondRowBar,
          })}
        >
          {this.isModalOpen && this.renderModal()}
          {this.renderLeftSection()}
          <div
            className={classList({
              'row full-height right-bar y-center relative no-flex-children-direct':
                true,
              'header-bar-main-content px24': !!secondRowBar,
            })}
          >
            {this.renderCenterSection()}
            <div className="row x-end full-height no-grow">
              {this.renderRightSection()}
              {this.renderViewToggle()}
            </div>
          </div>
        </div>
        <div>{secondRowBar}</div>
        <OpacityBackground isAnyPopupOpened={shouldShowOpacityBackground} />
        {actionBar}
      </div>
    )
  }

  protected toggleModal = () => {
    this.isModalOpen = !this.isModalOpen
  }

  protected renderModal() {
    return <div />
  }

  protected renderViewToggle() {
    const { viewState, viewModes } = this.store
    return <ViewModeToggle viewState={viewState} viewModes={viewModes} />
  }

  protected renderLeftSection() {
    return <div />
  }

  protected renderCenterSection() {
    return <div />
  }

  protected renderRightSection() {
    return <div />
  }

  protected renderActionBar() {
    return this.props.children || null
  }

  protected renderSecondRow(): JSX.Element {
    return null
  }
}
