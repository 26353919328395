import * as React from 'react'

import { Icon, IconSize } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { DeliveryFilterType } from '~/client/graph'
import DeliveryFilter from '~/client/src/desktop/components/Filters/DeliveryFilter/DeliveryFilter'
import DeliverySavedFilters from '~/client/src/desktop/components/Filters/DeliverySavedFilters/DeliverySavedFilters'
import EntityNameFilter from '~/client/src/desktop/components/Filters/EntityNameFilter/EntityNameFilter'
import BaseHeaderBar from '~/client/src/desktop/components/HeaderBar/HeaderBar'
import ReportModal from '~/client/src/desktop/components/HeaderBar/components/ReportModal'
import deliveryGroupingToFieldIdMap from '~/client/src/desktop/constants/deliveryGroupingToFieldIdMap'
import DeliveryActionTypes from '~/client/src/desktop/enums/DeliveryActionTypes'
import DeliveryViewPeriodMode, {
  deliveryViewPeriodModes,
  getDeliveryViewPeriodModeDisplayName,
} from '~/client/src/desktop/enums/DeliveryViewPeriodMode'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopDeliveryViewStore from '~/client/src/desktop/views/Deliveries/Deliveries.store'
import DeliveriesListStore from '~/client/src/desktop/views/Deliveries/components/DeliveriesList/DeliveriesList.store'
import DeliveriesMapStore from '~/client/src/desktop/views/Deliveries/components/DeliveriesMap/DeliveriesMap.store'
import DynamicOverflowListStore from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList.store'
import * as Icons from '~/client/src/shared/components/Icons'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import DeliveryFollowingsStore from '~/client/src/shared/stores/domain/DeliveryFollowings.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import DeliveryFilterStore from '~/client/src/shared/stores/ui/DeliveryFilter.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import DeliveriesHeaderBarStore from './DeliveriesHeaderBar.store'
import DeliveriesListActionBar from './components/DeliveriesListActionBar/DeliveriesListActionBar'
import DeliveriesMapActionBar from './components/DeliveriesMapActionBar/DeliveriesMapActionBar'
import DeliveryMapFilter from './components/DeliveriesMapFilters/DeliveryMapFilter'
import DeliveryMapFilterStore from './components/DeliveriesMapFilters/DeliveryMapFilter.store'
import DeliveryGroupingOptions from './components/DeliveryGroupingOptions/DeliveryGroupingOptions'
import DeliveryReportButton from './components/DeliveryReportButton/DeliveryReportButton'
import NewDeliveryActionButton from './components/NewDeliveryActionButton/NewDeliveryActionButton'

// localization: translated

interface IProps {
  desktopDeliveryViewStore: DesktopDeliveryViewStore
  deliveriesListStore: DeliveriesListStore
  deliveriesMapStore: DeliveriesMapStore
  state?: DesktopInitialState
  projectDateStore?: ProjectDateStore
  eventsStore?: DesktopEventStore
  deliveriesStore?: DeliveriesStore
  locationAttributesStore?: LocationAttributesStore
  deliveryFollowingsStore?: DeliveryFollowingsStore
  deliveryAssignmentsStore?: DeliveryAssignmentsStore
  companiesStore?: CompaniesStore
  projectMembersStore?: ProjectMembersStore
  isMapViewDisabled: boolean
}

const DATE_MODE_ICON_SIZE = 16

@inject(
  'state',
  'projectDateStore',
  'eventsStore',
  'deliveriesStore',
  'deliveryFollowingsStore',
  'deliveryAssignmentsStore',
  'locationAttributesStore',
  'companiesStore',
  'projectMembersStore',
)
@observer
export default class DeliveriesHeaderBar extends BaseHeaderBar<IProps> {
  protected readonly store: DeliveriesHeaderBarStore = null
  private readonly deliveryFilterStore: DeliveryFilterStore = null
  private readonly deliveryMapFilterStore: DeliveryMapFilterStore = null
  private readonly dynamicOverflowListStore: DynamicOverflowListStore = null

  public constructor(props: IProps) {
    super(props)

    this.dynamicOverflowListStore = new DynamicOverflowListStore()

    this.store = new DeliveriesHeaderBarStore(
      props.eventsStore,
      props.desktopDeliveryViewStore,
      props.projectDateStore,
      props.isMapViewDisabled,
    )

    this.deliveryFilterStore = new DeliveryFilterStore(
      props.eventsStore,
      props.deliveriesStore,
      false,
      props.locationAttributesStore,
      props.deliveryFollowingsStore,
      props.deliveryAssignmentsStore,
      props.companiesStore,
      props.projectMembersStore,
      props.deliveriesListStore.getFilteredCollectionExcludeFilter,
      this.store.onShowChanged,
      this.dynamicOverflowListStore.enableRecalculation,
    )

    this.deliveryMapFilterStore = new DeliveryMapFilterStore(
      props.eventsStore,
      props.deliveriesStore,
      props.deliveriesMapStore.deliveriesBySitemapListStore,
      props.companiesStore,
      this.store.onShowChanged,
    )
  }

  public componentDidMount() {
    this.store.setInitialDateValue()
    this.store.loadReportTemplate()
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.isMapViewDisabled !== prevProps.isMapViewDisabled) {
      this.store.isMapViewDisabled = !this.store.isMapViewDisabled
    }
  }

  protected renderLeftSection() {
    const { desktopDeliveryViewStore, state } = this.props
    const { isListViewMode } = desktopDeliveryViewStore

    const { forceSearchClose, onShowSearchChanged } = this.store

    return (
      <div className="row x-start full-height left-bar no-grow">
        <EntityNameFilter
          className="long"
          filters={state.deliveryFilters}
          forceClose={forceSearchClose}
          onShowChanged={onShowSearchChanged}
          placeholder={Localization.translator.searchByBookingIdOrSubscribers}
        />
        {this.renderMyDeliveriesButton()}
        {isListViewMode && this.renderGroupingOptions()}
      </div>
    )
  }

  protected renderCenterSection() {
    const { desktopDeliveryViewStore, deliveriesListStore } = this.props

    return (
      <>
        {this.renderFilters()}
        <div className="row full-height no-grow">
          <DeliverySavedFilters
            desktopDeliveryViewStore={desktopDeliveryViewStore}
            deliveriesListStore={deliveriesListStore}
            deliveryFilterStore={this.deliveryFilterStore}
          />
          {this.renderDatePicker()}
          {this.renderCalendarModePicker()}
        </div>
      </>
    )
  }

  protected renderRightSection() {
    const { filterStoresByTypeMap } = this.deliveryFilterStore
    const { isCalendarViewMode, isListViewMode, onNewDeliveryCreate } =
      this.props.desktopDeliveryViewStore

    if (isCalendarViewMode) {
      return (
        <NewDeliveryActionButton
          isActive={this.store.activeActionType === DeliveryActionTypes.NEW}
          onClick={onNewDeliveryCreate}
        />
      )
    }

    if (isListViewMode) {
      return (
        <DeliveryReportButton
          title={Localization.translator.createReport}
          desktopDeliveryViewStore={this.props.desktopDeliveryViewStore}
          deliveriesListStore={this.props.deliveriesListStore}
          className="ml10"
          toggleModal={this.toggleModal}
          shouldPreventCreation={true}
          filterStoresByTypeMap={filterStoresByTypeMap}
        />
      )
    }

    return super.renderRightSection()
  }

  protected renderModal() {
    const { startDate, endDate, isFullPeriodModeActive } =
      this.props.desktopDeliveryViewStore
    const { filterStoresByTypeMap } = this.deliveryFilterStore
    const { additionalReportFiles, toggleAdditionalReportFiles } = this.store
    return (
      <ReportModal
        groupedBy={this.groupingOptionName}
        startDate={startDate}
        endDate={endDate}
        toggleModal={this.toggleModal}
        getIconByFilter={this.getIconByFilter}
        additionalReportFiles={additionalReportFiles}
        toggleAdditionalReportFiles={toggleAdditionalReportFiles}
        filterStoresByTypeMap={filterStoresByTypeMap}
        renderButton={this.renderReportButton}
        reportName={Localization.translator.deliveryReport}
        isFullReport={isFullPeriodModeActive}
      />
    )
  }

  private get groupingOptionName(): string {
    const {
      filters: { selectedDeliveryBandsOption },
      getDeliveryFieldName,
    } = this.props.state

    return selectedDeliveryBandsOption.bands
      .map(value => getDeliveryFieldName(deliveryGroupingToFieldIdMap[value]))
      .join(', ')
  }

  protected renderActionBar() {
    const {
      state,
      deliveriesMapStore,
      deliveriesListStore,
      desktopDeliveryViewStore,
    } = this.props

    const { viewMode, setActionType, onNewDeliveryCreate } =
      desktopDeliveryViewStore

    const { activeActionType, forceGroupByClose, onShowGroupByChanged } =
      this.store

    switch (viewMode) {
      case ViewModes.List:
        const { isSomeRowSelected, statusTitle, isCdDeliverySelected } =
          deliveriesListStore

        return (
          <DeliveriesListActionBar
            shouldShowActionButtons={isSomeRowSelected}
            statusTitle={statusTitle}
            onNewDeliveryCreate={onNewDeliveryCreate}
            activeActionType={activeActionType}
            setActionType={setActionType}
            isAdminMode={state.userActiveProjectSettings?.isSuperUser}
            isCdDeliverySelected={isCdDeliverySelected}
          />
        )

      case ViewModes.Map:
        const { completedToTotalCountLabel } = deliveriesMapStore
        return (
          <div className="relative">
            <DeliveriesMapActionBar
              shouldForceCloseGroupingOptions={forceGroupByClose}
              completedToTotalCountLabel={completedToTotalCountLabel}
              onNewDeliveryCreate={onNewDeliveryCreate}
              activeActionType={activeActionType}
              setActionType={setActionType}
              onGropingChanged={this.onGropingChanged}
              desktopDeliveryViewStore={desktopDeliveryViewStore}
              onShowGroupingOptionsChanged={onShowGroupByChanged}
            />
          </div>
        )

      default:
        return null
    }
  }

  private getIconByFilter(filterType: string) {
    switch (filterType) {
      case DeliveryFilterType.Status:
        return <Icons.Delivery className="no-grow row mr10" />
      case DeliveryFilterType.Company:
        return <Icons.CompanyCompact className="no-grow row mr10" />
      case DeliveryFilterType.Gate:
        return <Icons.Gate className="no-grow row mr10" />
      case DeliveryFilterType.Building:
        return <Icons.CompactBuilding className="no-grow row mr10" />
      case DeliveryFilterType.Area:
        return <TagIcon.Area className="no-grow row mr10" />
      case DeliveryFilterType.Route:
        return <Icons.RouteReport className="no-grow row mr10" />
      case DeliveryFilterType.Zone:
        return <Icons.Zone className="no-grow row mr10" />
      case DeliveryFilterType.Level:
        return <TagIcon.Level className="no-grow row mr10" />
      case DeliveryFilterType.Followers:
        return <Icons.StarHalf className="no-grow row mr10" />
      case DeliveryFilterType.Assigners:
        return <Icons.FilledStar className="no-grow row mr10" />
      case DeliveryFilterType.Equipment:
        return <Icons.Equipment className="no-grow row mr10" />
    }
  }

  private renderGroupingOptions() {
    const { desktopDeliveryViewStore } = this.props
    const { forceGroupByClose, onShowGroupByChanged } = this.store

    return (
      <DeliveryGroupingOptions
        desktopDeliveryViewStore={desktopDeliveryViewStore}
        onShowChanged={onShowGroupByChanged}
        forceClose={forceGroupByClose}
        onChanged={this.onGropingChanged}
      />
    )
  }

  private renderDatePicker() {
    const {
      showDatePicker,
      setPreviousPeriod,
      setNextPeriod,
      isTodayBetweenSelectedDates,
      isFullPeriodModeActive,
    } = this.props.desktopDeliveryViewStore

    return (
      <div className="row no-flex desktop-deliveries-top-bar">
        <div className="no-grow pr5 pl15">
          <Icon
            className="pointer base-arrow"
            size={IconSize.LARGE}
            icon={IconNames.CARET_LEFT}
            onClick={setPreviousPeriod}
          />
        </div>
        <div
          className={classList({
            'date-picker-button desktop-deliveries-top-bar-btn text grey-light center':
              true,
            'active-current-day':
              isTodayBetweenSelectedDates && !isFullPeriodModeActive,
            'ba-palette-grey':
              !isTodayBetweenSelectedDates && !isFullPeriodModeActive,
          })}
          onClick={showDatePicker}
        >
          {this.store.datePickerCaption}
        </div>
        <div className="no-grow pl5 pr15">
          <Icon
            className="pointer base-arrow"
            size={IconSize.LARGE}
            icon={IconNames.CARET_RIGHT}
            onClick={setNextPeriod}
          />
        </div>
      </div>
    )
  }

  private renderMyDeliveriesButton = () => {
    return (
      <div
        onClick={this.store.toggleIsMyDeliveriesState}
        className={classList({
          'desktop-deliveries-top-bar-btn no-grow ml15': true,
          active: this.store.isMyDeliveriesState,
        })}
      >
        {Localization.translator.me}
      </div>
    )
  }

  private renderCalendarModePicker(): JSX.Element {
    const { calendarMode } = this.props.desktopDeliveryViewStore

    const { isDatePickerModeSelectorOpen, toggleDatePickerModeSelector } =
      this.store

    const icon = isDatePickerModeSelectorOpen
      ? IconNames.CARET_UP
      : IconNames.CARET_DOWN

    return (
      <div
        className="date-mode-btn relative pointer row x-center mw90 pl10 mx5 text large light capitalize brada4"
        onClick={toggleDatePickerModeSelector}
      >
        {getDeliveryViewPeriodModeDisplayName(calendarMode)}
        <Icon icon={icon} iconSize={DATE_MODE_ICON_SIZE} className="ml5" />

        {this.renderDateModeModal()}
      </div>
    )
  }

  private renderFilters() {
    const { desktopDeliveryViewStore } = this.props
    const {
      isMapViewMode,
      isCalendarViewMode,
      areCDOrdersHidden,
      hasCDIntegration,
      toggleShowingOfCDOrders,
    } = desktopDeliveryViewStore
    const { forceCloseMap } = this.store

    if (isMapViewMode) {
      return (
        <DeliveryMapFilter
          hasCDIntegration={hasCDIntegration}
          areCDOrdersDisabled={areCDOrdersHidden}
          toggleShowingOfCDOrders={toggleShowingOfCDOrders}
          store={this.deliveryMapFilterStore}
          forceCloseMap={forceCloseMap}
        />
      )
    }

    return (
      <DeliveryFilter
        hasCDIntegration={hasCDIntegration}
        store={this.deliveryFilterStore}
        areCDOrdersDisabled={areCDOrdersHidden}
        toggleShowingOfCDOrders={toggleShowingOfCDOrders}
        dynamicOverflowListStore={this.dynamicOverflowListStore}
        forceCloseMap={forceCloseMap}
        shouldUseAllFilterTypes={isCalendarViewMode}
      />
    )
  }

  private renderDateModeModal(): JSX.Element {
    if (!this.store.isDatePickerModeSelectorOpen) {
      return null
    }

    const { calendarMode: currentCalendarMode } =
      this.props.desktopDeliveryViewStore

    return (
      <div className="mw90 absolute-block date-mode-options-container ba-palette-grey brada4">
        {deliveryViewPeriodModes
          .filter(mode => this.isAvailablePeriodMode(mode))
          .map(mode => {
            return (
              <div
                key={mode}
                className={classList({
                  'pointer row x-center y-center text large bg-white option':
                    true,
                  selected: currentCalendarMode === mode,
                })}
                onClick={this.changeCalendarMode.bind(null, mode)}
              >
                {getDeliveryViewPeriodModeDisplayName(mode)}
              </div>
            )
          })}
      </div>
    )
  }

  private isAvailablePeriodMode(mode: string) {
    const { state, desktopDeliveryViewStore } = this.props
    const { isCalendarViewMode } = desktopDeliveryViewStore
    const { hiddenFields } = state.delivery

    if (DeliveryViewPeriodMode.DayPeriod === mode) {
      return !hiddenFields[FieldIds.ZONE]
    }

    const shouldShowOnCalendarMode =
      !isCalendarViewMode || mode !== DeliveryViewPeriodMode.FullPeriod

    return (
      shouldShowOnCalendarMode && mode !== DeliveryViewPeriodMode.TodayPeriod
    )
  }

  private onGropingChanged = () => {
    const {
      deliveriesListStore,
      deliveriesMapStore: { deliveriesBySitemapListStore },
    } = this.props
    deliveriesBySitemapListStore.resetCollapsing()
    deliveriesListStore.resetCollapsing()
  }

  private changeCalendarMode = (mode: DeliveryViewPeriodMode) => {
    this.props.desktopDeliveryViewStore.setCalendarMode(mode)
    this.dynamicOverflowListStore.enableRecalculation()
  }

  private renderReportButton = () => {
    const { filterStoresByTypeMap } = this.deliveryFilterStore
    const { additionalReportFiles, isAnyFileTypeChecked } = this.store
    return (
      <DeliveryReportButton
        title={Localization.translator.createReport}
        desktopDeliveryViewStore={this.props.desktopDeliveryViewStore}
        deliveriesListStore={this.props.deliveriesListStore}
        className="ml10"
        toggleModal={this.toggleModal}
        shouldHideIcon={true}
        shouldInvertColors={true}
        filterStoresByTypeMap={filterStoresByTypeMap}
        additionalReportFiles={additionalReportFiles}
        isEnabled={isAnyFileTypeChecked}
      />
    )
  }
}
