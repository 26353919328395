import React from 'react'

import { SitePermitStatus, WorkflowsFilterType } from '~/client/graph'
import { AvatarSize } from '~/client/src/shared/components/Avatar/Avatar'
import CompanyProfilePreview from '~/client/src/shared/components/CompanyProfilePreview/CompanyProfilePreview'
import PermitTypeIcon from '~/client/src/shared/components/PermitTypeIcon/PermitTypeIcon'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import WorkflowCardStatus from '~/client/src/shared/components/WorkflowCard/Status'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Company from '~/client/src/shared/models/Company'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import PermitType from '~/client/src/shared/models/PermitType'
import User from '~/client/src/shared/models/User'
import { FilterValue } from '~/client/src/shared/types/CustomWorkflowFilter'
import { getTitleByFilterType } from '~/client/src/shared/utils/FilterHelper'

interface ISavedViewFiltersProps {
  filtersMap?: Map<WorkflowsFilterType, FilterValue[]>
  filtersCount?: number
  groupBy?: string
  className?: string
}

export default class SavedViewFilters extends React.Component<ISavedViewFiltersProps> {
  public render(): JSX.Element {
    const { className, groupBy, filtersCount, filtersMap } = this.props

    return (
      <div className={className}>
        <div className="row align-start h40 as-stretch px16 py8">
          <div className="text large bold no-grow middle mw200 py10">
            {Localization.translator.groupBy}
          </div>
          <div className="text large bold no-grow middle mw200 py10">
            {groupBy || Localization.translator.none}
          </div>
        </div>
        <div className="row align-start gap-8 px16 h40 as-stretch">
          <span className="text large bold no-grow middle">
            {Localization.translator.filters}
          </span>
          <span className="text small light middle">({filtersCount})</span>
        </div>
        {filtersMap &&
          Object.entries(filtersMap).map(([type, values]) => {
            const filterType = type as WorkflowsFilterType
            const filterValues = values as FilterValue[]

            return (
              <div
                key={type as WorkflowsFilterType}
                className="row align-start as-stretch px16 py8"
              >
                <div className="mw200 px8 py10">
                  <span className="text large light middle">
                    {getTitleByFilterType(filterType)}
                  </span>
                  <span className="text small light middle ml8">
                    ({filterValues.length})
                  </span>
                </div>
                <div className="row wrap self-center">
                  {filterValues.map((filterValue, index) => (
                    <div key={`${filterType}-${index}`} className="inline-flex">
                      {this.renderFilterChip(filterType, filterValue)}
                      {index < filterValues.length - 1 && (
                        <div className="text small middle mr4 mb4">,</div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
      </div>
    )
  }

  private renderFilterChip(
    filterType: WorkflowsFilterType,
    filterValue: FilterValue,
  ): JSX.Element {
    if (!filterValue) {
      return
    }

    switch (filterType) {
      case WorkflowsFilterType.Company:
        const company = filterValue as Company
        return (
          <CompanyProfilePreview
            key={company.id}
            company={company}
            isMenuHidden={true}
            isPhoneHidden={true}
            shouldShowCompanyType={false}
            avatarSize={AvatarSize.Tiny}
            className="inline-flex mr4 mb4"
            contentContainerClassName="text medium max-width240"
          />
        )

      case WorkflowsFilterType.Location:
      case WorkflowsFilterType.Equipment:
        const location = filterValue as LocationAttributeBase
        return (
          <SitemapAttributeTag
            shouldShowAsTag={true}
            dataObject={location}
            className="inline-flex mr4 mb4"
            contentContainerClassName="text-ellipsis py2 max-width270"
          >
            <span title={location.name} className="text medium">
              {location.name}
            </span>
          </SitemapAttributeTag>
        )

      case WorkflowsFilterType.ResponsibleContact:
        const user = filterValue as User
        return (
          <UserProfilePreview
            user={user}
            isUserNameClickable={false}
            isBriefInfoHidden={true}
            withEmployeeId={false}
            showBoldName={false}
            avatarSize={AvatarSize.Tiny}
            className="inline-flex mr4 mb4"
            contentContainerClassName="text medium max-width240"
          />
        )

      case WorkflowsFilterType.Status:
        const status = filterValue as SitePermitStatus
        return (
          <WorkflowCardStatus
            status={status}
            isLate={false}
            className="inline-flex mr4 mb4"
            tagClassName="text medium max-width270"
            textSize="medium"
          />
        )

      case WorkflowsFilterType.Type:
        const permitType = filterValue as PermitType
        return (
          <div className="inline-flex mr4 mb4 max-width270">
            <PermitTypeIcon
              permitType={permitType?.type}
              className="type-icon row mr12 no-grow"
            />

            <span className="text ellipsis medium middle">
              {permitType.name}
            </span>
          </div>
        )
      case WorkflowsFilterType.Category:
        const category = filterValue as string
        return (
          <div className="inline-flex mr4 mb4 text ellipsis medium max-width270">
            {category}
          </div>
        )
      default:
        return (
          <div className="inline-flex mr4 mb4 text ellipsis medium max-width270">
            {filterValue as string}
          </div>
        )
    }
  }
}
