import React from 'react'

import { Switch } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { WorkflowsFilterType } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { FilterValue } from '~/client/src/shared/types/CustomWorkflowFilter'

import SavedViewFilters from './SavedViewFilters'

import '../SavedViews.scss'

interface INewViewProps {
  filterName: string
  filtersCount?: number
  filtersMap?: Map<WorkflowsFilterType, FilterValue[]>
  groupByCaption?: string
  onCreateClick: () => void
  onCancelClick: () => void
  onNameChange: (event) => void
  onIsPublicToggle: () => void
  isPublic: boolean
  className?: string
  isActiveUserAdmin: boolean
}

@observer
export default class NewViewDialog extends React.Component<INewViewProps> {
  public render() {
    const {
      filterName,
      filtersCount,
      filtersMap,
      groupByCaption,
      onCreateClick,
      onCancelClick,
      onNameChange,
      onIsPublicToggle,
      isPublic,
      className,
      isActiveUserAdmin,
    } = this.props

    return (
      <div
        className={classList({
          'view-dialog new-view-dialog': true,
          [className]: !!className,
        })}
      >
        <div className="row header">
          <div className="row pl4">
            <span
              className="col x-center y-center no-flex w48 h48 no-grow pointer pa8"
              onClick={onCancelClick}
            >
              <Icons.Cross className="row" />
            </span>
            <div className="text bold extra-large center">
              {Localization.translator.newView}
            </div>
          </div>
          <div
            className={classList({
              'row no-grow px12 py10 x-end pointer': true,
            })}
            onClick={onCreateClick}
          >
            {Localization.translator.create}
          </div>
        </div>
        <div className="view-dialog-content overflow-hidden-scrollbar">
          <div className="pt12 px16">
            <StruxhubInput
              label={Localization.translator.viewName}
              isRequiredTextHidden={true}
              value={filterName}
              onChange={onNameChange}
            />
          </div>
          <div className="pr16 pb12 pl24 row align-start gap-8">
            <div className="my10 row as-stretch text large">
              {Localization.translator.shareToProject}
            </div>
            <Switch
              className="h18 no-flex pa0 ma-auto"
              checked={isPublic}
              disabled={!isActiveUserAdmin}
              onChange={onIsPublicToggle}
            />
          </div>
          <SavedViewFilters
            filtersMap={filtersMap}
            filtersCount={filtersCount}
            groupBy={groupByCaption}
            className="col align-start as-stretch mr4"
          />
        </div>
      </div>
    )
  }
}
