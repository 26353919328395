import React from 'react'

import { GeoJSONFeature } from 'mapbox-gl'
import { observer } from 'mobx-react'
import { Layer, Source } from 'react-map-gl'

import { ITileset } from '~/client/graph'

import {
  TILESET_CIRCLE_LAYER_PREFIX,
  TILESET_FILL_LAYER_PREFIX,
  TILESET_FILL_OUTLINE_LAYER_PREFIX,
  TILESET_LINE_LAYER_PREFIX,
  TILESET_SOURCE,
  TILESET_SYMBOL_LAYER_PREFIX,
} from '../../../MapBoxViewer/mapboxConstants'

interface IProps {
  idx: number
  selectedTilesetFeature: GeoJSONFeature
  tile: ITileset
}

@observer
export default class GlobeViewTileset extends React.Component<IProps> {
  public render(): JSX.Element {
    const { selectedTilesetFeature, tile } = this.props
    const sourceLayerId = TILESET_SOURCE + tile.id
    return (
      <Source
        key={sourceLayerId}
        id={sourceLayerId}
        type={tile.mapboxData.type}
        url={`mapbox://${tile.mapboxData.id}`}
      >
        <Layer
          id={TILESET_FILL_LAYER_PREFIX + tile.id}
          type="fill"
          filter={['==', ['geometry-type'], 'Polygon']}
          source={sourceLayerId}
          source-layer="features"
          paint={{
            'fill-color': [
              'match',
              ['id'],
              selectedTilesetFeature?.id || 0,
              '#0037ff',
              '#cca329',
            ],
            'fill-outline-color': '#fcba03',
            'fill-opacity': [
              'match',
              ['id'],
              selectedTilesetFeature?.id || 0,
              1,
              0.3,
            ],
          }}
        />
        <Layer
          id={TILESET_FILL_OUTLINE_LAYER_PREFIX + tile.id}
          type="line"
          filter={['==', ['geometry-type'], 'Polygon']}
          source={sourceLayerId}
          source-layer="features"
          paint={{
            'line-color': '#f6ff00',
            'line-width': 0.5,
          }}
        />
        <Layer
          id={TILESET_LINE_LAYER_PREFIX + tile.id}
          type="line"
          filter={['==', ['geometry-type'], 'LineString']}
          source={sourceLayerId}
          source-layer="features"
          paint={{
            'line-color': '#ff7b00',
            'line-width': 1,
          }}
        />
        <Layer
          id={TILESET_CIRCLE_LAYER_PREFIX + tile.id}
          type="circle"
          filter={['==', ['geometry-type'], 'Point']}
          source={sourceLayerId}
          source-layer="features"
          paint={{
            'circle-radius': [
              'match',
              ['id'],
              selectedTilesetFeature?.id || 0,
              4,
              2,
            ],
            'circle-color': [
              'match',
              ['id'],
              selectedTilesetFeature?.id || 0,
              '#0037ff',
              '#fcba03',
            ],
            'circle-stroke-width': 0.5,
            'circle-stroke-color': 'black',
          }}
        />
        <Layer
          id={TILESET_SYMBOL_LAYER_PREFIX + tile.id}
          type="symbol"
          source={sourceLayerId}
          filter={['has', 'description']}
          source-layer="features"
          layout={{
            'text-field': ['get', 'description'],
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
            'text-size': 10,
            'text-anchor': 'bottom',
            'text-offset': [0, 1.5],
          }}
          paint={{
            'text-color': '#fcba03',
            'text-halo-color': '#000000',
            'text-halo-width': 1,
          }}
        />
      </Source>
    )
  }
}
