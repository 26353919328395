import { action, observable } from 'mobx'

import { ISiteLocation } from '~/client/graph'
import Basemap from '~/client/src/shared/models/Basemap'
import Sitemap from '~/client/src/shared/models/Sitemap'
import Guard from '~/client/src/shared/utils/Guard'

import MapViewSetUpStore, { SetUpSteps } from '../MapViewSetUp.store'
import { ViewType } from './MapBoxEditor.store'

export default class SitemapControlStore {
  @observable public shouldShowEditBasemapMenu: boolean = false
  @observable public shouldShowCreateMapFileMenu: boolean = false
  @observable public shouldShowCreatePlanMenu: boolean = false
  @observable public shouldShowTooltipMenu: boolean = false
  @observable public whiteboardMode: boolean = false
  @observable public pdfFile: File = null
  @observable public sitemapPdfFile: File = null
  @observable public location: ISiteLocation = null
  @observable public sitemapName: string = ''
  @observable public isUploading: boolean = false
  @observable public basemapFile: File = null
  @observable public tilesetFile: File = null
  @observable public basemap: Basemap

  public constructor(
    private readonly mapViewSetupStore: MapViewSetUpStore,
    private readonly setStep: (step: SetUpSteps) => void,
  ) {
    Guard.requireAll({
      mapViewSetupStore,
    })
  }

  @action.bound
  public async setConvertedBasemap(imgFile: Blob) {
    this.shouldShowTooltipMenu = true
    if (imgFile) {
      await this.updateBasemap(imgFile)
    }

    this.pdfFile = null
    this.shouldShowTooltipMenu = false
  }

  @action.bound
  public applyLocation(location: ISiteLocation): void {
    this.location = location
  }

  @action.bound
  public changeMapName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.sitemapName = event.currentTarget.value
  }

  @action.bound
  public async setConvertedSitemap(imgFile: Blob): Promise<void> {
    this.shouldShowCreatePlanMenu = true
    if (imgFile) {
      await this.saveNewSitemap(imgFile)
    }
    if (!this.whiteboardMode) {
      this.setStep(SetUpSteps.alignPlan)
    }

    this.sitemapPdfFile = null
    this.shouldShowCreatePlanMenu = false
  }

  @action.bound
  public toggleTooltipMenu(): void {
    this.shouldShowTooltipMenu = !this.shouldShowTooltipMenu
    this.clearForm()
  }

  @action.bound
  public hideTooltipMenu(): void {
    this.shouldShowTooltipMenu = false
  }

  @action.bound
  public toggleCreateMapFileMenu(): void {
    this.shouldShowCreateMapFileMenu = !this.shouldShowCreateMapFileMenu
    if (this.shouldShowCreatePlanMenu) {
      this.setStep(SetUpSteps.uploadPlan)
    } else {
      this.setStep(null)
    }
    this.clearForm()
  }

  @action.bound
  public toggleCreatePlanMenu(whiteboardOnlyMode?: boolean): void {
    this.whiteboardMode = whiteboardOnlyMode
    this.shouldShowCreatePlanMenu = !this.shouldShowCreatePlanMenu
    if (this.shouldShowCreatePlanMenu) {
      this.setStep(SetUpSteps.uploadPlan)
    } else {
      this.setStep(null)
    }
    this.clearForm()
  }

  @action.bound
  public hideEditMenu(): void {
    this.shouldShowEditBasemapMenu = false
    this.clearForm()
  }

  @action.bound
  public toggleEditMenu(): void {
    this.shouldShowEditBasemapMenu = !this.shouldShowEditBasemapMenu
    this.clearForm()
  }

  @action.bound
  public async setPdfOrUpdateBasemap(): Promise<void> {
    this.isUploading = true
    if (!this.basemapFile) {
      return
    }

    if (this.isPdfTypeBasemapSelected) {
      this.pdfFile = this.basemapFile
      this.hideTooltipMenu()
      return
    }

    await this.updateBasemap(this.basemapFile)
  }

  @action.bound
  public async setPdfOrCreateSitemap(): Promise<void> {
    this.isUploading = true
    if (!this.basemapFile) {
      return
    }

    if (this.isPdfTypeBasemapSelected) {
      this.sitemapPdfFile = this.basemapFile
      this.hideCreateMenu(false)
      return
    }

    await this.saveNewSitemap(this.basemapFile)
    if (!this.whiteboardMode) {
      this.setStep(SetUpSteps.alignPlan)
      this.mapViewSetupStore.mapBoxEditorStore.setLeftVisibility()
    }
  }

  public get isPdfTypeBasemapSelected(): boolean {
    return this.basemapFile?.type === 'application/pdf'
  }

  public showDeleteDialog = (): void => {
    const { showDeleteConfirmationDialogBySitemap, selectedSitemap } =
      this.mapViewSetupStore.sitemapsSetupStore
    showDeleteConfirmationDialogBySitemap(selectedSitemap)
  }

  @action.bound
  public async setSitemapBasemap(file: File): Promise<void> {
    this.isUploading = true
    this.basemapFile = file
    this.basemap =
      await this.mapViewSetupStore.sitemapsSetupStore.uploadBaseMap(file)
    this.isUploading = false
  }

  @action.bound
  public async setSitemapBasemapFromBrowse(event) {
    await this.setSitemapBasemap(event.target.files[0])
  }

  @action.bound
  public setTileFile(file: File): void {
    this.tilesetFile = file
  }

  @action.bound
  public resetMapFIleSelection(): void {
    this.resetTileFile()
    this.resetBasemapFile()
  }

  @action.bound
  public resetBasemapFile(): void {
    this.basemapFile = null
    this.basemap = null
  }

  @action.bound
  public resetTileFile(): void {
    this.tilesetFile = null
  }

  @action.bound
  public async uploadTileFile(): Promise<void> {
    this.isUploading = true
    await this.mapViewSetupStore.tilesetsSetupStore.uploadGeoTIFFToMapbox(
      this.tilesetFile,
    )
    this.isUploading = false
    this.toggleCreateMapFileMenu()
  }

  @action.bound
  private async saveNewSitemap(imgFile?: File | Blob): Promise<void> {
    const sitemap =
      await this.mapViewSetupStore.sitemapsSetupStore.uploadNewSitemap(
        imgFile,
        this.sitemapName,
        true,
      )
    if (this.whiteboardMode) {
      this.mapViewSetupStore.globeViewSetupStore.deselectGlobe()
      this.mapViewSetupStore.onViewTypeSelect(ViewType.Objects)
    }
    await this.mapViewSetupStore.sitemapsSetupStore.selectSitemap(sitemap)
    this.isUploading = false
    this.updateAssociations(sitemap)
    this.hideCreateMenu()
    this.clearForm()
  }

  private updateAssociations = (sitemap: Sitemap): void => {
    if (!this.location) {
      return
    }

    const { updateSitemapAssignedItems } =
      this.mapViewSetupStore.mapViewItemsSetupStore

    updateSitemapAssignedItems([this.location.id], sitemap)
  }

  @action.bound
  private hideCreateMenu(shouldClearForm: boolean = true): void {
    this.shouldShowCreatePlanMenu = false
    this.shouldShowCreateMapFileMenu = false
    if (shouldClearForm) {
      this.clearForm()
    }
  }

  @action.bound
  private clearForm(): void {
    this.basemapFile = null
    this.basemap = null
    this.sitemapPdfFile = null
    this.pdfFile = null
    this.location = null
    this.sitemapName = ''
  }

  @action.bound
  private async updateBasemap(imgFile?: File | Blob): Promise<void> {
    const { selectedSitemap, updateBasemapForSitemap } =
      this.mapViewSetupStore.sitemapsSetupStore

    await updateBasemapForSitemap(imgFile || this.basemapFile, selectedSitemap)

    this.isUploading = false
    this.hideEditMenu()
  }
}
