import React from 'react'

import { FileInput } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DndArea from '~/client/src/desktop/components/DndArea/DndArea'
import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import FileType from '~/client/src/shared/enums/FileType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import { ACCEPTED_SITEMAP_EXTENSIONS } from '../../../MapViewSetUp.store'
import MapViewItemsSetupStore from '../../../stores/MapViewItemsSetup.store'
import SitemapControlStore from '../../../stores/SitemapControl.store'

interface IProps {
  store: SitemapControlStore
  shouldFixTopPosition?: boolean

  mapViewItemsSetupStore?: MapViewItemsSetupStore
  allowUploadOnlyWhiteboard?: boolean
}

const uploadSitePlan = 'Upload site plan'
const uploadingBasemap = 'Uploading basemap'

const EXTENSIONS_SEPARATOR = ','
const mapName = 'Plan name'
const dragMapHere = 'Drag map here'
const acceptedExtensions = '(.pdf, .png, .tif accepted)'
const basemapUploaded = 'Basemap uploaded'
const browseYourHardDrive = 'Browse your hard drive'
const uploadAsWhiteboard = 'Upload as Whiteboard'

@observer
export default class UploadSitemapDialog extends React.Component<IProps> {
  public componentDidUpdate(): void {
    if (
      this.props.allowUploadOnlyWhiteboard &&
      !this.props.store.whiteboardMode
    ) {
      this.setShouldUploadAsWhiteboard(true)
    }
  }

  public render(): JSX.Element {
    const { shouldFixTopPosition, allowUploadOnlyWhiteboard } = this.props
    const {
      sitemapName,
      isUploading,
      basemap,
      whiteboardMode,
      setSitemapBasemap,
      changeMapName,
      setPdfOrCreateSitemap,
      toggleCreatePlanMenu: toggleCreateMenu,
    } = this.props.store

    const isSaveButtonActive = !isUploading && !!sitemapName && !!basemap

    return (
      <MenuCloser
        className={classList({
          'absolute map-upload-block': true,
          'fixed-top': shouldFixTopPosition,
        })}
        closeMenu={toggleCreateMenu}
      >
        <div className="col">
          <div className="text size24 mb30">{uploadSitePlan}</div>
          <div className="row y-start">
            <div>
              <div className="general-map-setup-dnd-area">
                {!basemap ? (
                  <>
                    <DndArea
                      fileType={FileType.Image}
                      accept={ACCEPTED_SITEMAP_EXTENSIONS}
                      title={this.title}
                      onDrop={setSitemapBasemap}
                      className="upload-schedule-dnd-container col x-center y-center"
                    />
                    {this.renderBrowseComputerLine()}
                  </>
                ) : (
                  <DesktopFileInput
                    id={basemap.id}
                    name=""
                    value={basemap.source}
                    isReadonly={true}
                    textClassName="hint"
                    shouldHideIconAndOutline={true}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row mt5 relative overflow-hidden">
            <StruxhubInput
              id="sitemap-name-input"
              label={mapName}
              isRequired={true}
              value={sitemapName}
              onChange={changeMapName}
            />
          </div>
          <div>
            <Checkbox
              label={uploadAsWhiteboard}
              isChecked={whiteboardMode}
              isDisabled={allowUploadOnlyWhiteboard}
              onClick={() => this.setShouldUploadAsWhiteboard(!whiteboardMode)}
            />
          </div>
          <div
            className={classList({
              'submit-button light pa10 full-width mt30': true,
              'inactive-element unclickable-element': !isSaveButtonActive,
            })}
            onClick={setPdfOrCreateSitemap}
          >
            {isUploading ? <Loader /> : Localization.translator.save}
          </div>
        </div>
      </MenuCloser>
    )
  }

  private get title(): string | JSX.Element {
    const { isUploading, sitemapPdfFile } = this.props.store
    if (isUploading) {
      return <Loader hint={uploadingBasemap} />
    }

    if (sitemapPdfFile) {
      return basemapUploaded
    }

    return (
      <div className="col text large no-grow x-center">
        <span>
          <Icons.Upload className="no-grow" /> {dragMapHere}
        </span>
        <span>{acceptedExtensions}</span>
      </div>
    )
  }

  private renderBrowseComputerLine = (): JSX.Element => {
    return (
      <div>
        <FileInput
          className="top-controls-upload-csv-button tooltip-menu-option"
          disabled={false}
          inputProps={{
            onChange: this.props.store.setSitemapBasemapFromBrowse,
            accept: ACCEPTED_SITEMAP_EXTENSIONS.join(EXTENSIONS_SEPARATOR),
            value: EMPTY_STRING,
          }}
          text={
            <span className="row text tooltip-menu-option px10 large pointer light-blue bold x-end">
              {!this.props.store.basemap
                ? browseYourHardDrive
                : Localization.translator.change_verb}
            </span>
          }
        />
      </div>
    )
  }

  private setShouldUploadAsWhiteboard(value: boolean): void {
    this.props.store.whiteboardMode = value
  }
}
