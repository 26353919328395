import * as React from 'react'

import { Dialog, Icon, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { Table } from 'react-virtualized'

import BaseObservableTable, {
  DEFAULT_ROW_HEIGHT,
} from '~/client/src/desktop/components/BaseObservableTable/BaseObservableTable'
import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import * as Icons from '~/client/src/shared/components/Icons'
import {
  ILWFCCategory,
  ILWFCColumn,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import TableSeparators from '~/client/src/shared/components/TableSeparators'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import GlobeView from '~/client/src/shared/models/GlobeView'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Sitemap from '~/client/src/shared/models/Sitemap'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import {
  getBackgroundColor,
  getTextColor,
} from '~/client/src/shared/utils/tableColoring'

import GlobeAssociationControl from '../../GlobeAssociationControl'
import MapViewSetUpStore, { MenuViewMode } from '../../MapViewSetUp.store'
import MapViewsListStore, {
  DataKeys,
  MapView,
  MapViewType,
  site,
} from '../../stores/MapViewsList.store'
import SitemapAssociationControl from '../SitemapAssociationControl'
import GlobeLocationAssociationModal from '../dialogs/GlobeLocationAssociationModal'
import LocationAssociationModal from '../dialogs/LocationAssociationModal'
import EditableLabel from '../dialogs/ViewSelectDialog/components/EditableLabel'

import './Ribbons.scss'

interface IProps {
  shouldHideAdditionMenu?: boolean
  shouldFixTopPosition?: boolean
  isHidden?: boolean
  store: MapViewSetUpStore

  globeViewsStore?: GlobeViewsStore
  state?: DesktopInitialState
  sitemapsStore?: SitemapsStore
}

const Z_INDEX = 100
const VIEW_COLUMN_MAX_WIDTH = 490
const lbsTag = 'LBS Tag'
const current = 'Current'
const createNewView = 'Create new View'
const createNewWhiteboard = 'Create new Whiteboard'
const SEARCH_DEFAULT = 'Search'

@inject('state', 'globeViewsStore', 'sitemapsStore')
@observer
export default class GlobeRibbon extends React.Component<IProps> {
  private tableRef: Table = null
  @observable private editingGlobe: GlobeView = null
  @observable private editingSitemap: Sitemap = null
  private editableRefs = {}
  private plansCollapseMap = {}

  public constructor(props: IProps) {
    super(props)
  }

  public render(): JSX.Element {
    const { isLoading } = this.props.store.globeViewSetupStore
    const { globeViewSetupStore, mapViewItemsSetupStore, sitemapsSetupStore } =
      this.props.store
    const { isAssignGlobeDialogShown, hideAssignGlobeDialog } =
      globeViewSetupStore
    const { isAssignSitemapDialogShown, hideAssignSitemapDialog } =
      sitemapsSetupStore

    return (
      <Dialog
        isOpen={true}
        className="base-add-edit-wrapper globe-ribbon relative brada4"
      >
        <MenuCloser
          shouldPreventClosing={isAssignGlobeDialogShown}
          isOpen
          closeMenu={this.props.store.toggleGlobeList}
        >
          <div
            className={classList({
              'globe-select-menu col full-height relative': true,
              'unclickable-element': isLoading,
            })}
          >
            {isLoading && (
              <>
                <div
                  className="absolute full-height full-width bg-white"
                  style={{ zIndex: Z_INDEX }}
                />
                <Loader className="globe-select-loader" />
              </>
            )}
            {isAssignGlobeDialogShown && (
              <div className="absolute-block absolute-block-x-center">
                <GlobeLocationAssociationModal
                  onClose={hideAssignGlobeDialog}
                  mapViewItemsSetupStore={mapViewItemsSetupStore}
                  globeViewSetupStore={globeViewSetupStore}
                  className="absolute bg-white bp3-portal"
                  globe={this.editingGlobe}
                />
              </div>
            )}
            {isAssignSitemapDialogShown && (
              <div className="absolute-block absolute-block-x-center">
                <LocationAssociationModal
                  onClose={hideAssignSitemapDialog}
                  mapViewItemsSetupStore={mapViewItemsSetupStore}
                  sitemapsSetupStore={sitemapsSetupStore}
                  sitemap={this.editingSitemap}
                />
              </div>
            )}
            {this.renderHeader()}
            <div className="globe-select-content">{this.renderTable()}</div>
          </div>
        </MenuCloser>
      </Dialog>
    )
  }

  private renderHeader(): JSX.Element {
    const { toggleGlobeList } = this.props.store

    const isGlobeTabActive =
      this.listStore.selectedViewMode === MenuViewMode.globes

    return (
      <div className="row px10 globe-select-header bb-grey-scale-light">
        <Icon
          intent={Intent.NONE}
          size={20}
          className="no-grow globe-select-header-icon mr10 pointer"
          icon={IconNames.CROSS}
          onClick={toggleGlobeList}
        />
        <div
          className={classList({
            'text large pa5 bold x-center full-height row y-center': true,
            'bg-white default-cursor': isGlobeTabActive,
            'globe-select-header-text bg-cool-grey pointer': !isGlobeTabActive,
          })}
          onClick={this.setGlobesViewMode}
        >
          {Localization.translator.globeViews}
        </div>
        <div
          className={classList({
            'globe-select-header-text text large pa5 bold x-center full-height row y-center':
              true,
            'bg-white default-cursor': !isGlobeTabActive,
            'globe-select-header-text bg-cool-grey pointer': isGlobeTabActive,
          })}
          onClick={this.setWhiteboardsViewMode}
        >
          {Localization.translator.whiteboards}
        </div>
        <BaseActionButton
          className="primary-theme mx10"
          isEnabled={true}
          title={isGlobeTabActive ? createNewView : createNewWhiteboard}
          onClick={
            isGlobeTabActive ? this.createNewGlobe : this.createNewSitemap
          }
        />
        {this.renderSearchBar()}
      </div>
    )
  }

  @action.bound
  private createNewGlobe(): void {
    this.props.store.isGlobeListShown = false
    const { globeCorners, viewport } = this.props.store.mapBoxViewerStore
    this.props.store.globeViewSetupStore.createNewPlaceholder(
      globeCorners,
      viewport,
    )
  }

  @action.bound
  private createNewSitemap(): void {
    this.props.store.isGlobeListShown = false
    this.props.store.sitemapControlStore.toggleCreatePlanMenu(true)
  }

  private renderSearchBar(): JSX.Element {
    const { searchKey } = this.props.state.globeFilters

    return (
      <div className="search-bar relative">
        <Icon
          icon={IconNames.SEARCH}
          className="search-bar-icon absolute"
          iconSize={Icon.SIZE_LARGE}
        />
        <StruxhubInput
          onChange={this.changeSearchText}
          value={searchKey}
          placeholder={SEARCH_DEFAULT}
          isMinimalisticMode={true}
        />
      </div>
    )
  }

  @action.bound
  private changeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    this.props.state.globeFilters.searchKey = event.target.value
  }

  private renderTable(): JSX.Element {
    return (
      <div className="table-small relative full-height map-view-table">
        <BaseObservableTable
          setTableRef={this.setTableRef}
          store={this.listStore}
          autoSizerClassName="full-height full-width"
          valueRenderer={this.renderValue}
          rowHeightGetter={this.getRowHeight}
          headerRowRenderer={this.headerRowRenderer}
          categoryContent={this.renderCategoryCell}
          dataCellCustomClassName="y-center"
          categoryRowCustomClassName="row text large bold primary-blue cell bb-cool-grey category-cell full-height no-select pointer"
          shouldPreventCollapsing={true}
          fixedWidth={this.listStore.tableWidth}
          handleCellClick={this.handleRowClick}
        />
      </div>
    )
  }

  private setTableRef = (tableRef: Table) => {
    this.tableRef = tableRef
  }

  private renderValue = (
    value: any,
    dataKey: string,
    data: LWFCRowData,
    recomputeGridSize?: () => void,
    level?: number,
  ): JSX.Element => {
    switch (dataKey) {
      case DataKeys.View:
        return this.renderViewValue(value, level)
      case DataKeys.PublishTo:
        return this.renderPublishTo(value, recomputeGridSize)
      case DataKeys.Objects:
        return this.renderObjectsValue(value)
      case DataKeys.None:
        return this.renderNonesValue(value)
    }
  }

  private renderNonesValue(view: MapView): JSX.Element {
    const { selectDeletableGlobe, selectedGlobeViewId, copyGlobe } =
      this.props.store.globeViewSetupStore
    const { showDeleteConfirmationDialogBySitemap, duplicateSitemap } =
      this.props.store.sitemapsSetupStore

    const shouldHideDeleteAction =
      view.id === selectedGlobeViewId ||
      this.props.store.globeViewsStore.list.length <= 1
    let editFn
    let duplicateFn
    let deleteFn
    switch (view.type) {
      case MapViewType.GlobeView:
        const globe = this.props.globeViewsStore.byId.get(view.id)
        editFn = this.onEditNameClick.bind(null, view.id)
        duplicateFn = copyGlobe.bind(null, globe)
        deleteFn = selectDeletableGlobe.bind(null, globe)
        break
      case MapViewType.Whiteboard:
        const sitemap = this.props.sitemapsStore.byId.get(view.id)
        editFn = this.onEditNameClick.bind(null, view.id)
        duplicateFn = duplicateSitemap.bind(null, view.id)
        deleteFn = showDeleteConfirmationDialogBySitemap.bind(null, sitemap)
        break
    }
    return (
      <div
        className={classList({
          'full-height full-width row y-center': true,
          pl35: shouldHideDeleteAction,
          'bg-blue-active': this.isSelected(view),
        })}
      >
        <Icons.GlobeEdit
          className="no-grow ml10"
          onClick={this.actionRowClick.bind(null, editFn)}
        />
        <Icons.GlobeDuplicate
          className="no-grow mx10"
          onClick={this.actionRowClick.bind(null, duplicateFn)}
        />
        {!shouldHideDeleteAction && (
          <Icons.GlobeDelete
            className="no-grow mr10"
            onClick={this.actionRowClick.bind(null, deleteFn)}
          />
        )}
      </div>
    )
  }

  @action.bound
  private actionRowClick(
    callback: () => void,
    event: React.SyntheticEvent,
  ): void {
    event.stopPropagation()
    callback()
  }

  @action.bound
  private onEditNameClick(id: string): void {
    this.editableRefs[id].triggerEditMode()
  }

  private renderObjectsValue({ view, items }): JSX.Element {
    return (
      <div
        className={classList({
          'text grey large full-height row y-center x-start': true,
          'bg-blue-active': this.isSelected(view),
        })}
      >
        {items.length}
      </div>
    )
  }

  private renderViewValue(view: MapView, level: number): JSX.Element {
    const {
      sitemapsSetupStore,
      mapViewItemsSetupStore,
      globeViewSetupStore,
      mapViewsListStore,
    } = this.props.store
    const { selectedGlobeViewId, updateGlobeName, globeViewsMap } =
      globeViewSetupStore
    const { selectedSitemap, updateSitemapName, sitemapsMap } =
      sitemapsSetupStore
    const isExpanded = this.plansCollapseMap[view.id]
    let updateFn
    const isSelected =
      view.id === selectedGlobeViewId || view.id === selectedSitemap?.id
    switch (view.type) {
      case MapViewType.GlobeView:
        const globe = this.props.globeViewsStore.byId.get(view.id)
        updateFn = updateGlobeName.bind(this, globe)
        break
      case MapViewType.Whiteboard:
        const sitemap = this.props.sitemapsStore.byId.get(view.id)
        updateFn = updateSitemapName.bind(this, sitemap)
        break
    }

    return (
      <div
        className={classList({
          'row full-height ml10 globe-ribbon-holder': true,
          'bg-blue-active': isSelected,
        })}
        style={{ maxWidth: VIEW_COLUMN_MAX_WIDTH }}
      >
        <div className="col full-height sitemap-image-holder row">
          <TableSeparators
            level={level}
            className="no-grow full-height br-palette-grey ml10"
          />
          <div className="no-grow globe-image-holder">
            <DesktopFileInput
              id={view.id}
              name=""
              value={view.filledImage}
              isReadonly={true}
              textClassName="hint"
              shouldHideIconAndOutline={true}
              shouldHideName={true}
              className="unclickable-element"
            />
          </div>
          <div
            className={classList({
              'col ml20 y-center full-width': true,
              'full-height': isExpanded,
            })}
            style={{ maxWidth: 120 }}
          >
            <div className="row full-width">
              <EditableLabel
                ref={this.setEditableRef.bind(null, view.id)}
                text={view.name}
                update={updateFn}
                className="editable-label text bold large px10 brada4 text-ellipsis bg-unset"
                inactiveHolderClassName="w-fit-content"
              />
              {view.id === selectedGlobeViewId ||
                (view.id === selectedSitemap?.id && (
                  <div className="no-grow mx10 text uppercase gray-back white small px5 brada4">
                    {current}
                  </div>
                ))}
            </div>
          </div>
          <div className="col px10 y-between no-grow">
            <div className="row text uppercase small light lp15 bold">
              {lbsTag}
            </div>
            <div className="row">
              {mapViewsListStore.selectedViewMode === MenuViewMode.globes ? (
                <GlobeAssociationControl
                  mapViewItemsSetupStore={mapViewItemsSetupStore}
                  globeViewSetupStore={globeViewSetupStore}
                  globe={globeViewsMap.get(view.id)}
                  shouldHideModal={true}
                  onToggle={this.onGlobeModalToggle.bind(
                    null,
                    globeViewsMap.get(view.id),
                  )}
                />
              ) : (
                <SitemapAssociationControl
                  mapViewItemsSetupStore={mapViewItemsSetupStore}
                  sitemapsSetupStore={sitemapsSetupStore}
                  sitemap={sitemapsMap.get(view.id)}
                  shouldHideModal={true}
                  onToggle={this.onModalToggle.bind(
                    null,
                    sitemapsMap.get(view.id),
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  private setEditableRef = (id: string, ref): void => {
    this.editableRefs[id] = ref
  }

  private handleRowClick = (
    rowData: LWFCRowData,
    column?: ILWFCColumn,
  ): void => {
    const instanceId = rowData[DataKeys.id]
    if (this.listStore.isInstanceSelected(instanceId)) {
      return
    }
    if (column.dataKey === DataKeys.CHECKBOX) {
      this.listStore.toggleInstance(instanceId)
      return
    }
    this.listStore.resetHiddenSelection()
    this.listStore.toggleHiddenInstance(instanceId)
    this.props.store.selectMapView(rowData[DataKeys.View].type, rowData.id)
    this.props.store.toggleGlobeList()
  }

  private getRowHeight = ({ index }): number => {
    const row = this.listStore.rows[index]
    if (!index) {
      return 0
    }
    return (row && row.height) || DEFAULT_ROW_HEIGHT
  }

  private headerRowRenderer = (): JSX.Element => {
    return (
      <div
        style={{ height: DEFAULT_ROW_HEIGHT }}
        className="row header-row bb-brand-dark y-end"
      >
        {this.listStore.columns.map(({ dataKey, width, translatorKey }) => {
          return (
            <div
              key={dataKey}
              style={{ minWidth: width, maxWidth: width }}
              className={classList({
                'row y-end text uppercase no-outline-container cell cell-header pb5 bold header-icon':
                  true,
                pl20: dataKey === DataKeys.View,
              })}
            >
              {translatorKey && Localization.getText(translatorKey)}
            </div>
          )
        })}
      </div>
    )
  }

  private renderCategoryCell = (
    category: ILWFCCategory,
    dataKey: string,
    gridProps: any,
    data: LWFCRowData,
    level?: number,
    recomputeTableSize?: () => void,
  ): JSX.Element => {
    const { collapsedCategories } = this.listStore
    const { categoryLabel, categoryId, isChecked } = category

    const deliveryAttribute = data as LocationAttributeBase
    const backgroundColor = getBackgroundColor(categoryId, deliveryAttribute)
    const textColor = getTextColor(categoryId)
    const icon = collapsedCategories.get(categoryId)
      ? IconNames.CARET_UP
      : IconNames.CARET_DOWN

    let content: JSX.Element
    if (dataKey === DataKeys.CHECKBOX) {
      content = (
        <div className="row ml20 full-height">
          <Checkbox
            className={classList({
              'checkbox-selector text large no-bold no-grow mr10': true,
              'light-checkbox': categoryId === site,
              'dark-checkbox': categoryId !== site,
            })}
            isCentered={true}
            isChecked={isChecked}
            onClick={this.listStore.toggleCategory.bind(null, category)}
          />
        </div>
      )
    }
    if (dataKey === DataKeys.View) {
      content = (
        <div className="row full-height ml10">
          <TableSeparators
            level={level}
            className="no-grow full-height br-palette-grey ml10"
          />
          <div>
            <Icon
              className="pointer text grey ml3"
              icon={icon}
              onClick={this.toggleCategoryCollapsing.bind(
                null,
                categoryId,
                recomputeTableSize,
              )}
            />
            <SitemapAttributeTag
              dataObject={deliveryAttribute as LocationBase}
              shouldShowAsTag={false}
              contentContainerClassName="text-ellipsis py2"
            >
              <span title={categoryLabel}>{categoryLabel}</span>
            </SitemapAttributeTag>
          </div>
        </div>
      )
    }

    return (
      <div
        {...gridProps}
        style={{ backgroundColor, color: textColor }}
        className="text large cell category-cell category-row full-height no-select pointer"
      >
        {content}
      </div>
    )
  }

  @action.bound
  private toggleCategoryCollapsing(
    categoryId: string,
    recomputeTableSize?: () => void,
  ): void {
    this.listStore.toggleCategoryCollapsing(categoryId)
    recomputeTableSize?.()
  }

  private get listStore(): MapViewsListStore {
    return this.props.store.mapViewsListStore
  }

  @action.bound
  private onGlobeModalToggle(globe: GlobeView, event): void {
    event.stopPropagation()
    this.editingGlobe = globe
    this.props.store.globeViewSetupStore.toggleAssignGlobeDialog()
  }

  @action.bound
  private onModalToggle(sitemap: Sitemap, event): void {
    event.stopPropagation()
    this.editingSitemap = sitemap
    this.props.store.sitemapsSetupStore.toggleAssignSitemapDialog()
  }

  private renderPublishTo(
    view: MapView,
    recomputeGridSize?: () => void,
  ): JSX.Element {
    const {
      isDeliveriesDisabled,
      isFormsDisabled,
      isLogisticsDisabled,
      isTrackerDisabled,
    } = this.props.state
    const {
      siteAssignedSitemaps,
      formsAssignedSitemaps,
      activitiesAssignedSitemaps,
      deliveriesAssignedSitemaps,
    } = this.props.store.sitemapsSetupStore
    const {
      siteAssignedGlobes,
      formsAssignedGlobes,
      activitiesAssignedGlobes,
      deliveriesAssignedGlobes,
    } = this.props.store.globeViewSetupStore
    let siteAssigned
    let deliveriesAssigned
    let formsAssigned
    let activitiesAssigned
    if (this.listStore.selectedViewMode === MenuViewMode.globes) {
      siteAssigned = siteAssignedGlobes
      deliveriesAssigned = deliveriesAssignedGlobes
      formsAssigned = formsAssignedGlobes
      activitiesAssigned = activitiesAssignedGlobes
    } else {
      siteAssigned = siteAssignedSitemaps
      deliveriesAssigned = deliveriesAssignedSitemaps
      formsAssigned = formsAssignedSitemaps
      activitiesAssigned = activitiesAssignedSitemaps
    }

    return (
      <div
        className={classList({
          'row full-height': true,
          'bg-blue-active': this.isSelected(view),
        })}
      >
        {!isLogisticsDisabled && (
          <div
            className="relative no-grow"
            onClick={this.onSiteSitemapSectionClick.bind(
              null,
              view,
              recomputeGridSize,
            )}
          >
            {!siteAssigned[view.id] ? (
              <Icons.HomeRoundedCrossed className="no-grow" />
            ) : (
              <Icons.HomeRounded className="no-grow publish-to-icon" />
            )}
          </div>
        )}
        {!isFormsDisabled && (
          <div
            className="relative no-grow"
            onClick={this.onFormsSitemapSectionClick.bind(
              null,
              view,
              recomputeGridSize,
            )}
          >
            {!formsAssigned[view.id] ? (
              <Icons.PermitsRoundedCrossed className="no-grow" />
            ) : (
              <Icons.PermitsRoundedActive className="no-grow publish-to-icon" />
            )}
          </div>
        )}
        {!isDeliveriesDisabled && (
          <div
            className="relative no-grow"
            onClick={this.onDeliveriesSectionClick.bind(
              null,
              view,
              recomputeGridSize,
            )}
          >
            {!deliveriesAssigned[view.id] ? (
              <Icons.DeliveryRoundedCrossed className="no-grow" />
            ) : (
              <Icons.DeliveryRounded className="no-grow publish-to-icon" />
            )}
          </div>
        )}
        {!isTrackerDisabled && (
          <div
            className="relative no-grow"
            onClick={this.onActivitiesSitemapSectionClick.bind(
              null,
              view,
              recomputeGridSize,
            )}
          >
            {!activitiesAssigned[view.id] ? (
              <Icons.ScheduleRoundedCrossed className="no-grow" />
            ) : (
              <Icons.ScheduleRounded className="no-grow publish-to-icon" />
            )}
          </div>
        )}
      </div>
    )
  }

  @action.bound
  private onSiteSitemapSectionClick(
    mapView: MapView,
    recomputeGridSize: () => void,
    event,
  ): void {
    event.stopPropagation()
    const { sitemapsSetupStore, globeViewSetupStore, mapViewsListStore } =
      this.props.store
    if (mapViewsListStore.selectedViewMode === MenuViewMode.globes) {
      globeViewSetupStore.onSiteGlobeSectionClick(mapView.id)
    } else {
      sitemapsSetupStore.onSiteSitemapSectionClick(
        sitemapsSetupStore.sitemapsMap.get(mapView.id),
      )
    }
    recomputeGridSize()
  }

  @action.bound
  private onFormsSitemapSectionClick(
    mapView: MapView,
    recomputeGridSize: () => void,
    event,
  ): void {
    event.stopPropagation()
    const { sitemapsSetupStore, globeViewSetupStore, mapViewsListStore } =
      this.props.store
    if (mapViewsListStore.selectedViewMode === MenuViewMode.globes) {
      globeViewSetupStore.onFormsGlobeSectionClick(mapView.id)
    } else {
      sitemapsSetupStore.onFormsSitemapSectionClick(
        sitemapsSetupStore.sitemapsMap.get(mapView.id),
      )
    }
    recomputeGridSize()
  }

  @action.bound
  private onActivitiesSitemapSectionClick(
    mapView: MapView,
    recomputeGridSize: () => void,
    event,
  ): void {
    event.stopPropagation()
    const { sitemapsSetupStore, globeViewSetupStore, mapViewsListStore } =
      this.props.store
    if (mapViewsListStore.selectedViewMode === MenuViewMode.globes) {
      globeViewSetupStore.onActivitiesSitemapSectionClick(mapView.id)
    } else {
      sitemapsSetupStore.onActivitiesSitemapSectionClick(
        sitemapsSetupStore.sitemapsMap.get(mapView.id),
      )
    }
    recomputeGridSize()
  }

  @action.bound
  private onDeliveriesSectionClick(
    mapView: MapView,
    recomputeGridSize: () => void,
    event,
  ): void {
    event.stopPropagation()
    const { sitemapsSetupStore, globeViewSetupStore, mapViewsListStore } =
      this.props.store
    if (mapViewsListStore.selectedViewMode === MenuViewMode.globes) {
      globeViewSetupStore.onDeliveryGlobeSectionClick(mapView.id)
    } else {
      sitemapsSetupStore.onDeliveriesSectionClick(
        sitemapsSetupStore.sitemapsMap.get(mapView.id),
      )
    }
    recomputeGridSize()
  }

  private isSelected(view: MapView): boolean {
    const {
      sitemapsSetupStore: { selectedSitemap },
      globeViewSetupStore: { selectedGlobeViewId },
    } = this.props.store

    return view.id === selectedGlobeViewId || view.id === selectedSitemap?.id
  }

  @action.bound
  private setGlobesViewMode(): void {
    this.listStore.setViewMode(MenuViewMode.globes)
    this.tableRef?.recomputeGridSize()
  }

  @action.bound
  private setWhiteboardsViewMode(): void {
    this.listStore.setViewMode(MenuViewMode.whiteboards)
    this.tableRef?.recomputeGridSize()
  }
}
