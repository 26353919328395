import React from 'react'

import { observer } from 'mobx-react'
import { Layer, Source } from 'react-map-gl'

import {
  ISitemapWithBasemap,
  MAX_ITEM_ZOOM_LEVEL,
  PROJECT_MARKER_ZOOM_LEVEL,
} from '../../../MapBoxViewer/MapBoxViewer.store'
import {
  PLAN_LAYER_PREFIX,
  PLAN_SOURCE,
} from '../../../MapBoxViewer/mapboxConstants'

interface IProps {
  isVisible: boolean
  idx: number
  sitemapWithBasemap: ISitemapWithBasemap
}

@observer
export default class GlobeViewPlan extends React.Component<IProps> {
  public render(): JSX.Element {
    const { sitemap, basemap } = this.props.sitemapWithBasemap
    const isVisible = this.props.isVisible
    const sourceLayerId = PLAN_SOURCE + sitemap.id
    if (!sitemap?.geoCorners || !basemap?.source) {
      return null
    }
    return (
      <Source
        id={sourceLayerId}
        type="image"
        key={sourceLayerId}
        url={basemap.source}
        coordinates={sitemap.geoCorners?.map(c => [c.longitude, c.latitude])}
      >
        <Layer
          id={PLAN_LAYER_PREFIX + sitemap.id}
          source={sourceLayerId}
          type="raster"
          layout={{ visibility: isVisible ? 'visible' : 'none' }}
          maxzoom={MAX_ITEM_ZOOM_LEVEL}
          minzoom={PROJECT_MARKER_ZOOM_LEVEL}
          paint={{
            'raster-fade-duration': 0,
          }}
        />
      </Source>
    )
  }
}
