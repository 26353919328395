import React from 'react'

import { computed } from 'mobx'
import { observer } from 'mobx-react'
import { Layer, Source } from 'react-map-gl'

import Sitemap from '~/client/src/shared/models/Sitemap'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import MapBoxViewerStore, {
  ISitemapWithBasemap,
  MAX_ITEM_ZOOM_LEVEL,
  PROJECT_MARKER_ZOOM_LEVEL,
} from '../../../MapBoxViewer/MapBoxViewer.store'
import {
  UNDER_PLAN_FILL_LAYER,
  UNDER_PLAN_LINE_LAYER,
} from '../../../MapBoxViewer/mapboxConstants'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps {
  mapBoxViewerStore: MapBoxViewerStore
  sitemapWithBasemaps: ISitemapWithBasemap[]
  selectedSitemap?: Sitemap
  isRubberMode?: boolean
}

@observer
export default class UnderGlobePlanShape extends React.Component<IProps> {
  public render(): JSX.Element {
    return (
      <>
        {this.renderLineLayer()}
        {this.renderFillLayer()}
      </>
    )
  }

  private renderLineLayer(): JSX.Element {
    if (
      (!this.props.mapBoxViewerStore.hoveredSitemapId &&
        !this.props.selectedSitemap) ||
      this.props.isRubberMode
    ) {
      return null
    }

    return (
      <Source
        id="under_plan_line_data"
        type="geojson"
        data={this.lineShapesGeoJson}
      >
        <Layer
          id={UNDER_PLAN_LINE_LAYER}
          type="line"
          paint={{
            'line-color': ThemeMode.getHEXColor(Colors.primary60),
            'line-width': 2,
          }}
          maxzoom={MAX_ITEM_ZOOM_LEVEL}
          minzoom={PROJECT_MARKER_ZOOM_LEVEL}
        />
      </Source>
    )
  }

  private renderFillLayer(): JSX.Element {
    return (
      <Source
        id="under_plan_fill_data"
        type="geojson"
        data={this.fillShapesGeoJson}
      >
        <Layer
          id={UNDER_PLAN_FILL_LAYER}
          type="fill"
          paint={{
            'fill-opacity': 0,
          }}
          maxzoom={MAX_ITEM_ZOOM_LEVEL}
          minzoom={PROJECT_MARKER_ZOOM_LEVEL}
        />
      </Source>
    )
  }

  @computed
  private get fillShapesGeoJson():
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string {
    return {
      type: 'FeatureCollection',
      features: this.props.sitemapWithBasemaps?.map(s => ({
        type: 'Feature',
        properties: {
          sitemapId: s.sitemap.id,
        },
        geometry: {
          type: 'Polygon',
          coordinates: s.sitemap.geoCorners && [
            [
              ...s.sitemap.geoCorners.map(c => [c.longitude, c.latitude]),
              [
                s.sitemap.geoCorners[0].longitude,
                s.sitemap.geoCorners[0].latitude,
              ],
            ],
          ],
        },
      })),
    }
  }

  @computed
  private get lineShapesGeoJson():
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string {
    const { sitemapsMap, hoveredSitemapId } = this.props.mapBoxViewerStore
    const map =
      sitemapsMap[this.props.selectedSitemap?.id] ||
      sitemapsMap[hoveredSitemapId]
    if (!map) {
      return
    }

    const { sitemap } = map
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            sitemapId: sitemap.id,
          },
          geometry: {
            type: 'Polygon',
            coordinates: sitemap.geoCorners && [
              [
                ...sitemap.geoCorners.map(c => [c.longitude, c.latitude]),
                [
                  sitemap.geoCorners[0].longitude,
                  sitemap.geoCorners[0].latitude,
                ],
              ],
            ],
          },
        },
      ],
    }
  }
}
