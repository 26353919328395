import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { ViewState } from 'react-map-gl'
import ReactSlider from 'react-slider'

import MapViewSetUpStore from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/MapViewSetUp.store'
import LocationObjectsPallet from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/components/LocationObjectsPallet/LocationObjectsPallet'
import MapEditor from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/components/SitemapEditor/MapEditor'
import {
  ACTION_KEY,
  DTO_ID_KEY,
  NEW_SITEMAP_TYPE_KEY,
  SITEMAP_ID_KEY,
} from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/models/ISitemapDelayedAction'
import DeleteDeliveryAttributeConfirmDialog from '~/client/src/desktop/views/ProjectSetUp/components/ProjectWorkflowsSetUp/components/DeliveryRequestSetUp/components/DeliveryDetailsConfigurations/components/DeleteDeliveryAttributeConfirmDialog'
import OpacityBackground from '~/client/src/desktop/views/SimpleGanttView/components/OpacityBackground/OpacityBackground'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import GlobeView from '~/client/src/shared/models/GlobeView'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import { DEFAULT_NAME } from '~/client/src/shared/stores/GlobeViewControl.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import LocationIntegrationsStore from '~/client/src/shared/stores/domain/LocationIntegrations.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import SyncRestrictionsStore from '~/client/src/shared/stores/domain/SyncRestrictions.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import TilesetsStore from '~/client/src/shared/stores/domain/Tilesets.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import DesktopEventsStore from '../../stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '../../stores/ui/DesktopCommon.store'
import ProjectSetUpPageStore from '../../views/ProjectSetUp/ProjectSetUpPage.store'
import GlobeLeftPanel from '../../views/ProjectSetUp/components/AppsSitemap/components/GlobeLeftPanel/GlobeLeftPanel'
import DeleteSitemapConfirmDialog from '../../views/ProjectSetUp/components/AppsSitemap/components/dialogs/DeleteSitemapConfirmDialog'
import UpdateSitemapDialog from '../../views/ProjectSetUp/components/AppsSitemap/components/dialogs/UpdateSitemapDialog/UpdateSitemapDialog'
import UploadMapFileDialog from '../../views/ProjectSetUp/components/AppsSitemap/components/dialogs/UploadMapFileDialog/UploadMapFileDialog'
import GlobeLimitedRibbon from '../../views/ProjectSetUp/components/AppsSitemap/components/ribbons/GlobeLimitedRibbon'
import GlobeRibbon from '../../views/ProjectSetUp/components/AppsSitemap/components/ribbons/GlobeRibbon'
import SitemapRibbon from '../../views/ProjectSetUp/components/AppsSitemap/components/ribbons/SitemapRibbon'
import DeleteTilesetConfirmDialog from '../../views/ProjectSetUp/components/DeleteTilesetConfirmDialog/DeleteTilesetConfirmDialog'
import DeleteGlobeConfirmDialog from '../../views/ProjectSetUp/components/ProjectWorkflowsSetUp/components/DeliveryRequestSetUp/components/DeliveryDetailsConfigurations/components/DeleteGlobeConfirmDialog'
import SaveAttributeConfirmDialog from '../../views/ProjectSetUp/components/ProjectWorkflowsSetUp/components/DeliveryRequestSetUp/components/DeliveryDetailsConfigurations/components/SaveGlobeItemConfirmDialog'
import GlobeProperties from './GlobeProperties'

import './GeneralMapViewSetUp.scss'

interface IProps {
  selectedGlobeId?: string
  globes?: GlobeView[]
  projectSetUpPageStore?: ProjectSetUpPageStore
  eventsStore?: DesktopEventsStore
  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  locationAttributesStore?: LocationAttributesStore
  sitemapItemsStore?: SitemapItemsStore
  fileUploadingStore?: FileUploadingStore
  common?: DesktopCommonStore
  syncRestrictionsStore?: SyncRestrictionsStore
  activityFiltersStore?: ActivityFiltersStore
  userProjectsStore?: UserProjectsStore
  tagsStore?: TagsStore
  globeViewsStore?: GlobeViewsStore
  locationIntegrationsStore?: LocationIntegrationsStore
  tilesetsStore?: TilesetsStore

  renderChildren?: (width: number, height: number) => JSX.Element
}

const transparency = 'transparency'
const sitemapsDataIsLoading = 'Sitemaps Data is loading...'

const DELAYED_ACTION_QUERY_PARAMS = [
  ACTION_KEY,
  NEW_SITEMAP_TYPE_KEY,
  DTO_ID_KEY,
  SITEMAP_ID_KEY,
]

@inject(
  'eventsStore',
  'sitemapsStore',
  'basemapsStore',
  'locationAttributesStore',
  'sitemapItemsStore',
  'fileUploadingStore',
  'common',
  'syncRestrictionsStore',
  'activityFiltersStore',
  'userProjectsStore',
  'tagsStore',
  'globeViewsStore',
  'tilesetsStore',
)
@observer
export default class GeneralMapViewSetUp extends React.Component<IProps> {
  @observable private isSavingGlobe: boolean = false
  private store: MapViewSetUpStore
  public constructor(props: IProps) {
    super(props)

    this.store = new MapViewSetUpStore(
      props.eventsStore,
      props.sitemapsStore,
      props.basemapsStore,
      props.locationAttributesStore,
      props.sitemapItemsStore,
      props.fileUploadingStore,
      props.syncRestrictionsStore,
      props.activityFiltersStore,
      props.userProjectsStore,
      props.tagsStore,
      props.globeViewsStore,
      props.tilesetsStore,
    )

    const { lastEditedGlobe } = this.store.globeViewSetupStore
    this.store.mapBoxEditorStore.isRubberMode = false
    const globe = props.globeViewsStore.byId.get(props.selectedGlobeId)
    const globes = props.globeViewsStore.list
    this.store.globeViewSetupStore.selectInitialGlobe(
      globe || lastEditedGlobe || globes?.[0],
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.selectedGlobeId !== prevProps.selectedGlobeId) {
      this.store.globeViewSetupStore.selectGlobeById(this.props.selectedGlobeId)
      this.store.mapBoxViewerStore.setViewportFromAddress()
    }
  }

  public componentDidMount(): void {
    this.store.sitemapsSetupStore.setViewportFromAddress()
  }

  public UNSAFE_componentWillMount(): void {
    const { search, pathname } = this.props.common.history.location
    const queryParams = new URLSearchParams(search)

    const { sitemapsSetupStore } = this.store
    sitemapsSetupStore.setDelayedActionParamsFromUrl(queryParams)

    DELAYED_ACTION_QUERY_PARAMS.forEach(param => {
      queryParams.delete(param)
    })
    const path = pathname + '?' + queryParams.toString()
    // @ts-ignore needed here
    this.props.common.history.replace(path)
    this.store.mapBoxEditorStore.isRubberMode = false
  }

  public render(): JSX.Element {
    const { projectSetUpPageStore, globes, eventsStore } = this.props
    const {
      isInitialLoaderShown,
      mapViewItemsSetupStore,
      sitemapsSetupStore: { selectedSitemap },
      isLoading,
      globeViewSetupStore: {
        updateGlobeView,
        selectedGlobeView,
        deletableGlobe,
        deleteGlobe,
        deselectDeletableGlobe,
      },
      mapBoxViewerStore,
      mapBoxEditorStore,
      isGlobeMode,
      currentStep,
    } = this.store

    const isGlobeLoading = eventsStore.appState.loading.get(e.SAVE_GLOBE_VIEW)
    if (isInitialLoaderShown) {
      return <Loader hint={sitemapsDataIsLoading} />
    }

    if (isLoading) {
      return <Loader hint={Localization.translator.loading} />
    }

    const {
      deletableMapViewItem,
      deletableSitemapItemCaption,
      mapViewItemForSave,
      mapViewItemForSaveCaption,
      applyMapViewItemDeleteConfirmDialog,
      hideMapViewItemDeleteConfirmDialog,
      applyMapViewItemSaveConfirmDialog,
      hideMapViewItemSaveConfirmDialog,
      selectedMapViewItem,
      isSavingAfterChanges,
    } = mapViewItemsSetupStore

    return (
      <div className="general-map-setup globe-view global-map-setup full-height">
        {this.store.isGlobeListShown && <GlobeRibbon store={this.store} />}
        {
          <SitemapRibbon
            store={this.store}
            allowUploadOnlyWhiteboard={this.store.isGlobeListShown}
            shouldHideButton={true}
          />
        }
        {this.store.sitemapControlStore.shouldShowCreateMapFileMenu && (
          <>
            <OpacityBackground isAnyPopupOpened={true} />
            <UploadMapFileDialog
              mapViewItemsSetupStore={this.store.mapViewItemsSetupStore}
              store={this.store.sitemapControlStore}
            />
          </>
        )}
        {this.store.sitemapControlStore.shouldShowEditBasemapMenu && (
          <>
            <OpacityBackground isAnyPopupOpened={true} />
            <UpdateSitemapDialog store={this.store} />
          </>
        )}
        {this.store.sitemapsSetupStore.deletableSitemap && (
          <>
            <OpacityBackground isAnyPopupOpened={true} />
            <DeleteSitemapConfirmDialog store={this.store} />
          </>
        )}
        {this.store.tilesetsSetupStore.deletableTileset && (
          <>
            <OpacityBackground isAnyPopupOpened={true} />
            <DeleteTilesetConfirmDialog store={this.store} />
          </>
        )}
        {deletableMapViewItem && (
          <DeleteDeliveryAttributeConfirmDialog
            isShown={!!deletableMapViewItem}
            isSubTextHidden={
              deletableMapViewItem && !deletableMapViewItem.dataObject
            }
            type={deletableSitemapItemCaption}
            name={deletableMapViewItem.displayName}
            onApply={applyMapViewItemDeleteConfirmDialog}
            onHide={hideMapViewItemDeleteConfirmDialog}
          />
        )}
        {deletableGlobe && (
          <DeleteGlobeConfirmDialog
            isShown={!!deletableGlobe}
            name={deletableGlobe.name}
            onApply={deleteGlobe}
            onHide={deselectDeletableGlobe}
          />
        )}
        {mapViewItemForSave && (
          <SaveAttributeConfirmDialog
            isShown={!!mapViewItemForSave}
            type={mapViewItemForSaveCaption}
            name={mapViewItemForSave.displayName}
            onApply={applyMapViewItemSaveConfirmDialog}
            onHide={hideMapViewItemSaveConfirmDialog}
            isSaving={isSavingAfterChanges}
          />
        )}
        <div className={classList({ 'main-area row full-height': true })}>
          <div className="col full-height">
            <div className="row sitemap-edit-content full-height">
              <GlobeLeftPanel
                store={this.store}
                projectSetUpPageStore={projectSetUpPageStore}
                step={currentStep}
                globes={globes}
                isDisabled={isGlobeLoading}
                selectedMapViewItem={selectedMapViewItem}
                selectedSitemap={selectedSitemap}
              />
              {this.renderContent()}
              {(selectedGlobeView || selectedSitemap) && (
                <GlobeProperties
                  globe={selectedGlobeView}
                  store={this.store}
                  viewport={mapBoxViewerStore.viewport}
                  updateGlobeView={updateGlobeView}
                  mapBoxViewerStore={mapBoxViewerStore}
                  mapBoxEditorStore={mapBoxEditorStore}
                  isItemSelected={!!selectedMapViewItem}
                  selectedSitemap={selectedSitemap}
                  saveAlignment={this.saveAlignment}
                  exitRubber={this.exitRubberMode}
                  isDisabled={isGlobeLoading}
                  isGlobeMode={isGlobeMode}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  @action.bound
  private saveAlignment(): void {
    const {
      mapBoxViewerStore: {
        width,
        height,
        globeCorners,
        setViewportFromAddress,
      },
      sitemapsSetupStore: {
        selectedSitemap,
        updateGeoposition,
        deselectSitemap,
      },
      setStep,
      globeViewSetupStore,
    } = this.store

    updateGeoposition(
      selectedSitemap,
      this.viewport,
      width,
      height,
      globeCorners,
    )

    setStep(null)
    deselectSitemap()

    if (
      !globeViewSetupStore.selectedGlobeView.sitemapsMap[selectedSitemap.id]
    ) {
      globeViewSetupStore.setSitemapToGlobeView(selectedSitemap.id)
    }

    globeViewSetupStore.selectGlobe(globeViewSetupStore.selectedGlobeView)
    setViewportFromAddress()
  }

  @action.bound
  private exitRubberMode(): void {
    const {
      globeViewSetupStore,
      sitemapsSetupStore: { deselectSitemap },
      mapBoxViewerStore,
      mapBoxEditorStore,
      setStep,
    } = this.store

    mapBoxEditorStore.isRubberMode = false

    setStep(null)
    deselectSitemap()
    globeViewSetupStore.selectGlobe(globeViewSetupStore.selectedGlobeView)
    mapBoxViewerStore.setViewportFromAddress()
  }

  private renderContent(): JSX.Element {
    const { eventsStore, renderChildren } = this.props
    const {
      mapBoxEditorStore: { setOpacity, opacity, isRubberMode },
      mapBoxViewerStore: { isResetDisabled },
      globeViewSetupStore,
      sitemapsSetupStore,
      toggleGlobeList,
      isGlobeMode,
    } = this.store
    const isLoading = eventsStore.appState.loading.get(e.SAVE_GLOBE_VIEW)
    const globe = globeViewSetupStore?.selectedGlobeView

    return (
      <div className="main-area-container full-height relative global-map">
        <div className="top-view-row full-width">
          <div
            className="view-select-modal bg-white py5 px10 row x-between brada4 pointer"
            onClick={toggleGlobeList}
          >
            <div className="text large medium-bold w-fit-content text-ellipsis">
              {globeViewSetupStore.selectedGlobeView?.name ||
                sitemapsSetupStore.selectedSitemap?.name ||
                DEFAULT_NAME}
            </div>
            <Icon icon={IconNames.CHEVRON_DOWN} className="ml10 no-grow" />
          </div>
          {isGlobeMode && (
            <GlobeLimitedRibbon
              createNewGlobe={this.createNewView}
              saveSelectedGlobe={this.updateGlobeView}
              isSavingGlobe={this.isSavingGlobe}
              resetViewport={this.resetViewport}
              isResetDisabled={isResetDisabled}
            />
          )}
        </div>
        {!isLoading && !isRubberMode && (
          <LocationObjectsPallet store={this.store} />
        )}
        <div className="sitemap-editor-container row full-height">
          {isLoading && !globe ? (
            <Loader />
          ) : (
            <MapEditor
              store={this.store}
              setViewport={this.setViewport}
              opacity={this.store.mapBoxEditorStore.opacity}
              renderChildren={renderChildren}
              isRubberMode={this.store.mapBoxEditorStore.isRubberMode}
              processingTilesets={this.props.tilesetsStore?.processingTilesets?.slice()}
            />
          )}
        </div>
        {isRubberMode && (
          <div className="absolute brada10 bg-white pa5 opacity-slider row">
            <div>
              <div className="text uppercase transparency-text lp15">
                {transparency}
              </div>
              <div className="row">
                <Icon
                  icon={IconNames.MAP}
                  className="no-grow mr10"
                  onClick={setOpacity.bind(null, 0)}
                />
                <div className="mr20 slider-holder">
                  <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    value={opacity}
                    renderThumb={this.renderOpacitySlider}
                    onChange={setOpacity}
                  />
                </div>
                <Icons.Globe
                  className="no-grow mr10"
                  onClick={setOpacity.bind(null, 100)}
                />
                <input
                  type="number"
                  value={opacity}
                  onChange={this.updateOpacity}
                  max={100}
                  min={1}
                />
                %
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  private renderOpacitySlider = (props, state): JSX.Element => {
    return <div {...props}>{state.valueNow}</div>
  }

  private updateOpacity = event => {
    this.store.setOpacity(event.target.value)
  }

  private get viewport(): ViewState {
    return this.store.mapBoxViewerStore.viewport
  }

  private setViewport = async (viewport: ViewState): Promise<void> => {
    if (
      !this.store.globeViewSetupStore.selectedGlobeView ||
      this.props.eventsStore.appState.loading.get(e.SAVE_GLOBE_VIEW)
    ) {
      return
    }
    this.store.mapBoxViewerStore.viewport = viewport
  }

  @action.bound
  private async updateGlobeView(): Promise<void> {
    const { viewport, globeCorners, globeViewStyle } =
      this.store.mapBoxViewerStore
    this.isSavingGlobe = true
    const mapboxImage = await this.getMapboxImage()
    await this.store.globeViewSetupStore.updateGlobeView(
      viewport,
      globeCorners,
      null,
      null,
      globeViewStyle,
      mapboxImage,
    )
    this.isSavingGlobe = false
  }

  private async getMapboxImage(): Promise<string> {
    const mapboxBase64 = await this.store.mapBoxEditorStore.createMapImage()
    const mapboxImageFile = this.store.base64ImageToFile(
      mapboxBase64,
      this.store.globeViewSetupStore.selectedGlobeView.name,
    )
    return await this.store.sitemapsSetupStore.getImageUrl(mapboxImageFile)
  }

  @action.bound
  private async createNewView(): Promise<void> {
    this.isSavingGlobe = true
    const mapboxImage = await this.getMapboxImage()
    const {
      mapBoxViewerStore: {
        globeCorners,
        viewport,
        selectedGlobe,
        globeViewStyle,
        setViewportFromAddress,
      },
      globeViewSetupStore,
    } = this.store
    const {
      bounds,
      bearing,
      zoom,
      center: { lat, lng },
    } = this.props.eventsStore.appState.projectAddress
    const newViewport = selectedGlobe
      ? viewport
      : ({
          longitude: lng,
          latitude: lat,
          zoom,
          bearing,
          pitch: 0,
        } as ViewState)

    await globeViewSetupStore.createNewPlaceholder(
      selectedGlobe && globeCorners,
      newViewport,
      bounds,
      selectedGlobe?.items,
      selectedGlobe.mapFiles,
      globeViewStyle,
      mapboxImage,
    )

    setViewportFromAddress()
    this.isSavingGlobe = false
  }

  @action.bound
  private resetViewport(): void {
    this.store.mapBoxViewerStore.setViewportFromAddress()
  }
}
