import React from 'react'

import { inject, observer } from 'mobx-react'

import { ITileset, MapFileType } from '~/client/graph'
import Sitemap from '~/client/src/shared/models/Sitemap'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { SAVE_GLOBE_VIEW } from '~/client/src/shared/stores/EventStore/eventConstants'

import MapBoxViewerStore, {
  ISitemapWithBasemap,
} from '../../../MapBoxViewer/MapBoxViewer.store'
import GlobeViewPlan from './GlobeViewPlan'
import GlobeViewTileset from './GlobeViewTileset'
import UnderGlobePlanShape from './UnderGlobePlanShape'

export type MapFileOnGlobe = {
  type: MapFileType
  file: ISitemapWithBasemap | ITileset
}
interface IProps {
  mapBoxViewerStore: MapBoxViewerStore
  sitemapWithBasemaps: ISitemapWithBasemap[]
  mapFilesOnGlobe: MapFileOnGlobe[]
  selectedSitemap?: Sitemap
  isEditor?: boolean
  isRubberMode?: boolean
  eventsStore?: EventsStore
  className?: string
}

@inject('eventsStore')
@observer
export default class GlobeViewFiles extends React.Component<IProps> {
  public render(): JSX.Element {
    const {
      mapBoxViewerStore,
      sitemapWithBasemaps,
      isRubberMode,
      selectedSitemap,
      mapFilesOnGlobe,
      eventsStore,
    } = this.props
    const isGlobeLoading = eventsStore.appState.loading.get(SAVE_GLOBE_VIEW)
    if (isGlobeLoading) {
      return null
    }
    return (
      <>
        {this.props?.isEditor && (
          <UnderGlobePlanShape
            mapBoxViewerStore={mapBoxViewerStore}
            sitemapWithBasemaps={sitemapWithBasemaps}
            isRubberMode={isRubberMode}
            selectedSitemap={selectedSitemap}
          />
        )}
        {mapFilesOnGlobe.map((f, idx) => {
          switch (f.type) {
            case MapFileType.Sitemap:
              return this.renderGlobePlan(f.file as ISitemapWithBasemap, idx)
            case MapFileType.Tileset:
              return this.renderGlobeTileset(f.file as ITileset, idx)
            default:
              return null
          }
        })}
      </>
    )
  }

  private renderGlobePlan(
    sitemapWithBasemap: ISitemapWithBasemap,
    idx: number,
  ): JSX.Element {
    const { sitemap, basemap } = sitemapWithBasemap
    const isVisible =
      !this.props.isRubberMode ||
      !!this.props.sitemapWithBasemaps.find(s => s.sitemap.id === sitemap.id)
    if (!sitemap?.geoCorners || !basemap?.source) {
      return null
    }
    return (
      <div key={'globe-plan-' + idx}>
        <GlobeViewPlan
          idx={idx}
          sitemapWithBasemap={sitemapWithBasemap}
          isVisible={isVisible}
        />
      </div>
    )
  }

  private renderGlobeTileset(tile: ITileset, idx: number) {
    const {
      tilesetsControlStore: { selectedTilesetFeature },
    } = this.props.mapBoxViewerStore
    return (
      <div key={'globe-tileset-' + idx}>
        <GlobeViewTileset
          idx={idx}
          tile={tile}
          selectedTilesetFeature={selectedTilesetFeature}
        />
      </div>
    )
  }
}
