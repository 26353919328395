import * as React from 'react'

import * as turf from '@turf/turf'
import mapboxgl, { MapMouseEvent } from 'mapbox-gl'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import Map, {
  FullscreenControl,
  GeolocateControl,
  Layer,
  Marker,
  NavigationControl,
  Popup,
  Source,
} from 'react-map-gl'

import {
  IGeoJson2DGeographicCoordinates,
  IPosition,
  IProjectAddressInput,
  LocationType,
} from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'

import { getProjectUrl } from '../../constants/commonRoutes'
import Language from '../../localization/Language'
import IGeoPosition from '../../models/IGeoPosition'
import InitialState from '../../stores/InitialState'
import ProjectsStore from '../../stores/domain/Projects.store'
import MapLayerSelector from '../MapLayersSelector/components/MapLayerSelector'
import MapBoxViewerStore, {
  ISitemapWithBasemap,
  MAX_PITCH_LEVEL,
  MAX_ZOOM_LEVEL,
  Projection,
  getMapBoxPlaceFromBareCoordinates,
  mapboxFeatureToAddress,
} from './MapBoxViewer.store'
import {
  ITEM_EDITOR_SHAPE_FILL_LAYER,
  POLYLINE_ADDITIONAL_EDITABLE_POINTS_LAYER,
  SHAPE_ARROW_LINE_LAYER,
  SHAPE_EDITABLE_POINTS_LAYER,
  SHAPE_FILL_LAYER,
  SHAPE_LINE_LAYER,
  SHAPE_SELECTED_FILL_LAYER,
  SHAPE_SELECTED_LINE_LAYER,
  TILESET_CIRCLE_LAYER_PREFIX,
  TILESET_FILL_LAYER_PREFIX,
  TILESET_LINE_LAYER_PREFIX,
  TILESET_SYMBOL_LAYER_PREFIX,
  UNDER_PLAN_FILL_LAYER,
} from './mapboxConstants'

import './MapBoxViewer.scss'

export interface IProjectMarkerInfo {
  coordinates: IGeoJson2DGeographicCoordinates
  projectId: string
  projectName: string
  projectCode: string
}
interface IMapBoxEditorProps {
  viewport: IGeoPosition

  latitude: number
  longitude: number
  store: MapBoxViewerStore
  language: Language

  onMouseDown?: (evt: MapMouseEvent) => void
  onMouseUp?: (evt: MapMouseEvent) => void
  onMouseMove?: (evt: MapMouseEvent) => void
  onMapClick?: (evt: MapMouseEvent) => void
  onMapDblClick?: (evt: MapMouseEvent) => void
  onKeyDown?: (evt: KeyboardEvent) => void
  onKeyUp?: (evt: KeyboardEvent) => void

  creatableAttributeType?: LocationType
  isTextStickerCreationActive?: boolean

  setViewport: (viewport: IGeoPosition) => void
  createAttributeInPosition?: (position: IPosition) => void
  width?: number
  offsetY?: number
  offsetX?: number
  height?: number

  isRubberMode?: boolean

  containerWidth?: number
  containerHeight?: number
  globeLayer?: (containerWidth: number, containerHeight: number) => JSX.Element
  updateLngLat?: (latitude: number, longitude: number) => void
  onAddressChanged?: (address: IProjectAddressInput) => void
  toggleAnnouncementsHiddenState?: () => void
  toggleDeliveriesHiddenState?: () => void
  togglePermitsHiddenState?: () => void
  toggleMonitoringsHiddenState?: () => void
  isLogisticsView?: boolean
  arePermitsHidden?: boolean
  areAnnouncementsHidden?: boolean
  areDeliveriesHidden?: boolean
  areMonitoringsHidden?: boolean
  isFixed?: boolean
  isDeliveryView?: boolean
  shouldShowProjectMarker?: boolean
  shouldHideControls?: boolean
  isCompactMode?: boolean

  areAdditionalMarkersAvailable?: boolean
  state?: InitialState
  projectsStore?: ProjectsStore
  isGlobeMode?: boolean
  sourceGeoCornersPositionsMap?: {
    [attrId: string]: number[][]
  }
  sitemapWithBasemapsOnGlobe?: ISitemapWithBasemap[]
  globeId?: string
}

const {
  Building,
  Zone,
  Gate,
  Route,
  OffloadingEquipment,
  Level,
  Area,
  Staging,
  InteriorDoor,
  InteriorPath,
  LogisticsObject,
  VerticalObject,
} = LocationType

export const UNITS_KILOMETERS: turf.Units = 'kilometers'
const COMMON_MARKER_OFFSET = -10
const PROJECT_MARKER_OFFSET_Y = -20
const MAX_SIZE = '100%'
const COMPACT_MODE_OFFSET = 68
const ADDITIONAL_CONTROLS_OFFSET = COMPACT_MODE_OFFSET + 112

const GEOLOCATION_STYLE = {
  bottom: 197,
  right: 0,
  zIndex: 100,
  cursor: 'pointer',
}
const FULLSCREEN_STYLE = {
  bottom: 234,
  right: 0,
  zIndex: 100,
  cursor: 'pointer',
}

const SCALE_CONTROL_STYLE = {
  bottom: 90,
  zIndex: 100,
  right: 0,
  cursor: 'pointer',
}

const PROJECTION_CONTROL_STYLE = {
  bottom: 10,
  right: 10,
  zIndex: 100,
  cursor: 'pointer',
}

const RESET_CONTROL_STYLE = {
  bottom: 47,
  right: 10,
  zIndex: 100,
  cursor: 'pointer',
}

const CURSOR_BY_OBJECT_TYPE = {
  [Building]: 'url(/static/cursors/building.png) 12 16, copy',
  [Zone]: 'url(/static/cursors/offloading-zone.svg) 12 12, copy',
  [OffloadingEquipment]: 'url(/static/cursors/equipment.png) 6 6, copy',
  [Gate]: 'url(/static/cursors/gate.png) 16 18, copy',
  [Route]: 'url(/static/cursors/route.png) 16 16, copy',
  [Level]: 'url(/static/cursors/level.png) 12 12, copy',
  [Area]: 'url(/static/cursors/area.png) 12 12, copy',
  [Staging]: 'url(/static/cursors/staging.svg) 12 12, copy',
  [InteriorDoor]: 'url(/static/cursors/interior-door.svg) 12 12, copy',
  [InteriorPath]: 'url(/static/cursors/interior-path.svg) 12 12, copy',
  [LogisticsObject]: 'url(/static/cursors/zone.png) 12 12, copy',
  [VerticalObject]: 'url(/static/cursors/zone.png) 12 12, copy',
}

const T_LETTER = 'url(/static/icons/t-letter-icon.svg) 12 12, copy'

@inject('state', 'projectsStore')
@observer
export class MapBoxViewer extends React.Component<IMapBoxEditorProps> {
  @observable private selectedProjectMarkerInfo: IProjectMarkerInfo = null

  public componentDidUpdate(prevProps: Readonly<IMapBoxEditorProps>): void {
    if (
      (this.props.containerHeight !== prevProps.containerHeight &&
        !!prevProps.containerHeight) ||
      (this.props.containerWidth !== prevProps.containerWidth &&
        !!prevProps.containerWidth)
    ) {
      this.props.store.onResize()
    }
    const shouldUpdateOrder: boolean =
      this.props.sitemapWithBasemapsOnGlobe !==
        prevProps.sitemapWithBasemapsOnGlobe ||
      this.props.sitemapWithBasemapsOnGlobe?.length !==
        prevProps.sitemapWithBasemapsOnGlobe?.length ||
      (this.props.globeId &&
        this.props.globeId !== prevProps.globeId &&
        !!this.props.store.mapRef)

    if (shouldUpdateOrder && this.props.store.isStyleLoaded) {
      this.props.store.mapRef?.moveLayer(SHAPE_FILL_LAYER)
      this.props.store.mapRef?.moveLayer(SHAPE_LINE_LAYER)
      this.props.store.mapRef?.moveLayer(SHAPE_ARROW_LINE_LAYER)
    }
    if (this.props.language !== prevProps.language) {
      this.props.store.updateLanguage()
    }
    if (this.props.globeId && this.props.globeId !== prevProps.globeId) {
      this.props.store.setInitStyle()
    }
  }

  public componentDidMount(): void {
    document.addEventListener('keydown', this.onKeyDown)
    document.addEventListener('keyup', this.onKeyUp)
    this.props.store.setInitStyle()
  }

  public componentWillUnmount(): void {
    this.props.store.mapRef = null
    this.props.store.isStyleLoaded = false
    document.removeEventListener('keydown', this.onKeyDown)
    document.removeEventListener('keyup', this.onKeyUp)
  }

  public render(): JSX.Element {
    const {
      setViewport,
      viewport,
      store,
      store: {
        isStyleLoaded,
        manuallyTriggeredProjectMarker,
        globeViewStyle: { isTrafficEnabled },
      },
      shouldHideControls,
      shouldShowProjectMarker,
      width,
      height,
      containerWidth,
      containerHeight,
      isTextStickerCreationActive,
      areAdditionalMarkersAvailable,
      isGlobeMode,
      isRubberMode,
    } = this.props

    const shouldShowAdditionalProjectMarkers =
      areAdditionalMarkersAvailable &&
      store.shouldShowProjectMarker &&
      store.globeViewStyle.shouldShowAppProjectMarkers

    const shouldRenderNavigationLayers: boolean =
      !shouldHideControls || isGlobeMode
    const shouldRenderProjectMarker: boolean =
      manuallyTriggeredProjectMarker ||
      shouldShowProjectMarker ||
      store.shouldShowProjectMarker

    return (
      <div
        id="map"
        className={classList({
          'absolute-top mapbox-editor map-box-editor-map full-width full-height':
            true,
          'hidden-controls': shouldHideControls,
          'full-width full-height': !width && !height,
          'hidden-cursor': isTextStickerCreationActive,
        })}
        style={{
          width: containerWidth ? containerWidth : MAX_SIZE,
          height: containerHeight ? containerHeight : MAX_SIZE,
          zIndex: 1,
        }}
      >
        <Map
          projection={{ name: store.projection }}
          onMouseDown={this.onMouseDown}
          onMouseUp={this.onMouseUp}
          onMouseMove={this.onMouseMove}
          onDblClick={this.onMapDblClick}
          onClick={this.onMapClick}
          ref={ref => store?.setRefForProjectMap(ref)}
          zoom={viewport?.zoom}
          onMove={e => setViewport(e.viewState)}
          pitch={isRubberMode ? 0 : viewport?.pitch}
          bearing={viewport?.bearing}
          latitude={viewport?.latitude}
          longitude={viewport?.longitude}
          doubleClickZoom={false}
          mapStyle={store.mapStyle}
          preserveDrawingBuffer={true}
          accessToken={mapboxgl.accessToken}
          scrollZoom={true}
          maxZoom={MAX_ZOOM_LEVEL}
          maxPitch={MAX_PITCH_LEVEL}
          cursor={this.cursor}
          style={{
            width: width ? containerWidth : MAX_SIZE,
            height: height ? containerHeight : MAX_SIZE,
          }}
          interactiveLayerIds={this.interactiveLayers}
        >
          {isStyleLoaded && (
            <>
              {isTrafficEnabled && this.renderTraffic()}
              {/* all site maps and items*/}
              {isGlobeMode && this.globe}
              {/* project marker [at some zoom point OR  project creation] */}
              {shouldRenderProjectMarker && this.projectAddressMarker}
              {/* additional project markers [at some zoom point OR  project creation] */}
              {shouldShowAdditionalProjectMarkers &&
                this.additionalProjectAddressMarkers}
              {store.shouldShowProjectMarker && this.renderProjectPopup()}
              {/* navigation helpers */}
              {shouldRenderNavigationLayers && this.renderNavigationHelpers()}
            </>
          )}
        </Map>
      </div>
    )
  }

  private get interactiveLayers(): string[] {
    return [
      SHAPE_FILL_LAYER,
      SHAPE_LINE_LAYER,
      SHAPE_SELECTED_FILL_LAYER,
      SHAPE_SELECTED_LINE_LAYER,
      ITEM_EDITOR_SHAPE_FILL_LAYER,
      SHAPE_EDITABLE_POINTS_LAYER,
      POLYLINE_ADDITIONAL_EDITABLE_POINTS_LAYER,
      SHAPE_ARROW_LINE_LAYER,
      UNDER_PLAN_FILL_LAYER,
      ...this.props.store.tilesetsControlStore.globeTilesets.flatMap(t => [
        TILESET_FILL_LAYER_PREFIX + t.id,
        TILESET_LINE_LAYER_PREFIX + t.id,
        TILESET_CIRCLE_LAYER_PREFIX + t.id,
        TILESET_SYMBOL_LAYER_PREFIX + t.id,
      ]),
    ]
  }

  private renderTraffic(): JSX.Element {
    return (
      <Source
        id="trafficSource"
        type="vector"
        url="mapbox://mapbox.mapbox-traffic-v1"
      >
        <Layer
          id="traffic-layer"
          source-layer="traffic"
          source="trafficSource"
          type="line"
          paint={{
            'line-width': 6,
            'line-color': [
              'case',
              ['==', 'low', ['get', 'congestion']],
              '#28d760',
              ['==', 'moderate', ['get', 'congestion']],
              '#ffff00',
              ['==', 'heavy', ['get', 'congestion']],
              '#ffcb32',
              ['==', 'severe', ['get', 'congestion']],
              '#ff0003',
              '#000000',
            ],
          }}
        />
      </Source>
    )
  }

  private renderNavigationHelpers(): JSX.Element {
    const { isFixed, isCompactMode } = this.props
    return (
      <>
        <FullscreenControl
          style={{
            ...FULLSCREEN_STYLE,
            bottom: isCompactMode
              ? FULLSCREEN_STYLE.bottom + COMPACT_MODE_OFFSET
              : FULLSCREEN_STYLE.bottom,
            position: isFixed ? 'fixed' : 'absolute',
          }}
        />
        <GeolocateControl
          style={{
            ...GEOLOCATION_STYLE,
            bottom: isCompactMode
              ? GEOLOCATION_STYLE.bottom + COMPACT_MODE_OFFSET
              : GEOLOCATION_STYLE.bottom,
            position: isFixed ? 'fixed' : 'absolute',
          }}
          trackUserLocation={true}
        />
        {!isCompactMode && (
          <>
            <NavigationControl
              style={{
                ...SCALE_CONTROL_STYLE,
                bottom: isCompactMode
                  ? SCALE_CONTROL_STYLE.bottom + COMPACT_MODE_OFFSET
                  : SCALE_CONTROL_STYLE.bottom,
                position: isFixed ? 'fixed' : 'absolute',
              }}
            />
            {this.renderMapLayers()}
          </>
        )}
        {this.renderAdditionalControlToggles()}
      </>
    )
  }

  private renderAdditionalControlToggles(): JSX.Element {
    return (
      <>
        {this.renderResetControl()}
        {!this.props.isCompactMode && this.renderProjectionControl()}
      </>
    )
  }

  private renderResetControl(): JSX.Element {
    const {
      isFixed,
      isCompactMode,
      store: { setViewportFromAddress },
    } = this.props

    return (
      <div
        className={classList({
          'mapboxgl-ctrl mapboxgl-ctrl-group brada4 bg-white': true,
          fixed: isFixed,
          absolute: !isFixed,
        })}
        style={{
          ...RESET_CONTROL_STYLE,
          bottom: isCompactMode
            ? RESET_CONTROL_STYLE.bottom + ADDITIONAL_CONTROLS_OFFSET
            : RESET_CONTROL_STYLE.bottom,
        }}
      >
        <button
          onClick={() => setViewportFromAddress()}
          title="Reset Viewport"
          className="mapboxgl-ctrl-icon"
        >
          <Icons.ResetViewport />
        </button>
      </div>
    )
  }

  private renderProjectionControl(): JSX.Element {
    const {
      isFixed,
      isCompactMode,
      store: { projection, toggleProjection },
    } = this.props

    return (
      <div
        className={classList({
          'mapboxgl-ctrl mapboxgl-ctrl-group brada4 bg-white': true,
          fixed: isFixed,
          absolute: !isFixed,
        })}
        style={{
          ...PROJECTION_CONTROL_STYLE,
          bottom: isCompactMode
            ? PROJECTION_CONTROL_STYLE.bottom + ADDITIONAL_CONTROLS_OFFSET
            : PROJECTION_CONTROL_STYLE.bottom,
        }}
      >
        <button
          onClick={toggleProjection}
          title="Projection"
          className="mapboxgl-ctrl-icon"
        >
          {projection === Projection.globe ? (
            <Icons.Globe />
          ) : (
            <Icons.Sitemap />
          )}
        </button>
      </div>
    )
  }

  private renderMapLayers(): JSX.Element {
    const {
      store,
      isFixed,
      isLogisticsView,
      toggleDeliveriesHiddenState,
      togglePermitsHiddenState,
      toggleMonitoringsHiddenState,
      arePermitsHidden,
      areDeliveriesHidden,
      areMonitoringsHidden,
      isDeliveryView,
      areAdditionalMarkersAvailable,
    } = this.props

    return (
      <div
        className={classList({
          fixed: isFixed,
          absolute: !isFixed,
          'map-control-container': true,
        })}
      >
        <MapLayerSelector
          isFixed={isFixed}
          store={store}
          isLogisticsView={isLogisticsView}
          toggleDeliveriesHiddenState={toggleDeliveriesHiddenState}
          togglePermitsHiddenState={togglePermitsHiddenState}
          toggleMonitoringsHiddenState={toggleMonitoringsHiddenState}
          areDeliveriesHidden={areDeliveriesHidden}
          arePermitsHidden={arePermitsHidden}
          areMonitoringsHidden={areMonitoringsHidden}
          isDeliveryView={isDeliveryView || isLogisticsView}
          areAdditionalMarkersAvailable={areAdditionalMarkersAvailable}
        />
      </div>
    )
  }

  private get cursor(): string {
    const {
      isTextStickerCreationActive,
      creatableAttributeType,
      store: { mapCursor },
    } = this.props
    if (creatableAttributeType) {
      return CURSOR_BY_OBJECT_TYPE[creatableAttributeType]
    }
    if (isTextStickerCreationActive) {
      return T_LETTER
    }
    if (mapCursor) {
      return mapCursor
    }
    return 'grab'
  }

  @action.bound
  private onMapClick(e: MapMouseEvent): void {
    if (
      this.props.creatableAttributeType ||
      this.props.isTextStickerCreationActive
    ) {
      this.props.createAttributeInPosition(
        this.props.store.mapRef.getMap().project(e.lngLat),
      )
    } else {
      this.props.onMapClick?.(e)
    }
  }

  @action.bound
  private onMapDblClick(e: MapMouseEvent): void {
    this.props.onMapDblClick?.(e)
    if (this.props.shouldShowProjectMarker) {
      const { lat, lng } = e.lngLat
      this.changeAddress(lat, lng)
    }
  }

  @action.bound
  private onMouseDown(evt: MapMouseEvent): void {
    this.props.onMouseDown?.(evt)
  }

  @action.bound
  private onMouseUp(evt: MapMouseEvent): void {
    this.props.onMouseUp?.(evt)
  }

  @action.bound
  private onMouseMove(evt: MapMouseEvent): void {
    this.props.onMouseMove?.(evt)
  }

  @action.bound
  private onKeyDown(e: KeyboardEvent): void {
    this.props.onKeyDown?.(e)
  }

  @action.bound
  private onKeyUp(e: KeyboardEvent): void {
    this.props.onKeyUp?.(e)
  }

  private renderProjectPopup(): JSX.Element {
    if (!this.selectedProjectMarkerInfo) {
      return null
    }

    const { coordinates, projectName, projectCode } =
      this.selectedProjectMarkerInfo
    const url = getProjectUrl(projectCode)

    return (
      <Popup
        anchor="top"
        longitude={coordinates.longitude}
        latitude={coordinates.latitude}
        closeOnClick={false}
        className="mapbox-popup"
        onClose={this.setPopupInfo.bind(this, null)}
        onOpen={this.openInNewTab.bind(this, url)}
      >
        {projectName}
      </Popup>
    )
  }

  @action.bound
  private setPopupInfo(info: IProjectMarkerInfo): void {
    this.selectedProjectMarkerInfo = info
  }

  @action.bound
  private openInNewTab(url: string): void {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  @computed
  private get projectsMarkers(): IProjectMarkerInfo[] {
    const { projectsStore } = this.props

    return projectsStore.list
      .map(project => projectsStore.getProjectMarkerInfo(project))
      .filter(project => !!project)
  }

  private get additionalProjectAddressMarkers(): JSX.Element[] {
    const { id: activeProjectId } = this.props.state.activeProject

    return this.projectsMarkers
      .filter(marker => marker.projectId !== activeProjectId)
      .map(marker => {
        const { projectId, coordinates } = marker
        return (
          <Marker
            key={projectId}
            latitude={coordinates.latitude}
            longitude={coordinates.longitude}
            offset={[COMMON_MARKER_OFFSET, PROJECT_MARKER_OFFSET_Y]}
            draggable={false}
            onClick={this.setPopupInfo.bind(null, marker)}
            className="additional-project-marker"
          >
            <div className="pointer">
              <Icons.ProjectLocation
                onClick={this.setPopupInfo.bind(null, marker)}
              />
            </div>
          </Marker>
        )
      })
  }

  private get projectAddressMarker(): JSX.Element {
    const { latitude, longitude, shouldShowProjectMarker } = this.props
    const lat = latitude
    const lng = longitude

    if (!lat || !lng) {
      return
    }

    return (
      <Marker
        latitude={lat}
        longitude={lng}
        onDragStart={this.onProjectMarkerDrag}
        onDrag={this.onProjectMarkerDrag}
        onDragEnd={this.onProjectMarkerDragEnd}
        offset={[COMMON_MARKER_OFFSET, PROJECT_MARKER_OFFSET_Y]}
        draggable={shouldShowProjectMarker}
      >
        <div>
          <Icons.ProjectLocation />
        </div>
      </Marker>
    )
  }

  private get globe(): JSX.Element {
    const { globeLayer, containerWidth, containerHeight } = this.props
    return globeLayer(containerWidth, containerHeight)
  }

  private onProjectMarkerDrag = async (event: any): Promise<void> => {
    this.props.updateLngLat(event.lngLat.lat, event.lngLat.lng)
  }

  private onProjectMarkerDragEnd = async (event: any): Promise<void> => {
    const { lat, lng } = event.lngLat
    this.props.updateLngLat(lat, lng)
    this.changeAddress(lat, lng)
  }

  @action.bound
  private async changeAddress(lat: number, lng: number) {
    const item = await getMapBoxPlaceFromBareCoordinates(
      lng,
      lat,
      mapboxgl.accessToken,
    )

    const addr = mapboxFeatureToAddress(item)

    if (this.props.store.manuallyTriggeredProjectMarker) {
      this.props.onAddressChanged?.(addr)
      this.props.store.manuallyTriggeredProjectMarker = false
    } else {
      this.props.onAddressChanged?.(addr)
    }
  }
}
