import React from 'react'

import { Popover, PopoverPosition } from '@blueprintjs/core'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import MapViewSetUpStore from '../../views/ProjectSetUp/components/AppsSitemap/MapViewSetUp.store'
import LocationObjectsSelector from '../../views/ProjectSetUp/components/AppsSitemap/components/LocationObjectsPallet/LocationObjectsSelector'

interface IProps {
  store: MapViewSetUpStore
}

const tilesetProperties = 'Tileset properties'
const shapeFileProperties = 'Shape file properties'
const shapeFeaturesProperties = 'Shape feature properties'
const createLocation = 'Create Location with this Shape'

const latitude = 'Latitude'
const longitude = 'Longitude'
const zoomText = 'Zoom'

@observer
export default class TilesetProperties extends React.Component<IProps> {
  @observable private isCreateLocationPopupOpened = false
  public render(): React.ReactNode {
    return (
      <div className="full-height scrollable">
        <div className="col py10">
          <div className="row pb12 bb-light-input-border">
            <div className="text header bold center">{tilesetProperties}</div>
          </div>
          {this.renderSelectedTilesetProperties()}
          {this.renderSelectedFeatureProperties()}
        </div>
      </div>
    )
  }

  @action.bound
  private toggleIsCreateLocationPopupOpened(): void {
    this.isCreateLocationPopupOpened = !this.isCreateLocationPopupOpened
  }

  private renderFeaturesHeader(): JSX.Element {
    const { mapBoxViewerStore } = this.props.store
    const {
      selectedTilesetFeature,
      selectedTilesetFeatures,
      setSelectedTilesetFeature,
    } = mapBoxViewerStore.tilesetsControlStore

    return (
      selectedTilesetFeatures?.length > 1 && (
        <div className="row mb10 pointer">
          {selectedTilesetFeatures.slice().reverse().map((f, i) => (
            <div
              key={f.id}
              className={classList({
                'bg-color-blue-brand white':
                  f.id === selectedTilesetFeature?.id,
                'col br-light-grey text center': true,
              })}
              onClick={setSelectedTilesetFeature.bind(null, f)}
            >
              {i}
            </div>
          ))}
        </div>
      )
    )
  }

  private renderLocationObjectSelector(): JSX.Element {
    return <LocationObjectsSelector store={this.props.store} />
  }

  private renderSelectedFeatureProperties(): JSX.Element {
    const { mapBoxViewerStore } = this.props.store
    const { selectedTilesetFeature } = mapBoxViewerStore.tilesetsControlStore
    if (!selectedTilesetFeature) {
      return null
    }

    return (
      <div className="col pa10 bb-light-cool-grey">
        <div className="row">
          <div className="text large pb12 bold">{shapeFeaturesProperties}</div>
        </div>
        <div className="row">{this.renderFeaturesHeader()}</div>
        <Popover
          className="inline-block"
          popoverClassName="location-object-selector-wrapper"
          position={PopoverPosition.LEFT}
          minimal={true}
          usePortal={false}
          isOpen={this.isCreateLocationPopupOpened}
          canEscapeKeyClose={false}
          content={this.renderLocationObjectSelector()}
        >
          <BaseActionButton
            isEnabled={true}
            onClick={this.toggleIsCreateLocationPopupOpened}
            title={createLocation}
          />
        </Popover>
        <div className="row">
          <StruxhubInput
            label={'Geometry type'}
            type="string"
            value={selectedTilesetFeature?.geometry?.type}
            isReadOnly
            isRequiredTextHidden
            hideBottomLabel
          />
        </div>
        {Object.entries(selectedTilesetFeature.properties).map(
          ([key, value], idx) => (
            <div key={key + idx} className="relative">
              <StruxhubInput
                label={key}
                type="string"
                value={value?.toString()}
                isReadOnly
                isRequiredTextHidden
                hideBottomLabel
              />
            </div>
          ),
        )}
      </div>
    )
  }

  private renderSelectedTilesetProperties(): JSX.Element {
    const { mapBoxViewerStore, tilesetsSetupStore } = this.props.store
    const { selectedFeatureTileset } = mapBoxViewerStore.tilesetsControlStore
    const { selectedTileset, showDeleteConfirmationDialogByTileset } =
      tilesetsSetupStore
    const tileset = selectedFeatureTileset || selectedTileset

    if (!tileset) {
      return null
    }

    return (
      <div className="col pa10 bb-light-cool-grey">
        <div className="row pa10 x-end">
          <Icons.Delete
            className="no-grow pointer"
            onClick={showDeleteConfirmationDialogByTileset.bind(this, tileset)}
          />
        </div>
        <div className="row">
          <div className="text large pb12 bold">{shapeFileProperties}</div>
        </div>
        <div className="relative">
          <StruxhubInput
            label={Localization.translator.name}
            type="string"
            value={tileset.mapboxData.name}
            isReadOnly
            isRequiredTextHidden
            hideBottomLabel
          />
        </div>
        <div className="relative">
          <StruxhubInput
            label={latitude}
            type="number"
            value={tileset.mapboxData.center?.[1]?.toString()}
            isReadOnly
            isRequiredTextHidden
            hideBottomLabel
          />
        </div>
        <div className="relative">
          <StruxhubInput
            label={longitude}
            type="number"
            value={tileset.mapboxData.center?.[0]?.toString()}
            isReadOnly
            isRequiredTextHidden
            hideBottomLabel
          />
        </div>
        <div className="relative">
          <StruxhubInput
            label={zoomText}
            type="number"
            value={tileset.mapboxData.center?.[2]?.toString()}
            isReadOnly
            isRequiredTextHidden
            hideBottomLabel
          />
        </div>
      </div>
    )
  }
}
