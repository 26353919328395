import * as turf from '@turf/turf'
import { GeoJSONFeature } from 'mapbox-gl'
import { action, computed, observable } from 'mobx'

import { IGeoJson2DGeographicCoordinates, ITileset } from '~/client/graph'

import { TILESET_SOURCE } from '../components/MapBoxViewer/mapboxConstants'
import GlobeView from '../models/GlobeView'
import GlobeViewControlStore from './GlobeViewControl.store'
import TilesetsStore from './domain/Tilesets.store'

export default class TilesetsControlStore {
  @observable public selectedTilesetFeatures: Array<GeoJSONFeature> = []
  @observable public selectedTilesetFeature: GeoJSONFeature = null
  @observable public selectedTilesetId: string

  public constructor(
    private readonly tilesetsStore: TilesetsStore,
    public readonly globeViewControlStore: GlobeViewControlStore,
  ) {}

  public get selectedGlobe(): GlobeView {
    return this.globeViewControlStore.selectedGlobeView
  }

  // tilesets to show as additional on the 'GLOBE'
  @computed
  public get globeTilesets(): ITileset[] {
    return this.selectedGlobe
      ? this.selectedGlobe.tilesetIds
          .filter(tilesetId => !!this.tilesetsStore.byId.get(tilesetId))
          .map(tileset => this.tilesetsStore.byId.get(tileset)) || []
      : []
  }

  public get selectedFeatureTileset(): ITileset {
    const index = TILESET_SOURCE.length
    const id = this.selectedTilesetFeature?.source?.slice(index)
    return this.globeTilesets?.find(t => t.id === id)
  }

  public get selectedTilesetFeatureCoordinates(): IGeoJson2DGeographicCoordinates[] {
    return (
      this.selectedTilesetFeature as GeoJSON.Feature<GeoJSON.Polygon>
    )?.geometry.coordinates[0].map(c => ({
      longitude: c[0],
      latitude: c[1],
    }))
  }

  public get selectedTilesetFeatureCoordinatesCenter(): IGeoJson2DGeographicCoordinates {
    const centerOfMass = turf.centerOfMass(this.selectedTilesetFeature).geometry
      .coordinates
    return {
      longitude: centerOfMass[0],
      latitude: centerOfMass[1],
    }
  }

  @action.bound
  public setSelectedTilesetFeature(feature: GeoJSONFeature): void {
    this.selectedTilesetFeature = feature
  }

  @action.bound
  public resetTilesetFeaturesSelection(): void {
    this.selectedTilesetFeature = null
    this.selectedTilesetFeatures = []
  }
}
