import * as React from 'react'

import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import AvatarType from '../../enums/AvatarType'
import * as Icons from '../Icons'

import './Avatar.scss'

interface IProps {
  src?: string
  size?: AvatarSize
  initials?: string
  className?: string
  avatarType?: AvatarType
  isOnline?: boolean
}

// translated

export enum AvatarSize {
  Enormous = 'enormous',
  Large = 'large',
  Small = 'small',
  Tiny = 'tiny',
  Minuscule = 'minuscule',
}

export default class Avatar extends React.PureComponent<IProps> {
  public static defaultProps = {
    size: AvatarSize.Small,
  }

  public render() {
    const { src, size, initials, className, isOnline = null } = this.props

    return (
      <div
        className={classList({
          'avatar-container row x-center br-rounded no-flex relative': true,
          'initials-wrapper': !src && !!initials,
          [size]: true,
          [className]: !!className,
        })}
      >
        {this.renderImg()}
        {isOnline != null && (
          <span
            className={classList({
              'avatar-status': true,
              online: isOnline,
            })}
          />
        )}
      </div>
    )
  }

  private renderImg(): JSX.Element {
    const { src, initials, avatarType } = this.props
    const { avatar } = Localization.translator

    if (src) {
      return <img src={src} alt={avatar} />
    }
    if (initials) {
      return this.renderInitials(initials)
    }

    switch (avatarType) {
      case AvatarType.User:
        return <Icons.User className="avatar-placeholder row" />

      case AvatarType.Company:
        return <Icons.Company className="avatar-placeholder row" />

      default:
        return <Icons.User className="avatar-placeholder row" />
    }
  }

  private renderInitials(initials: string): JSX.Element {
    return (
      <div className="text bold no-grow no-select initials">{initials}</div>
    )
  }
}
