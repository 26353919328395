import { action, computed, observable } from 'mobx'
import { ViewState } from 'react-map-gl'

import {
  IActivitiesConfigurations,
  IDeliveryConfigurations,
  IFormsConfigurations,
  ILogisticsConfigurations,
  IOrderedSitemap,
} from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventsStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import MapBoxViewerStore from '~/client/src/shared/components/MapBoxViewer/MapBoxViewer.store'
import GlobeView from '~/client/src/shared/models/GlobeView'
import Sitemap from '~/client/src/shared/models/Sitemap'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import EventTypes from '~/client/src/shared/stores/EventStore/eventTypes'
import GlobeViewSetupStore from '~/client/src/shared/stores/GlobeViewSetup.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import SyncRestrictionsStore from '~/client/src/shared/stores/domain/SyncRestrictions.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import TilesetsStore from '~/client/src/shared/stores/domain/Tilesets.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import MapBoxEditorStore, { ViewType } from './stores/MapBoxEditor.store'
import MapViewItemsSetupStore from './stores/MapViewItemsSetup.store'
import MapViewsListStore, { MapViewType } from './stores/MapViewsList.store'
import SitemapControlStore from './stores/SitemapControl.store'
import SitemapViewsSetupStore from './stores/SitemapViewsSetup.store'
import SitemapsSetupStore from './stores/SitemapsSetup.store'
import TilesetsSetupStore from './stores/TilesetsSetup.store'

const MIME_TYPE_REGEX = /:(.*?);/
export const ACCEPTED_SITEMAP_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.pdf']

export enum MapViewConfigType {
  deliveries,
  activities,
  logistics,
  forms,
}

export enum SetUpSteps {
  uploadPlan = '1. Upload plan',
  alignPlan = '2. Align plan & basemap',
}

export enum MenuViewMode {
  globes = 'globes',
  whiteboards = 'whiteboards',
}

export default class MapViewSetUpStore {
  @observable public selectedViewMode: MenuViewMode = MenuViewMode.globes
  public sitemapsSetupStore: SitemapsSetupStore
  public mapViewItemsSetupStore: MapViewItemsSetupStore
  public sitemapViewsSetupStore: SitemapViewsSetupStore
  public mapBoxViewerStore: MapBoxViewerStore
  public mapBoxEditorStore: MapBoxEditorStore
  public globeViewSetupStore: GlobeViewSetupStore
  public tilesetsSetupStore: TilesetsSetupStore
  public mapViewsListStore: MapViewsListStore
  public sitemapControlStore: SitemapControlStore

  @observable
  public isUpdatingSitemapLoaderShown: boolean = false
  @observable public currentStep: SetUpSteps = null
  @observable public isGlobeListShown: boolean = false

  private resolveSaveImagePromise: () => void = null

  public constructor(
    private readonly eventsStore: DesktopEventsStore,
    public readonly sitemapsStore: SitemapsStore,
    basemapsStore: BasemapsStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly sitemapItemsStore: SitemapItemsStore,
    fileUploadingStore: FileUploadingStore,
    syncRestrictionsStore: SyncRestrictionsStore,
    activityFiltersStore: ActivityFiltersStore,
    userProjectsStore: UserProjectsStore,
    tagsStore: TagsStore,
    public readonly globeViewsStore: GlobeViewsStore,
    public readonly tilesetsStore: TilesetsStore,
  ) {
    this.globeViewSetupStore = new GlobeViewSetupStore(
      eventsStore,
      globeViewsStore,
      userProjectsStore,
      sitemapItemsStore,
      locationAttributesStore,
      tagsStore,
    )

    this.sitemapsSetupStore = new SitemapsSetupStore(
      eventsStore,
      sitemapsStore,
      basemapsStore,
      fileUploadingStore,
      this,
      locationAttributesStore.gatesStore,
      locationAttributesStore.zonesStore,
      userProjectsStore,
      globeViewsStore,
    )

    this.mapViewItemsSetupStore = new MapViewItemsSetupStore(
      eventsStore,
      sitemapItemsStore,
      locationAttributesStore,
      syncRestrictionsStore,
      activityFiltersStore,
      this,
      fileUploadingStore,
    )

    this.mapViewsListStore = new MapViewsListStore(
      eventsStore.appState,
      globeViewsStore,
      sitemapsStore,
      sitemapItemsStore,
      this.mapViewItemsSetupStore,
      locationAttributesStore,
    )

    this.sitemapViewsSetupStore = new SitemapViewsSetupStore(
      eventsStore.appState,
      this,
      locationAttributesStore,
      tagsStore,
    )

    this.mapBoxViewerStore = new MapBoxViewerStore(
      sitemapsStore,
      tilesetsStore,
      locationAttributesStore,
      this.eventsStore.appState,
      this.globeViewSetupStore,
      basemapsStore,
      this.eventsStore,
      true,
    )

    this.tilesetsSetupStore = new TilesetsSetupStore(
      tilesetsStore,
      this.mapViewItemsSetupStore,
    )

    this.mapBoxEditorStore = new MapBoxEditorStore(
      this.mapBoxViewerStore,
      this.mapViewItemsSetupStore,
      this.sitemapsStore,
      this.tilesetsStore,
      this.locationAttributesStore,
      this.globeViewSetupStore,
      basemapsStore,
      this.sitemapsSetupStore,
      this.tilesetsSetupStore,
    )

    this.sitemapControlStore = new SitemapControlStore(this, this.setStep)
  }

  @action.bound
  public toggleGlobeList(): void {
    this.mapViewItemsSetupStore.deselectMapViewItem()
    this.sitemapsSetupStore.isAssignSitemapDialogShown = false
    this.globeViewSetupStore.isAssignGlobeDialogShown = false
    this.isGlobeListShown = !this.isGlobeListShown
  }

  public get viewport(): ViewState {
    return this.mapBoxViewerStore.viewport
  }

  @computed
  public get logisticsAssignedGlobes(): { [id: string]: boolean } {
    return this.state.logisticsMapIdsList
      .filter(m => m.globeViewId)
      .map(m => m.globeViewId)
      .reduce((map, globeViewId) => {
        map[globeViewId] = true
        return map
      }, {})
  }

  @computed
  public get formsAssignedGlobes(): { [id: string]: boolean } {
    return this.state.formsMapIdsList
      .filter(m => m.globeViewId)
      .map(m => m.globeViewId)
      .reduce((map, globeViewId) => {
        map[globeViewId] = true
        return map
      }, {})
  }

  @computed
  public get deliveriesAssignedGlobes(): { [id: string]: boolean } {
    return this.state.deliveriesMapIdsList
      .filter(m => m.globeViewId)
      .map(m => m.globeViewId)
      .reduce((map, globeViewId) => {
        map[globeViewId] = true
        return map
      }, {})
  }

  @computed
  public get activitiesAssignedGlobes(): { [id: string]: boolean } {
    return this.state.activitiesMapIdsList
      .filter(m => m.globeViewId)
      .map(m => m.globeViewId)
      .reduce((map, globeViewId) => {
        map[globeViewId] = true
        return map
      }, {})
  }

  @computed
  public get activitiesAssignedSitemaps(): { [id: string]: boolean } {
    return this.state.activitiesMapIdsList
      .filter(m => m.sitemapId)
      .map(m => m.sitemapId)
      .reduce((map, sitemapId) => {
        map[sitemapId] = true
        return map
      }, {})
  }

  @computed
  public get logisticsAssignedSitemaps(): { [id: string]: boolean } {
    return this.state.logisticsMapIdsList
      .filter(m => m.sitemapId)
      .map(m => m.sitemapId)
      .reduce((map, sitemapId) => {
        map[sitemapId] = true
        return map
      }, {})
  }

  @computed
  public get formsAssignedSitemaps(): { [id: string]: boolean } {
    return this.state.formsMapIdsList
      .filter(m => m.sitemapId)
      .map(m => m.sitemapId)
      .reduce((map, sitemapId) => {
        map[sitemapId] = true
        return map
      }, {})
  }

  @computed
  public get deliveriesAssignedSitemaps(): { [id: string]: boolean } {
    return this.state.deliveriesMapIdsList
      .filter(m => m.sitemapId)
      .map(m => m.sitemapId)
      .reduce((map, sitemapId) => {
        map[sitemapId] = true
        return map
      }, {})
  }

  @action
  public selectMapView(type: MapViewType, id: string): void {
    this.mapViewItemsSetupStore.selectedMapViewItem = null
    if (type === MapViewType.GlobeView) {
      if (this.globeViewSetupStore.selectedGlobeViewId === id) {
        return
      }
      this.sitemapsSetupStore.deselectSitemap()
      this.tilesetsSetupStore.deselectTileset()
      this.mapBoxViewerStore.tilesetsControlStore.resetTilesetFeaturesSelection()
      this.globeViewSetupStore.selectGlobeById(id)
      this.mapBoxViewerStore.setViewportFromAddress()
    }
    if (type === MapViewType.Whiteboard) {
      if (this.sitemapsSetupStore.selectedSitemap?.id === id) {
        return
      }
      this.onViewTypeSelect(ViewType.Objects)
      this.sitemapsSetupStore.selectSitemapById(id)
      this.globeViewSetupStore.deselectGlobe()
    }
  }

  @computed
  public get isGlobeMode(): boolean {
    return (
      !!this.globeViewSetupStore.selectedGlobeView ||
      !this.sitemapsSetupStore.selectedSitemap
    )
  }

  @action.bound
  public onViewTypeSelect(viewType: ViewType): void {
    if (this.mapBoxEditorStore.selectedViewType === viewType) {
      return
    }
    if (!this.mapBoxEditorStore.isRubberMode) {
      this.mapViewItemsSetupStore.disableCreatingAttribute()
      if (this.mapViewItemsSetupStore.selectedMapViewItem) {
        this.mapViewItemsSetupStore.safelyDeselectMapViewItem()
      }
      if (this.isGlobeMode && this.sitemapsSetupStore.selectedSitemap) {
        this.sitemapsSetupStore.deselectSitemap()
      }
      if (this.isGlobeMode && this.tilesetsSetupStore.selectedTileset) {
        this.tilesetsSetupStore.deselectTileset()
      }
      if (
        this.isGlobeMode &&
        this.mapBoxViewerStore.tilesetsControlStore.selectedTilesetFeature
      ) {
        this.mapBoxViewerStore.tilesetsControlStore.resetTilesetFeaturesSelection()
      }
    }

    this.mapViewItemsSetupStore.resetSearchKey()
    this.mapBoxEditorStore.onViewTypeSelect(viewType)
  }

  @action.bound
  public onGlobeSectionClick(
    globe: GlobeView,
    configType: MapViewConfigType,
  ): void {
    switch (configType) {
      case MapViewConfigType.activities:
        if (this.activitiesAssignedGlobes[globe.id]) {
          this.clearActivitiesGlobe(globe)
        } else {
          this.assignActivitiesGlobe(globe)
        }
        return
      case MapViewConfigType.deliveries:
        if (this.deliveriesAssignedGlobes[globe.id]) {
          this.clearDeliveriesGlobe(globe)
        } else {
          this.assignDeliveriesGlobe(globe)
        }
        return
      case MapViewConfigType.logistics:
        if (this.logisticsAssignedGlobes[globe.id]) {
          this.clearLogisticsGlobe(globe)
        } else {
          this.assignLogisticsGlobe(globe)
        }
        return
      case MapViewConfigType.forms:
        if (this.formsAssignedGlobes[globe.id]) {
          this.clearFormsGlobe(globe)
        } else {
          this.assignFormsGlobe(globe)
        }
        return
    }
  }

  @action.bound
  public onWhiteboardSectionClick(
    sitemap: Sitemap,
    configType: MapViewConfigType,
  ): void {
    switch (configType) {
      case MapViewConfigType.activities:
        if (this.activitiesAssignedSitemaps[sitemap.id]) {
          this.clearActivitiesSitemap(sitemap)
        } else {
          this.assignActivitiesSitemap(sitemap)
        }
        return
      case MapViewConfigType.deliveries:
        if (this.deliveriesAssignedSitemaps[sitemap.id]) {
          this.clearDeliveriesSitemap(sitemap)
        } else {
          this.assignDeliveriesSitemap(sitemap)
        }
        return
      case MapViewConfigType.logistics:
        if (this.logisticsAssignedSitemaps[sitemap.id]) {
          this.clearLogisticsSitemap(sitemap)
        } else {
          this.assignLogisticsSitemap(sitemap)
        }
        return
      case MapViewConfigType.forms:
        if (this.formsAssignedSitemaps[sitemap.id]) {
          this.clearFormsSitemap(sitemap)
        } else {
          this.assignFormsSitemap(sitemap)
        }
        return
    }
  }

  @action.bound
  private clearDeliveriesSitemap(sitemap: Sitemap): void {
    const { configurations } = this.state.delivery
    this.removeSitemapFromConfig(
      configurations,
      e.SAVE_DELIVERY_CONFIGURATIONS,
      sitemap,
    )
  }

  @action.bound
  private clearFormsSitemap(sitemap: Sitemap): void {
    const { configurations } = this.state.forms
    this.removeSitemapFromConfig(
      configurations,
      e.SAVE_FORMS_CONFIGURATIONS,
      sitemap,
    )
  }

  @action.bound
  private clearLogisticsSitemap(sitemap: Sitemap): void {
    const { configurations } = this.state.logistics
    this.removeSitemapFromConfig(
      configurations,
      e.SAVE_LOGISTICS_CONFIGURATIONS,
      sitemap,
    )
  }

  @action.bound
  private clearActivitiesSitemap(sitemap: Sitemap): void {
    const { configurations } = this.state.activitiesSettings
    this.removeSitemapFromConfig(
      configurations,
      e.SAVE_ACTIVITIES_CONFIGURATIONS,
      sitemap,
    )
  }

  @action.bound
  private assignFormsSitemap(sitemap: Sitemap): void {
    const { maps } = this.state.forms.configurations
    this.addSitemapToConfig(maps, e.SAVE_FORMS_CONFIGURATIONS, sitemap)
  }

  @action.bound
  private assignLogisticsSitemap(sitemap: Sitemap): void {
    const { maps } = this.state.logistics.configurations
    this.addSitemapToConfig(maps, e.SAVE_LOGISTICS_CONFIGURATIONS, sitemap)
  }

  @action.bound
  private assignActivitiesSitemap(sitemap: Sitemap): void {
    const { maps } = this.state.activitiesSettings.configurations
    this.addSitemapToConfig(maps, e.SAVE_ACTIVITIES_CONFIGURATIONS, sitemap)
  }

  @action.bound
  private assignDeliveriesSitemap(sitemap: Sitemap): void {
    const { maps } = this.state.delivery.configurations
    this.addSitemapToConfig(maps, e.SAVE_DELIVERY_CONFIGURATIONS, sitemap)
  }

  @action.bound
  private addSitemapToConfig(
    maps: IOrderedSitemap[],
    eventType: EventTypes,
    sitemap: Sitemap,
  ): void {
    if (!sitemap) {
      return
    }

    const { id } = sitemap
    if (!maps.some(m => m.sitemapId === id)) {
      maps.push({
        sitemapId: id,
        order: maps.length,
      })

      this.eventsStore.dispatch(eventType, {
        maps,
        projectId: this.state.activeProject.id,
      })
    }
  }

  @action.bound
  private removeSitemapFromConfig(
    config:
      | IDeliveryConfigurations
      | IActivitiesConfigurations
      | IFormsConfigurations
      | ILogisticsConfigurations,
    eventType: EventTypes,
    sitemap: Sitemap,
  ): void {
    if (!sitemap) {
      return
    }

    const { id } = sitemap

    config.maps = config.maps.filter(m => m.sitemapId !== id)

    this.eventsStore.dispatch(eventType, {
      maps: config.maps,
    })
  }

  @action.bound
  public setStep(step: SetUpSteps): void {
    this.currentStep = step
    this.mapBoxEditorStore.isRubberMode = step === SetUpSteps.alignPlan
    this.mapBoxEditorStore.isDraggingMode = step === SetUpSteps.alignPlan

    step === SetUpSteps.alignPlan && this.mapBoxViewerStore.setZeroPitch()
    this.mapBoxEditorStore.updateOpacityBySetupStep()
  }

  @action.bound
  public setOpacity(opacity: number): void {
    this.mapBoxEditorStore.opacity = opacity
  }

  public get isInitialLoaderShown(): boolean {
    return (
      !this.sitemapsStore.isDataReceived ||
      !this.globeViewsStore.isDataReceived ||
      !this.sitemapItemsStore.isDataReceived ||
      !this.locationAttributesStore.isDataReceived ||
      !(this.state.isTilesetsDisabled || this.tilesetsStore.isDataReceived)
    )
  }

  public get isLoading(): boolean {
    return this.eventsStore.appState.loading.get(e.ACTIVATE_PROJECT)
  }

  @action.bound
  public showLoader(): void {
    this.isUpdatingSitemapLoaderShown = true
  }

  @action.bound
  public hideLoader(): void {
    this.isUpdatingSitemapLoaderShown = false
  }

  public saveGlobeViewImage = async (
    filledImage: string,
    globeView?: GlobeView,
  ): Promise<void> => {
    await this.globeViewSetupStore.saveGlobeViewImage(filledImage, globeView)
    this.hideLoaderAndHideImageLoading()
  }

  public saveDeliverySitemapImage = async (
    base64Image: string,
    base64ItemsImage: string,
  ): Promise<void> => {
    const sitemap = this.sitemapsSetupStore.selectedSitemap

    if (!base64Image || !this.sitemapsSetupStore.selectedSitemap?.id) {
      return this.resolveImagePromise()
    }

    const file = this.base64ImageToFile(base64Image, 'filled-sitemap.png')
    const itemsFile =
      base64ItemsImage &&
      this.base64ImageToFile(base64ItemsImage, 'items-filled-sitemap.png')

    await this.sitemapsSetupStore.updateSitemapFilledImage(
      sitemap,
      file,
      itemsFile,
    )

    this.hideLoaderAndHideImageLoading()
    this.resolveImagePromise()
  }

  public base64ImageToFile = (base64Image: string, fileName: string): File => {
    const [base64Type, base64Data] = base64Image.split(',')

    const mime = base64Type.match(MIME_TYPE_REGEX)[1]
    const decodedImageData = atob(base64Data)

    const fileData = new Uint8Array(decodedImageData.length)

    let index = decodedImageData.length
    while (index--) {
      fileData[index] = decodedImageData.charCodeAt(index)
    }

    return new File([fileData], fileName, { type: mime })
  }

  @action.bound
  private hideLoaderAndHideImageLoading(): void {
    this.hideLoader()
    this.setSaveSitemapImageLoading(false)
    this.setSaveGlobeImageLoading(false)
  }

  @action.bound
  private setSaveSitemapImageLoading(isLoading: boolean): void {
    this.eventsStore.appState.loading.set(e.SAVE_SITEMAP_IMAGE, isLoading)
  }

  @action.bound
  private setSaveGlobeImageLoading(isLoading: boolean): void {
    this.eventsStore.appState.loading.set(e.SAVE_GLOBE_IMAGE, isLoading)
  }

  @action.bound
  private resolveImagePromise(): void {
    if (this.resolveSaveImagePromise) {
      this.resolveSaveImagePromise()
      this.resolveSaveImagePromise = null
    }
  }

  @action.bound
  private clearActivitiesGlobe(globe: GlobeView): void {
    const { configurations } = this.state.activitiesSettings
    this.removeGlobeFromConfig(
      configurations,
      e.SAVE_ACTIVITIES_CONFIGURATIONS,
      globe,
    )
  }

  @action.bound
  private clearDeliveriesGlobe(globe: GlobeView): void {
    const { configurations } = this.state.delivery
    this.removeGlobeFromConfig(
      configurations,
      e.SAVE_DELIVERY_CONFIGURATIONS,
      globe,
    )
  }

  @action.bound
  private clearLogisticsGlobe(globe: GlobeView): void {
    const { configurations } = this.state.logistics
    this.removeGlobeFromConfig(
      configurations,
      e.SAVE_LOGISTICS_CONFIGURATIONS,
      globe,
    )
  }

  @action.bound
  private clearFormsGlobe(globe: GlobeView): void {
    const { configurations } = this.state.forms
    this.removeGlobeFromConfig(
      configurations,
      e.SAVE_FORMS_CONFIGURATIONS,
      globe,
    )
  }

  @action.bound
  private assignLogisticsGlobe(globe: GlobeView): void {
    const { maps } = this.state.logistics.configurations
    this.addGlobeToConfig(maps, e.SAVE_LOGISTICS_CONFIGURATIONS, globe)
  }

  @action.bound
  private assignFormsGlobe(globe: GlobeView): void {
    const { maps } = this.state.forms.configurations
    this.addGlobeToConfig(maps, e.SAVE_FORMS_CONFIGURATIONS, globe)
  }

  @action.bound
  private assignDeliveriesGlobe(globe: GlobeView): void {
    const { maps } = this.state.delivery.configurations
    this.addGlobeToConfig(maps, e.SAVE_DELIVERY_CONFIGURATIONS, globe)
  }

  @action.bound
  private assignActivitiesGlobe(globe: GlobeView): void {
    const { maps } = this.state.activitiesSettings.configurations
    this.addGlobeToConfig(maps, e.SAVE_ACTIVITIES_CONFIGURATIONS, globe)
  }

  @action.bound
  private removeGlobeFromConfig(
    config:
      | IDeliveryConfigurations
      | IActivitiesConfigurations
      | IFormsConfigurations
      | ILogisticsConfigurations,
    eventType: EventTypes,
    globe: GlobeView,
  ): void {
    if (!globe) {
      return
    }

    const { id } = globe

    config.maps = config.maps.filter(m => m.globeViewId !== id)

    this.eventsStore.dispatch(eventType, {
      maps: config.maps,
    })
  }

  @action.bound
  private addGlobeToConfig(
    maps: IOrderedSitemap[],
    eventType: EventTypes,
    globe: GlobeView,
  ): void {
    if (!globe) {
      return
    }

    const { id } = globe
    if (!maps?.some(m => m.globeViewId === id)) {
      maps.push({
        globeViewId: id,
        order: maps.length,
      })

      this.eventsStore.dispatch(eventType, {
        maps,
        projectId: this.state.activeProject.id,
      })
    }
  }

  private get state(): DesktopInitialState {
    return this.eventsStore.appState
  }

  @action.bound
  public setGlobesViewMode(): void {
    this.selectedViewMode = MenuViewMode.globes
  }

  @action.bound
  public setWhiteboardsViewMode(): void {
    this.selectedViewMode = MenuViewMode.whiteboards
  }
}
