import { action, computed, observable } from 'mobx'

import { DeliveryListFilterType, LocationType } from '~/client/graph'
import DeliveryListComponentStore from '~/client/src/shared/components/Deliveries/DeliveryListComponent.store'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import { IDeliveriesSettings } from '~/client/src/shared/enums/ProjectSpecificUserProps'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import OffloadingEquipment from '~/client/src/shared/models/LocationObjects/OffloadingEquipment'
import Zone from '~/client/src/shared/models/LocationObjects/Zone'
import Sitemap from '~/client/src/shared/models/Sitemap'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryFilterStore from '~/client/src/shared/stores/ui/DeliveryFilter.store'
import Guard from '~/client/src/shared/utils/Guard'

import CalendarEventLabelType from '../../enums/CalendarEventLabelType'
import CalendarEvent, {
  CalendarEventEntityType,
} from '../../models/CalendarEvent'
import GlobeView from '../../models/GlobeView'
import DeliveryAssignmentsStore from '../../stores/domain/DeliveryAssignments.store'
import DeliveryFollowingsStore from '../../stores/domain/DeliveryFollowings.store'
import DeliveryVehicleTypesStore from '../../stores/domain/DeliveryVehicleTypes.store'
import GlobeViewsStore from '../../stores/domain/GlobeViews.store'
import LocationAttributesStore from '../../stores/domain/LocationAttributes.store'
import MaterialCategoryStore from '../../stores/domain/MaterialCategories.store'
import MaterialsStore from '../../stores/domain/Materials.store'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import SitemapItemsStore from '../../stores/domain/SitemapItems.store'
import SitemapsStore from '../../stores/domain/Sitemaps.store'
import UserProjectsStore from '../../stores/domain/UserProjects.store'
import CommonStore from '../../stores/ui/Common.store'
import ProjectDateStore, {
  areIntervalTimesIntersects,
} from '../../stores/ui/ProjectDate.store'
import EventStyleCssAdapter from '../../utils/EventStyleCssAdapter'
import { NEW_DELIVERY_PATH_KEYWORD } from '../../utils/usefulStrings'
import CalendarDayViewStore from '../CalendarDayView/CalendarDayView.store'
import DeliveriesMapViewStore from './components/DeliveriesMapView/DeliveriesMapView.store'

import Colors from '~/client/src/shared/theme.module.scss'

interface IFilter {
  title: string
  isActive: boolean
  data: string
  color?: string
  onActivate: (value: any) => void
}

export enum DeliveryPageMode {
  REQUEST_FROM_ACTIVITY,
  VIEW_FROM_ACTIVITY,
  VIEW_WITH_HIGHLIGHTED_DELIVERY,
  VIEW,
}

export enum DeliveryPageViewMode {
  Calendar = 'calendar',
  List = 'list',
  Map = 'map',
}

export const UNASSIGNED_FILTER_OPTION = 'Unassigned'

export default class DeliveriesViewStore {
  @observable public isFiltersModalOpen: boolean = false
  @observable public activeZoneId: string = null
  @observable public activeOffloadingEquipmentId: string = null
  @observable public isFollowingFilterActive: boolean = false
  @observable public isMyCompanyFilterActive: boolean = false
  @observable public selectedGroupByOption: DeliveryListFilterType =
    DeliveryListFilterType.Company
  @observable public isGroupByFilterShown: boolean = false

  @observable private selectedDatePeriod: Date = new Date()

  public get isLoading(): boolean {
    return (
      !this.locationAttributesStore.isDataReceived ||
      !this.deliveryAssignmentsStore.isDataReceived ||
      !this.deliveryFollowingsStore.isDataReceived ||
      !this.deliveriesStore.isDataReceived ||
      !this.materialCategoryStore.isDataReceived ||
      !this.materialsStore.isDataReceived
    )
  }

  public get currentStartDate() {
    return this.eventsStore.appState.delivery.compactView.activeDate
  }

  public set currentStartDate(date: Date) {
    this.eventsStore.appState.delivery.compactView.activeDate = date
  }

  public get currentEndDate() {
    return this.eventsStore.appState.delivery.compactView.activeEndDate
  }

  public set currentEndDate(date: Date) {
    this.eventsStore.appState.delivery.compactView.activeEndDate = date
  }

  public get highlightedDelivery() {
    return this.eventsStore.appState.delivery.compactView.highlightedDelivery
  }

  public set highlightedDelivery(delivery: Delivery) {
    this.eventsStore.appState.delivery.compactView.highlightedDelivery =
      delivery
  }

  public get mode() {
    return this.eventsStore.appState.delivery.compactView.mode
  }

  public get selectedViewMode() {
    return this.eventsStore.appState.delivery.compactView.viewMode
  }

  public calendarStore: CalendarDayViewStore
  public mapStore: DeliveriesMapViewStore
  public listStore: DeliveryListComponentStore

  public constructor(
    private readonly deliveriesStore: DeliveriesStore,
    private readonly eventsStore: EventsStore,
    private readonly activitiesStore: ActivitiesStore,
    private readonly deliveryDetailsStore: DeliveryDetailsStore,
    private readonly commonStore: CommonStore,
    private readonly sitemapsStore: SitemapsStore,
    private readonly globeViewsStore: GlobeViewsStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly deliveryFilterStore: DeliveryFilterStore,
    sitemapItemsStore: SitemapItemsStore,
    private readonly deliveryFollowingsStore: DeliveryFollowingsStore,
    private readonly deliveryAssignmentsStore: DeliveryAssignmentsStore,
    private readonly companiesStore: CompaniesStore,
    deliveryVehicleTypesStore: DeliveryVehicleTypesStore,
    private readonly materialCategoryStore: MaterialCategoryStore,
    private readonly materialsStore: MaterialsStore,
    private readonly shouldUseSavedFilters: boolean,
    private readonly userProjectsStore: UserProjectsStore,
    projectMembersStore: ProjectMembersStore,
    private readonly shouldUseSelectedDatePeriod: boolean,
  ) {
    Guard.requireAll({
      deliveriesStore,
      eventsStore,
      activitiesStore,
      deliveryDetailsStore,
      commonStore,
      sitemapsStore,
      projectDateStore,
      locationAttributesStore,
      deliveryFilterStore,
      sitemapItemsStore,
      deliveryFollowingsStore,
      deliveryAssignmentsStore,
      companiesStore,
      deliveryVehicleTypesStore,
      materialCategoryStore,
      materialsStore,
      projectMembersStore,
    })

    this.calendarStore = new CalendarDayViewStore(
      this.projectDateStore,
      companiesStore,
      CalendarEventEntityType.Delivery,
      this.deliveryDuration,
    )
    this.mapStore = new DeliveriesMapViewStore(
      this,
      eventsStore.appState,
      sitemapsStore,
      globeViewsStore,
      locationAttributesStore,
      sitemapItemsStore,
    )
    this.listStore = new DeliveryListComponentStore(
      eventsStore,
      deliveriesStore,
      projectDateStore,
      locationAttributesStore,
      deliveryVehicleTypesStore,
      materialCategoryStore,
      materialsStore,
      companiesStore,
      this,
      projectMembersStore,
    )

    this.currentEndDate = this.projectDateStore.endOfDay(this.currentStartDate)

    this.setSavedFilterValues()
  }

  @action.bound
  public toggleGroupBy() {
    this.isGroupByFilterShown = !this.isGroupByFilterShown
  }

  @action.bound
  public toggleFollowing() {
    this.isFollowingFilterActive = !this.isFollowingFilterActive

    this.updateFilters()
  }

  @action.bound
  public selectGroupBy(mode: DeliveryListFilterType) {
    this.selectedGroupByOption = mode

    this.updateFilters()
  }

  @action.bound
  public toggleMyCompany() {
    this.isMyCompanyFilterActive = !this.isMyCompanyFilterActive

    this.updateFilters()
  }

  @action.bound
  public toggleFilters() {
    this.isFiltersModalOpen = !this.isFiltersModalOpen
    if (this.shouldUseSavedFilters) {
      this.deliveryFilterStore.setFiltersValues()
    }
  }

  @action.bound
  public updateFilters() {
    this.saveUserDeliveriesSettings()
    this.mapStore.resetDisplayedDelivery()

    if (this.isListMode) {
      this.listStore.calculateDeliveryTree()
    }
  }

  @action.bound
  public selectDatePeriod(date: Date) {
    this.selectedDatePeriod = date
  }

  @action.bound
  public setSavedFilterValues() {
    if (this.isLoading || !this.userActiveProjectSettings) {
      return
    }

    const { deliveriesSettings } = this.userActiveProjectSettings

    if (deliveriesSettings) {
      const {
        isFollowingFilterActive,
        isMyCompanyFilterActive,
        selectedGroupByOption,
      } = deliveriesSettings

      this.isFollowingFilterActive = isFollowingFilterActive
      this.isMyCompanyFilterActive = isMyCompanyFilterActive
      this.selectedGroupByOption = selectedGroupByOption
    }

    this.deliveryFilterStore.setFiltersValues()
  }

  public deliveryPredicate(delivery: Delivery): boolean {
    if (!this.isDeliveryInSelectedPeriod(delivery)) {
      return false
    }
    if (
      this.activeZoneId &&
      (this.activeZoneId === UNASSIGNED_FILTER_OPTION
        ? !this.isZoneUnassigned(delivery)
        : !delivery.isZoneId(this.activeZoneId))
    ) {
      return false
    }
    if (
      this.activeOffloadingEquipmentId &&
      (this.activeOffloadingEquipmentId === UNASSIGNED_FILTER_OPTION
        ? !this.isOffloadingEquipmentUnassigned(delivery)
        : !delivery.isOffloadingEquipmentId(this.activeOffloadingEquipmentId))
    ) {
      return false
    }
    return true
  }

  public isDeliveryInSelectedPeriod({ startDate, endDate }: Delivery) {
    const { endOfDay, startOfDay } = this.projectDateStore

    let currentStartDate = this.currentStartDate
    let currentEndDate = this.currentEndDate

    if (this.shouldUseSelectedDatePeriod) {
      currentStartDate = startOfDay(this.selectedDatePeriod)
      currentEndDate = endOfDay(this.selectedDatePeriod)
    }

    return areIntervalTimesIntersects(
      {
        startDate: currentStartDate,
        endDate: currentEndDate,
      },
      {
        startDate: startOfDay(startDate),
        endDate: endOfDay(endDate),
      },
    )
  }

  public get deliveryDuration() {
    return this.eventsStore.appState.delivery.configurations.deliveryDuration
  }

  @computed
  public get filteredCollection(): Delivery[] {
    if (this.isLoading) {
      return []
    }

    const { user, userActiveProjectSettings } = this.eventsStore.appState

    if (!user || !userActiveProjectSettings) {
      return []
    }

    let filteredDeliveries: Delivery[]
    filteredDeliveries = this.deliveriesStore.availableDeliveries.filter(
      delivery => {
        return Object.values(
          this.deliveryFilterStore.groupedFilterStoresByTypeMap,
        ).every(filtersGroup => {
          return filtersGroup.every(
            filter =>
              !filter.appliedOptions.length ||
              filter.appliedOptions.includes(delivery.id),
          )
        })
      },
    )

    if (this.isFollowingFilterActive) {
      filteredDeliveries = filteredDeliveries.filter(
        ({ id }) =>
          this.deliveryFollowingsStore.isEntityFollowed(id) ||
          this.deliveryAssignmentsStore
            .getAssignedEntitiesByUserId(user.id)
            .includes(id),
      )
    }

    if (this.isMyCompanyFilterActive) {
      filteredDeliveries = filteredDeliveries.filter(
        d => d.company === userActiveProjectSettings.companyId,
      )
    }
    return filteredDeliveries
  }

  @computed
  public get currentViewDeliveries(): Delivery[] {
    return this.filteredCollection.filter(delivery =>
      this.isDeliveryInSelectedPeriod(delivery),
    )
  }

  public get isActivityMode() {
    return this.eventsStore.appState.delivery.compactView.isActivityMode
  }

  @action.bound
  public setMode(mode: DeliveryPageMode) {
    this.eventsStore.appState.delivery.compactView.mode = mode
  }

  @action.bound
  public setViewMode(mode: DeliveryPageViewMode) {
    this.eventsStore.appState.delivery.compactView.viewMode = mode
    this.currentEndDate = this.projectDateStore.endOfDay(this.currentStartDate)
    window.location.hash = mode
  }

  public get isCalendarMode(): boolean {
    return this.selectedViewMode === DeliveryPageViewMode.Calendar
  }

  public get isListMode(): boolean {
    return this.selectedViewMode === DeliveryPageViewMode.List
  }

  public get isViewWithHighlightedDeliveryMode() {
    return this.eventsStore.appState.delivery.compactView
      .isViewWithHighlightedDeliveryMode
  }

  @computed
  public get deliveryFilterCollection(): Delivery[] {
    return this.deliveriesStore.availableDeliveries.filter(delivery =>
      this.isDeliveryInSelectedPeriod(delivery),
    )
  }

  @computed
  public get todayDeliveries(): Delivery[] {
    return this.deliveriesStore.availableDeliveries.filter(delivery => {
      const { startDate, endDate } = delivery
      return (
        this.projectDateStore.isSameDay(startDate, this.currentStartDate) ||
        this.projectDateStore.isSameDay(endDate, this.currentStartDate)
      )
    })
  }

  public get todayDeliveriesCount() {
    return this.todayDeliveries.length
  }

  public filterDeliveriesByAttributes(
    deliveries: Delivery[],
    zoneId?: string,
    equipmentId?: string,
  ) {
    return deliveries.filter(delivery => {
      if (
        zoneId &&
        (zoneId === UNASSIGNED_FILTER_OPTION
          ? !this.isZoneUnassigned(delivery)
          : !delivery.isZoneId(zoneId))
      ) {
        return false
      }
      if (
        equipmentId &&
        (equipmentId === UNASSIGNED_FILTER_OPTION
          ? !this.isOffloadingEquipmentUnassigned(delivery)
          : !delivery.isOffloadingEquipmentId(equipmentId))
      ) {
        return false
      }
      return true
    })
  }

  public getTodayDeliveriesCountByZone(zoneId?: string) {
    return this.filterDeliveriesByAttributes(
      this.todayDeliveries,
      zoneId,
      this.activeOffloadingEquipmentId,
    ).length
  }

  public getTodayDeliveriesCountByEquipment(equipmentId?: string) {
    return this.filterDeliveriesByAttributes(
      this.todayDeliveries,
      this.activeZoneId,
      equipmentId,
    ).length
  }

  @computed
  public get calendarEvents(): CalendarEvent[] {
    return this.filteredCollection
      .filter(d => this.deliveryPredicate(d))
      .map(delivery => {
        const { startDate, endDate } = delivery

        const color = this.deliveryCardColor(delivery)
        const isHighlighted = this.isDeliveryHighlighted(delivery)
        const eventStyle = delivery.getEventStyle(color, isHighlighted)
        const cssAdapter = new EventStyleCssAdapter(eventStyle)

        return CalendarEvent.createEvent({
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          dataId: delivery.id,
          projectDateStore: this.projectDateStore,
          entityType: CalendarEventEntityType.Delivery,
          labelType: CalendarEventLabelType.Mobile,
          styles: cssAdapter.getStyles(),
          data: delivery,
          label: delivery.codeToDisplay(this.companiesStore),
        })
      })
  }

  @action.bound
  public setActiveZone(zoneId?: string) {
    this.activeZoneId = zoneId
  }

  @action.bound
  public setActiveOffloadingEquipment(equipmentId?: string) {
    this.activeOffloadingEquipmentId = equipmentId
  }

  @action.bound
  public setDate(newStartDate: Date, newEndDate?: Date) {
    this.currentStartDate = newStartDate
    this.currentEndDate = newEndDate
    this.listStore.calculateDeliveryTree()
  }

  @action.bound
  public nextDate() {
    const { addDays, startOfDay, endOfDay } = this.projectDateStore
    this.setDate(
      startOfDay(addDays(this.currentStartDate, 1)),
      endOfDay(addDays(this.currentEndDate, 1)),
    )
  }

  @action.bound
  public prevDate() {
    const { addDays, startOfDay, endOfDay } = this.projectDateStore
    this.setDate(
      startOfDay(addDays(this.currentStartDate, -1)),
      endOfDay(addDays(this.currentEndDate, -1)),
    )
  }

  @action.bound
  public onNewDeliveryCreate(startDate?: Date, endDate?: Date) {
    this.deliveryDetailsStore.setNewDeliveryMode()
    const prepopulatedFields = {}

    const { getDashedFormattedDate, getISOTime } = this.projectDateStore
    if (startDate && endDate) {
      prepopulatedFields[FieldIds.DATE] = getDashedFormattedDate(startDate)
      prepopulatedFields[FieldIds.END_DATE] = getDashedFormattedDate(endDate)
      prepopulatedFields[FieldIds.START_TIME] = getISOTime(startDate)
      prepopulatedFields[FieldIds.END_TIME] = getISOTime(endDate)
    } else {
      prepopulatedFields[FieldIds.DATE] = getDashedFormattedDate(
        this.currentStartDate,
      )
    }

    const { selectedActivity } = this.activitiesStore

    if (selectedActivity && this.isActivityMode) {
      prepopulatedFields[FieldIds.ACTIVITY_ID] = selectedActivity.code
    } else {
      prepopulatedFields[FieldIds.ACTIVITY_ID] = ''
    }

    if (this.activeZoneId) {
      prepopulatedFields[FieldIds.ZONE] = this.activeZoneId
    }
    if (this.activeOffloadingEquipmentId) {
      prepopulatedFields[FieldIds.OFFLOADING_EQUIPMENT] = [
        this.activeOffloadingEquipmentId,
      ]
    }

    this.deliveryDetailsStore.setPrepopulatedFields(prepopulatedFields)
    this.commonStore.displayDeliveryDetailsView(NEW_DELIVERY_PATH_KEYWORD)
    this.deliveryDetailsStore.updateFieldsByCachedValues()
  }

  @action.bound
  public onEmptyNewDeliveryCreate() {
    this.onNewDeliveryCreate()
  }

  @action.bound
  public onEventClicked(event: CalendarEvent) {
    this.openDeliveryDetails(event.data)
  }

  @action.bound
  public openDeliveryDetails(delivery: Delivery) {
    this.deliveryDetailsStore.setViewMode(delivery)
    this.commonStore.displayDeliveryDetailsView(delivery.id)
  }

  @computed
  public get zonesOptions(): IFilter[] {
    const options = [
      {
        data: null,
        onActivate: this.setActiveZone,
        isActive: !this.activeZoneId,
        title: `${
          Localization.translator.allZones
        } (${this.getTodayDeliveriesCountByZone()})`,
      },
      ...this.zones.map(zone => ({
        data: zone.id,
        onActivate: this.setActiveZone,
        color: zone.color,
        isActive: this.activeZoneId === zone.id,
        title: `${zone.name} (${this.getTodayDeliveriesCountByZone(zone.id)})`,
      })),
    ]

    if (this.areUnassignedZoneDeliveries) {
      options.push({
        data: UNASSIGNED_FILTER_OPTION,
        onActivate: this.setActiveZone,
        isActive: this.activeZoneId === UNASSIGNED_FILTER_OPTION,
        title: `${
          Localization.translator.unassigned
        } (${this.getTodayDeliveriesCountByZone(UNASSIGNED_FILTER_OPTION)})`,
      })
    }
    return options
  }

  @computed
  public get offloadingEquipmentOptions() {
    const options = [
      {
        data: null,
        onActivate: this.setActiveOffloadingEquipment,
        isActive: !this.activeOffloadingEquipmentId,
        title: `${
          Localization.translator.allEquipment
        } (${this.getTodayDeliveriesCountByEquipment()})`,
      },
      ...this.offloadingEquipments.map(({ id, name }) => ({
        data: id,
        onActivate: this.setActiveOffloadingEquipment,
        isActive: this.activeOffloadingEquipmentId === id,
        title: `${name} (${this.getTodayDeliveriesCountByEquipment(id)})`,
      })),
    ]

    if (this.areUnassignedEquipmentDeliveries) {
      options.push({
        data: UNASSIGNED_FILTER_OPTION,
        onActivate: this.setActiveOffloadingEquipment,
        isActive: this.activeOffloadingEquipmentId === UNASSIGNED_FILTER_OPTION,
        title: `${
          Localization.translator.unassigned
        } (${this.getTodayDeliveriesCountByZone(UNASSIGNED_FILTER_OPTION)})`,
      })
    }

    return options
  }

  public isZoneUnassigned = (delivery: Delivery) => {
    return !this.zonesIds.includes(delivery.zone)
  }

  @computed
  public get areUnassignedZoneDeliveries() {
    return this.currentViewDeliveries.some(delivery =>
      this.isZoneUnassigned(delivery),
    )
  }

  public isOffloadingEquipmentUnassigned = ({
    offloadingEquipmentIds,
  }: Delivery) => {
    if (offloadingEquipmentIds && offloadingEquipmentIds.length) {
      return offloadingEquipmentIds.some(
        equipmentId => !this.offloadingEquipmentsIds.includes(equipmentId),
      )
    }
    return true
  }

  @computed
  public get areUnassignedEquipmentDeliveries() {
    return this.currentViewDeliveries.some(delivery =>
      this.isOffloadingEquipmentUnassigned(delivery),
    )
  }

  @computed
  public get zones(): Zone[] {
    return this.locationAttributesStore.zonesStore.list
  }

  @computed
  public get zonesIds(): string[] {
    return this.zones.map(({ id }) => id)
  }

  public get offloadingEquipments(): OffloadingEquipment[] {
    return this.locationAttributesStore.offloadingEquipmentsStore.list
  }

  @computed
  public get offloadingEquipmentsIds(): string[] {
    return this.offloadingEquipments.map(({ id }) => id)
  }

  @computed
  public get deliveriesSitemaps(): Sitemap[] {
    const maps = this.eventsStore.appState.deliveriesMapIdsList
    return this.sitemapsStore.list.filter(s =>
      maps.some(m => m.sitemapId === s.id),
    )
  }

  @computed
  public get deliveriesGlobeViews(): GlobeView[] {
    const maps = this.eventsStore.appState.deliveriesMapIdsList
    return this.globeViewsStore.list.filter(s =>
      maps.some(m => m.globeViewId === s.id),
    )
  }

  @computed
  public get hasDeliveriesSitemaps(): boolean {
    return !!this.deliveriesSitemaps.length
  }

  @computed
  public get hasDeliveriesGlobeViews(): boolean {
    return !!this.deliveriesGlobeViews.length
  }

  public get userActiveProjectSettings() {
    return this.eventsStore.appState.userActiveProjectSettings
  }

  public getZone = (delivery: Delivery) => {
    return this.locationAttributesStore.zonesStore.getInstanceById(
      delivery.zone,
    )
  }

  public getArea = (delivery: Delivery) => {
    return this.locationAttributesStore.areasStore.getInstanceById(
      delivery.area,
    )
  }

  public getRoute = (delivery: Delivery) => {
    return this.locationAttributesStore.routesStore.getInstanceById(
      delivery.route,
    )
  }

  public getEquipment = (delivery: Delivery) => {
    return this.locationAttributesStore.offloadingEquipmentsStore.getInstanceById(
      delivery.offloadingEquipmentIds?.[0],
    )
  }

  public getBuilding = (delivery: Delivery) => {
    return this.locationAttributesStore.buildingsStore.getInstanceById(
      delivery.building,
    )
  }

  public getLevel = (delivery: Delivery) => {
    return this.locationAttributesStore.levelsStore.getInstanceById(
      delivery.level,
    )
  }

  public getGate = (delivery: Delivery) => {
    return this.locationAttributesStore.gatesStore.getInstanceById(
      delivery.gate,
    )
  }

  public deliveryCardColor = (delivery: Delivery): string => {
    const { cardColoringLocationType } =
      this.eventsStore.appState.delivery.configurations
    switch (cardColoringLocationType) {
      case LocationType.Area:
        return this.getArea(delivery)?.color || Colors.primary20
      case LocationType.Building:
        return this.getBuilding(delivery)?.color || Colors.primary20
      case LocationType.Gate:
        return this.getGate(delivery)?.color || Colors.primary20
      case LocationType.Level:
        return this.getLevel(delivery)?.color || Colors.primary20
      case LocationType.Route:
        return this.getRoute(delivery)?.color || Colors.primary20
      case LocationType.OffloadingEquipment:
        return this.getEquipment(delivery)?.color || Colors.primary20
      case LocationType.Zone:
      default:
        return this.getZone(delivery)?.color || Colors.primary20
    }
  }

  public isDeliveryHighlighted = (delivery: Delivery) => {
    return this.highlightedDelivery === delivery
  }

  private saveUserDeliveriesSettings() {
    const deliveriesSettings: IDeliveriesSettings = {
      isFollowingFilterActive: this.isFollowingFilterActive,
      isMyCompanyFilterActive: this.isMyCompanyFilterActive,
      selectedGroupByOption: this.selectedGroupByOption,
      selectedFilters: this.deliveryFilterStore.selectedFilters,
    }

    const userActiveProjectSettingsDto = this.userActiveProjectSettings.toDto()
    userActiveProjectSettingsDto.deliveriesSettings = deliveriesSettings

    this.userProjectsStore.save([userActiveProjectSettingsDto])
  }
}
